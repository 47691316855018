import { Layout, Tabs } from 'antd'

import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import 'moment/locale/vi'
import FeeConfigManagement from './feeConfig'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import DesignerConfigManagement from './designerConfig'
import { tabIDs } from '../../helpers/constants'
const { Content } = Layout
const TabPane = Tabs.TabPane

const CommonConfigManagement = observer((props: any) => {
   const { t } = useTranslation()
   const CommonConfigStore = useStore('CommonConfigStore')

   const onChangeTab = (key: string) => {
      CommonConfigStore.setActiveKeyMain(key)
   }
   return (
      <Content {...props}>
         <Tabs activeKey={CommonConfigStore.activeKeyMain} onChange={onChangeTab}>
            <TabPane tab={t(GLOBAL_CLIENT.designer)} key={tabIDs.designer}>
               <DesignerConfigManagement {...props} />
            </TabPane>
            <TabPane tab={t(GLOBAL_CLIENT.phiSan)} key={tabIDs.phiSan}>
               <FeeConfigManagement {...props} />
            </TabPane>
         </Tabs>
      </Content>
   )
})

export default CommonConfigManagement
