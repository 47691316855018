import { Route, Switch } from 'react-router-dom'
import NotFoundPage from '../404/NotFoundPage'
import DefaultSupporterScreen from '../default/DefaultSupporterScreen'
import { ROUTER_PATH } from './metaData'
import CreatorManagement from '../creator-management'
import CreateCreatorScreen from '../creator-management/Create'
import CreatorDetail from '../creator-management/Detail'
import DesignerManagement from '../designer-management'
import EmployeeManagement from '../employee-management'
import productTypeManagementScreen from '../product-type-management'
import DesignTypeManagementScreen from '../design-type-management'
import JobManagement from '../job-management'
import CompletedQuantityScreen from '../dashboard/completed-quantity'
import ProfileScreen from '../profile'
const SupporterRouters = (props: any) => {
   return (
      <Switch>
         <Route exact path={`/`} component={DefaultSupporterScreen} />

         <Route exact path={`${props.match.url}${ROUTER_PATH.CreatorManagementScreen}`} component={CreatorManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.CreateCreatorScreen}`} component={CreateCreatorScreen} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.CreatorDetailScreen}`} component={CreatorDetail} />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.DesignerManagementScreen}`}
            component={DesignerManagement}
         />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.EmployeeManagementScreen}`}
            component={EmployeeManagement}
         />
         {/* <Route exact path={`${props.match.url}${ROUTER_PATH.FundManagementScreen}`} component={FundManagementScreen} /> */}
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.ProductTypeManagementScreen}`}
            component={productTypeManagementScreen}
         />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.DesignTypeManagementScreen}`}
            component={DesignTypeManagementScreen}
         />
         <Route exact path={`${props.match.url}${ROUTER_PATH.JobManagementScreen}`} component={JobManagement} />
         {/* <Route exact path={`${props.match.url}${ROUTER_PATH.DebtManagementScreen}`} component={DebtManagementScreen} />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.DepositWithdrawalManagementScreen}`}
            component={DepositWithdrawalManagementScreen}
         />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.CommonManagementScreen}`}
            component={CommonManagementScreen}
         /> */}

         <Route exact path={`${props.match.url}${ROUTER_PATH.ProfileScreen}`} component={ProfileScreen} />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.CompletedQuantityScreen}`}
            component={CompletedQuantityScreen}
         />
         <Route path="*" component={NotFoundPage} />
      </Switch>
   )
}

export default SupporterRouters
