import { Popover } from 'antd'
import Images from 'src/common/Images'
import { useEffect, useRef, useState } from 'react'
import { DATE_TIME_FORMAT_CLIENT_NEW, NOTI_SIZE, SOCKET_EVENT, getNoti } from 'src/helpers/constants'
import InfiniteScroll from 'react-infinite-scroll-component'
import { observer } from 'mobx-react-lite'
import api from 'src/common/api'
import { Request2 } from 'src/common/Request2'
import { isEmpty } from 'lodash'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import moment from 'moment'
import { socket } from 'src/helpers/socket/socket'
import { useStore } from 'src/hooks'
import NoData from 'src/common/components/NoData'
import styled from 'styled-components'
import { EditJobDetailModal } from 'src/screens/job-detail/EditJobDetailCreator'
import { jobDetailInit } from 'src/stores/JobDetailStore'

const UserNotification = observer(() => {
   const AuthStore = useStore('AuthStore')
   const JobDetailStore = useStore('JobDetailStore')
   const { data_getUserInfo } = AuthStore
   const currentPage = useRef<number>(0)
   const [jobs, setJobs] = useState({
      rows: [],
      offset: 0
   }) as any
   const [isPopoverVisible, setPopoverVisible] = useState(false)
   const [isNewNotif, setIsNewNotif] = useState(false)
   const [isOpenEditJobDetail, setIsOpenEditJobDetail] = useState(false)
   const [jobId, setJobId] = useState(0)
   const [selectedItem, setSelectedItem] = useState<any>([])

   const handleVisibleChange = (visible: boolean) => {
      setPopoverVisible(visible)
   }
   useEffect(() => {
    console.log('UserNotification----');
    socket.on(SOCKET_EVENT.NOTIFICATION, (data) => {
      console.log('SOCKET_EVENT.NOTIFICATION data_getUserInfo.id', data)
      if (data_getUserInfo.id === data.to) {
         setIsNewNotif(true)
         if (isPopoverVisible) {
            const newJobs = [data, ...jobs.rows]
            setJobs({ ...jobs, rows: newJobs })
         }
      }
   })
   }, [])

   const getListData = async (param?: any, pageNumber: number = 0) => {
      try {
         // if (pageNumber === 0) setIsLoading(true)
         const offset = getNoti(pageNumber)
         const params = { offset, ...param }
         const result = await Request2.getWithToken(api.notification.get, { ...params, ...{ limit: NOTI_SIZE } })
         console.log(result)
         if (!isEmpty(result)) {
            if (params?.offset !== 0 && params?.offset !== jobs.offset) {
               setJobs({ ...result, rows: jobs?.rows.concat(result?.rows) })
            } else {
               setJobs(result)
            }
         }
      } catch (error) {
         // console.log(error)
      } finally {
         // if (pageNumber === 0) setIsLoading(false)
      }
   }



   const handleFetchJobs = () => {
      console.log('handleFetchJobs')
      getListData({}, currentPage.current + 1)
      currentPage.current = currentPage.current + 1
   }

   const handlePopoverClick = () => {
      setIsNewNotif(false)
      getListData({}, 0)
   }

   const handleMarkRead = async (id: any) => {
      try {
         // setIsLoading(true)
         const response = await Request2.postWithToken({}, api.notification.mark_read + `/${id}`)
         setPopoverVisible(false)
         try {
            setJobId(response?.data?.jobId)
            JobDetailStore.setJobDetail({ ...jobDetailInit, id: response?.data?.id })
            // await JobDetailStore.getJobDetail(response?.id)
         } catch (error) {
            console.log(error)
         }
         setIsOpenEditJobDetail(true)
      } catch (error) {
         console.log(error)
      } finally {
         // setIsLoading(false)
      }
   }

   // const Styled = styled.div``

   return (
      <>
         <Popover
            overlayClassName="gx-popover-horizantal popoverNoPadding"
            placement="bottomRight"
            trigger="click"
            visible={isPopoverVisible}
            // overlayStyle={{ padding: 0, backgroundColor: 'red' }}
            onVisibleChange={handleVisibleChange}
            getPopupContainer={(triggerNode) => triggerNode}
            content={
               jobs?.rows?.length ? (
                  <InfiniteScroll
                     dataLength={jobs?.rows?.length ?? 0}
                     next={handleFetchJobs}
                     hasMore={true}
                     loader={<></>}
                     style={{ width: 320, borderRadius: 12 }}
                     height={500}
                     scrollableTarget="observerTarget"
                  >
                     <ul className="gx-sub-popover" id="observerTarget" style={{ paddingLeft: 0 }}>
                        {jobs?.rows?.map((job: any, index: number) => {
                           return (
                              <li
                                 key={index}
                                 className="gx-media"
                                 style={{ backgroundColor: job?.markRead ? '#fff' : '#eee', padding: '12px 16px' }}
                                 onClick={() => handleMarkRead(job?.id)}
                              >
                                 <div className="gx-media-body gx-align-self-center">
                                    <div style={{ fontSize: '14px', lineHeight: '20px' }}>
                                       <ReactMarkdown className="gx-mb-0">{job?.description}</ReactMarkdown>
                                    </div>
                                    <span>
                                       <p style={{ marginBottom: 0 }}>
                                          {moment(job?.createdAt).format(DATE_TIME_FORMAT_CLIENT_NEW)}
                                       </p>
                                    </span>
                                 </div>
                              </li>
                           )
                        })}
                     </ul>
                  </InfiniteScroll>
               ) : (
                  <div style={{ width: 300 }}>
                     <NoData />
                  </div>
               )
            }
         >
            <span
               className="gx-avatar-name gx-mt-auto gx-mb-auto gx-pointer gx-status-pos gx-d-block"
               style={{ height: 22, width: 22 }}
               onClick={handlePopoverClick}
            >
               <img src={Images.ic_bell} alt="avatar" />
               {isNewNotif && (
                  <span
                     className="gx-status gx-small gx-orange"
                     style={{ top: 0, left: '12px', backgroundColor: '#ff0000' }}
                  />
               )}
            </span>
         </Popover>
         <EditJobDetailModal
            data={{ ...jobDetailInit, id: jobId }}
            visible={isOpenEditJobDetail}
            handleOk={() => setIsOpenEditJobDetail(true)}
            handleCancel={() => setIsOpenEditJobDetail(false)}
         />
      </>
   )
})

export default UserNotification
