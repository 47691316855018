import { isEmpty } from 'lodash'
import { GLOBAL_CLIENT } from './GlobalClient'
import i18n from 'i18next'

const requiredRule = {
   required: true,
   message: i18n.t(GLOBAL_CLIENT.vuilongnhapdulieu)
}
const requiredName = {
   required: true,
   message: i18n.t(GLOBAL_CLIENT.noName)
}
const requiredTeleFb= {
   required: true,
   message: i18n.t(GLOBAL_CLIENT.noTeleFb)
}
const requiredUsernameTele= {
   required: true,
   message: i18n.t(GLOBAL_CLIENT.noUsernameTele)
}
const requiredPhone = {
   required: true,
   message: i18n.t(GLOBAL_CLIENT.noPhone)
}
const requiredEmail = {
   required: true,
   message: i18n.t(GLOBAL_CLIENT.noEmail)
}
const requiredPassword = {
   required: true,
   message: i18n.t(GLOBAL_CLIENT.noPassword)
}
const requiredConfirmPassword = {
   required: true,
   message: i18n.t(GLOBAL_CLIENT.noConfirmPassword)
}
const emailRule: any = {
   pattern:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
   message: i18n.t(GLOBAL_CLIENT.validateEmail)
}
const passwordRule: any = {
   validator: (_: any, value: any) => {
      const passwordRegex = /^\S*(([0-9]+.*[A-Za-z]+\S*)|[A-Za-z]+.*([0-9]+.*)+\S)$/
      if (isEmpty(value)) return Promise.reject(new Error(i18n.t(GLOBAL_CLIENT.noPassword)))
      else if (!passwordRegex.test(value) || value?.length < 9)
         return Promise.reject(new Error(i18n.t(GLOBAL_CLIENT.validatePassword)))
      return Promise.resolve()
   }
}

const phoneRule: any = {
   pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/,
   min: 10,
   message: i18n.t(GLOBAL_CLIENT.phoneValidate)
}

const maxRule: any = {
   max: 255,
   message: i18n.t(GLOBAL_CLIENT.maxCharacter)
}

const termRule: any = {
   validator: (_: any, value: any) =>
      value ? Promise.resolve() : Promise.reject(new Error(i18n.t(GLOBAL_CLIENT.acceptTerm)))
}
export const rule = {
   phoneRule,
   requiredConfirmPassword,
   requiredName,
   requiredTeleFb,
   requiredUsernameTele,
   requiredPhone,
   requiredRule,
   requiredEmail,
   requiredPassword,
   emailRule,
   passwordRule,
   termRule,
   maxRule
}
