import { useEffect, useState } from 'react'
import { Select, Modal, Form, Space, DatePicker, Button } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../hooks'
import { DATE_FORMAT_CLIENT_PARAMS } from '../../helpers/constants'
import 'moment/locale/vi'
import locale from 'antd/es/date-picker/locale/vi_VN'
// import CalendarHeatmap2 from 'reactjs-calendar-heatmap'
// import 'react-calendar-heatmap/dist/styles.css'
import moment from 'moment'
import Tooltip from '@uiw/react-tooltip'
import HeatMap from '@uiw/react-heat-map'
import useWindowDimensions from '../useWindowDimensions'
import { size, range } from 'lodash'
import { Loading } from './Loading'

const SameNumberDetail = observer((props: any) => {
   const { t } = useTranslation()

   const [isLoading, setIsLoading] = useState(false)
   const { width } = useWindowDimensions()
   const { item, visible, onOk, onCancel } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars

   const YEARS = range(2000, new Date().getFullYear() + 1).reverse()
   const [year, setYear] = useState(new Date().getFullYear())
   const [status, setStatus] = useState(parseInt(item?.status))
   const UserStore = useStore('UserStore')
   const { jobSameNumber, fromSameNumber, toSameNumber } = UserStore
   const [form] = Form.useForm()
   // console.log('jobSameNumber; ', jobSameNumber)
   useEffect(() => {
      getData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [fromSameNumber, toSameNumber])

   const getData = async () => {
      if (moment(fromSameNumber).format('YYYYMMDD') <= moment(toSameNumber).format('YYYYMMDD')) {
         try {
            setIsLoading(true)
            await UserStore.getSameNumber(item?.id)
         } catch (error) {
            // console.log(error)
         } finally {
            setIsLoading(false)
         }
      }
   }

   const handleRangePickerChange = (date: any) => {
      UserStore.setFromSameNumber(date[0])
      UserStore.setToSameNumber(date[1])
   }

   const handleChangeYear = (value: number) => {
      console.log('value', value)
      setYear(value)
      UserStore.setFromSameNumber(new Date(`${value}/01/01`))
      UserStore.setToSameNumber(new Date(`${value}/12/31`))
   }

   return (
      <Modal
         width={0.68 * width}
         visible={visible}
         centered
         title={t(GLOBAL_CLIENT.numberItems)}
         okText={t(GLOBAL_CLIENT.edit)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         onOk={form.submit}
         footer={[
            <>
               <Button key="back" onClick={onCancel}>
                  Đóng
               </Button>
            </>
         ]}
      >
         <Loading isLoading={isLoading} />
         <Space>
            {/* <DatePicker.RangePicker
               allowClear={false}
               defaultValue={[moment(fromSameNumber), moment(toSameNumber)]}
               format={DATE_FORMAT_CLIENT_PARAMS}
               locale={locale}
               onChange={handleRangePickerChange}
               showNow
               mode={['year','year']}
               
            /> */}
            <Select defaultValue={year} onChange={handleChangeYear} placeholder="Chọn năm">
               {YEARS.map((item: any) => {
                  return (
                     <Select.Option key={item} value={item}>
                        {item}
                     </Select.Option>
                  )
               })}
            </Select>
         </Space>
         {jobSameNumber && size(jobSameNumber) > 0 && (
            <HeatMap
               // @ts-ignore
               value={JSON.parse(JSON.stringify(jobSameNumber))}
               width={720}
               // @ts-ignore
               // @ts-ignore
               monthLabels={['T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8', 'T9', 'T10', 'T11', 'T12']}
               weekLabels={['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']}
               startDate={fromSameNumber}
               endDate={toSameNumber}
               // startDate={moment(fromSameNumber.format('YYYY/MM/DD')).toDate()}
               // endDate={moment(toSameNumber.format('YYYY/MM/DD')).toDate()}

               legendCellSize={12}
               // @ts-ignore
               rectRender={(props, data) => {
                  // if (!data.count) return <rect {...props} />;
                  return (
                     <Tooltip
                        key={props?.key}
                        placement="top"
                        content={`${moment(data?.date).format('DD/MM/YYYY')}\n count: ${data.count || 0}`}
                     >
                        <rect {...props} />
                     </Tooltip>
                  )
               }}
            />
         )}
      </Modal>
   )
})

export default SameNumberDetail
