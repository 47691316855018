import { Col, Row } from 'antd'
import ProfileHeader from './ProfileHeader'
import Contact from './Contact'
import styled from 'styled-components'
import { useStore } from 'src/hooks'
import About from './About'
import { observer } from 'mobx-react-lite'

const ProfileScreenWrapper = styled.div`
   padding: 16px;
   margin-left: auto;
   margin-right: auto;
   .gx-profile-banner {
      background: #323347;
      margin-top: -16px;
      margin-left: -16px;
      margin-right: -16px;
   }
`
const ProfileScreen = observer(() => {
   const AuthStore = useStore('AuthStore')
   const { data_getUserInfo } = AuthStore

   return (
      <ProfileScreenWrapper className="m-container">
         <ProfileHeader data={data_getUserInfo} />
         <div className="gx-profile-content">
            <Row>
               <Col xl={16} lg={14} md={14} sm={24} xs={24}>
                  <About data={data_getUserInfo} />
               </Col>

               <Col xl={8} lg={10} md={10} sm={24} xs={24}>
                  <Contact data={data_getUserInfo} />
               </Col>
            </Row>
         </div>
      </ProfileScreenWrapper>
   )
})

export default ProfileScreen
