import { RcFile } from 'antd/lib/upload'
import { showMessageError } from './functions'

const useUpload = () => {
   const getBase64 = (img: RcFile, callback: (url: string) => void) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result as string))
      reader.readAsDataURL(img)
   }

   const beforeUpload = (file: RcFile) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
         showMessageError('Bạn chỉ có thể tải lên ảnh JPG/PNG!')
      }
      return isJpgOrPng
   }

   return { getBase64, beforeUpload }
}

export default useUpload