import { Row, Col } from 'antd'
import { STATUS_JOB } from 'src/helpers/constants'
import { useStore } from 'src/hooks'
import Countdown from './Countdown'
import JobState from './JobState'
import { observer } from 'mobx-react-lite'
import { useRef, useEffect } from 'react'

const JobDetailOrderName = observer(() => {
   const JobDetailStore = useStore('JobDetailStore')
   const { jobDetail } = JobDetailStore
   const AuthStore = useStore('AuthStore')
   const role = AuthStore.data_role
   const orderNameRef = useRef<any>(null)

   const handleEditInputChange = (e: any) => {
      JobDetailStore.setJobDetailName(e.target.value)
   }

   useEffect(() => {
      if (orderNameRef.current) {
         orderNameRef.current.click()
      }
   }, [])

   return (
      <Row gutter={[8, 0]} style={{ position: 'relative', width: '100%' }} wrap={false}>
         <Col
            className="gx-flex-row gx-align-items-center gx-justify-content-start"
            style={{
               width: 'calc(100% - 156px)'
            }}
         >
            <div>
               {/* <Input
                  value={jobDetail?.name}
                  style={{ fontSize: 16 }}
                  className="gx-font-weight-semi-bold"
                  autoFocus
                  maxLength={254}
                  placeholder="Tên đơn hàng"
                  onChange={handleEditInputChange}
                  readOnly
                  bordered={true}
               /> */}
            </div>
            <div
               style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical'
               }}
               ref={orderNameRef}
            >
               <h4
                  className="gx-font-weight-semi-bold gx-mb-0"
                  style={{
                     fontSize: '20px',
                     lineHeight: '28px',
                     fontWeight: 600,
                     color: '#272944'
                  }}
               >
                  {jobDetail?.name}
               </h4>
            </div>
         </Col>
         <Col style={{ position: 'absolute', top: 2, right: 0 }}>
            <Row gutter={[16, 0]} className="gx-flex-row gx-align-items-center gx-justify-content-end" wrap={true}>
               {jobDetail?.status === STATUS_JOB.DOING && (
                  <Col style={{ padding: 0, marginRight: 8 }}>
                     <Countdown expireAt={jobDetail?.expiresAt} />
                  </Col>
               )}
               <Col style={{ padding: 0 }}>
                  <JobState state={jobDetail?.status} />
               </Col>
            </Row>
         </Col>
      </Row>
   )
})

export default JobDetailOrderName
