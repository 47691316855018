import { Route, Switch } from 'react-router-dom'
import NotFoundPage from '../404/NotFoundPage'
import DefaultUserScreen from '../default/DefaultUserScreen'
import { ROUTER_PATH } from './metaData'
import JobsRecruiting from '../jobs-recruiting'
import JobsProcessing from '../jobs-processing'
import JobsReview from '../jobs-review'
import JobsEditing from '../jobs-editing'
import JobsDone from '../jobs-done'
import ProfileScreen from 'src/screens/profile'

const UserRouters = (props: any) => {
   return (
      <Switch>
         <Route exact path={`/`} component={DefaultUserScreen} />

         <Route exact path={`${props.match.url}${ROUTER_PATH.JobsRecruitingScreen}`}>
            <JobsRecruiting />
         </Route>
         <Route exact path={`${props.match.url}${ROUTER_PATH.JobsProcessingScreen}`}>
            <JobsProcessing />
         </Route>
         <Route exact path={`${props.match.url}${ROUTER_PATH.JobsReviewScreen}`}>
            <JobsReview />
         </Route>
         <Route exact path={`${props.match.url}${ROUTER_PATH.JobsEditingScreen}`}>
            <JobsEditing />
         </Route>
         <Route exact path={`${props.match.url}${ROUTER_PATH.JobsDoneScreen}`}>
            <JobsDone />
         </Route>

         <Route exact path={`${props.match.url}${ROUTER_PATH.ProfileScreen}`} component={ProfileScreen} />

         <Route exact path={`${props.match.url}${ROUTER_PATH.ResetPassword}`} component={ProfileScreen} />

         <Route path="*" component={NotFoundPage} />
      </Switch>
   )
}

export default UserRouters
