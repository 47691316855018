import { Form, Input } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { Loading } from 'src/common'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { ERROR_CODES } from 'src/helpers/constants'
import { showMessageSuccess } from 'src/helpers/functions'
import { rule } from 'src/helpers/validateRule'
import { useStore } from 'src/hooks'
import Images from '../../common/Images'
import { debounce } from 'lodash'
import { AuthenWrapper, SignupWrapper } from 'src/styles/login/Authen.style'
import BaseButton from 'src/common/components/button'

const ResendEmailScreen = () => {
   const AuthStore = useStore('AuthStore')
   const { t } = useTranslation()
   const [form] = Form.useForm()
   const history = useHistory()

   const [isLoading, setIsLoading] = useState(false)
   const onFinishFailed = (errorInfo: any) => {}
   const onFinish = async (values: any) => {
      try {
         setIsLoading(true)
         const result = await AuthStore.action_resendVerifyEmail(values)
         if (result.code === ERROR_CODES.SUCCESS) {
            showMessageSuccess(result?.message, () => history.push('/signin'))
         }
      } catch (e) {
         // console.log(e)
      } finally {
         setIsLoading(false)
      }
   }

   return (
      <AuthenWrapper>
         <Loading isLoading={isLoading} />
         <div className="login__banner">
            <img src={Images.bg_login} alt="" />
         </div>
         <SignupWrapper className="login__content">
            <div className="signup-content__body">
               <div className="signup-content__form">
                  <div className="form__title">{t(GLOBAL_CLIENT.activeAccount)}</div>
                  <div className="form__desc">{t(GLOBAL_CLIENT.activeAccountTxt)}</div>
                  <Form
                     form={form}
                     name="basic"
                     onFinish={debounce(onFinish, 400)}
                     onFinishFailed={onFinishFailed}
                     className="gx-signin-form gx-form-row0 signup-form__content"
                  >
                     <Form.Item rules={[rule.emailRule, rule.requiredEmail]} name="email">
                        <Input placeholder={t(GLOBAL_CLIENT.email)} maxLength={254} />
                     </Form.Item>
                     <Form.Item style={{ marginBottom: 'unset', marginTop: '24px' }}>
                        <div className="signup-form__footer">
                           <div className="signin__link">
                              <span>Or</span> <Link to="/signin">Sign In</Link>
                           </div>
                           <BaseButton type="primary" className="gx-mb-0">
                              Submit
                           </BaseButton>
                        </div>
                     </Form.Item>
                  </Form>
               </div>
            </div>
         </SignupWrapper>
      </AuthenWrapper>
   )
}

export default ResendEmailScreen
