import { Input, Form, Select } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BANKS, ERROR_CODES, ROLE } from '../../helpers/constants'
import { Loading } from '../../common'
import Images from '../../common/Images'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useStore } from '../../hooks'
import { rule } from '../../helpers/validateRule'
import { Link } from 'react-router-dom'
import { showMessageSuccess } from 'src/helpers/functions'
import { debounce } from 'lodash'
import { AuthenWrapper, SignupWrapper } from 'src/styles/login/Authen.style'
import BaseButton from 'src/common/components/button'
import StickyChatButton from 'src/common/components/sticky-chat/StickyChatButton'
const { Option } = Select

const SignupDesignerScreen = observer((props: any) => {
   const AuthStore = useStore('AuthStore')
   const { t } = useTranslation()
   const [isLoading, setIsLoading] = useState(false)
   const { history } = props
   const [form] = Form.useForm()

   const onFinishFailed = (errorInfo: any) => {}

   const onFinish = async (values: any) => {
      try {
         setIsLoading(true)
         Object.keys(values)?.forEach((k) => {
            if (typeof values[k] == 'string') return (values[k] = values[k]?.trim())
         })
         const { name, email, password, phoneNumber, linkTelegram, bankNumber, bankName, accountBankName } = values
         const usernameTele = linkTelegram?.replace('@', '')
         const urlTelegram = `https://t.me/${usernameTele}`
         const result = await AuthStore.action_register(
            { name, email, password, phoneNumber, linkTelegram: urlTelegram, bankNumber, bankName, accountBankName },
            { role: ROLE.DESIGNER }
         )
         if (result?.code === ERROR_CODES.SUCCESS) {
            showMessageSuccess(t(GLOBAL_CLIENT.registerSuccess), () => history.push('/resend-verify-email'))
         }
      } catch (error) {
         //  console.log(error)
      } finally {
         setIsLoading(false)
      }
   }

   return (
      <AuthenWrapper>
         {/* <Loading isLoading={isLoading} /> */}
         <div className="login__banner">
            <img src={Images.bg_login} alt="" />
         </div>
         <SignupWrapper className="signup__content">
            <div className="signup-content__body">
               <div className="signup-content__form">
                  <div className="form__title">{t(GLOBAL_CLIENT.signupDesigner)}</div>
                  {/* <div className="form__desc">
                     {t(GLOBAL_CLIENT.orSignupAs)} <Link to="/signup-creator">{t(GLOBAL_CLIENT.creator)}</Link>
                  </div> */}
                  <Form
                     form={form}
                     name="basic"
                     onFinish={debounce(onFinish, 400)}
                     onFinishFailed={onFinishFailed}
                     className="gx-signin-form gx-form-row0 signup-form__content"
                  >
                     <div className="form-item__wrapper">
                        <p className="form-item__label">{t(GLOBAL_CLIENT.fullname) + '*'}</p>
                        <Form.Item rules={[rule.requiredName]} name="name">
                           <Input placeholder="Nhập" maxLength={30} />
                        </Form.Item>
                     </div>
                     <div className="form-item__wrapper">
                        <p className="form-item__label">{t(GLOBAL_CLIENT.txtEmail) + '*'}</p>
                        <Form.Item name="email" rules={[rule.emailRule, rule.requiredEmail]}>
                           <Input placeholder="Nhập" maxLength={254} />
                        </Form.Item>
                     </div>
                     <div className="form-item__wrapper">
                        <p className="form-item__label">{t(GLOBAL_CLIENT.txtPhone) + '*'}</p>
                        <Form.Item name="phoneNumber" rules={[rule.phoneRule, rule.requiredPhone]}>
                           <Input placeholder="Nhập" maxLength={20} />
                        </Form.Item>
                     </div>
                     <div className="form-item__wrapper">
                        <p className="form-item__label">{t(GLOBAL_CLIENT.bankName) + '*'}</p>
                        <Form.Item name="bankName" rules={[rule.requiredRule]}>
                           <Select style={{ textAlign: 'left' }} placeholder="Chọn ngân hàng">
                              {BANKS.map((item: any) => {
                                 return (
                                    <Option key={item?.id} value={item?.code}>
                                       {item?.short_name}
                                    </Option>
                                 )
                              })}
                           </Select>
                        </Form.Item>
                     </div>
                     {/* <Form.Item name="bankName" rules={[rule.requiredRule]}>
                        <p className="form-item__label">{t(GLOBAL_CLIENT.bankName) + '*'}</p>
                        <Input
                           placeholder={t(GLOBAL_CLIENT.bankName)}
                           
                           maxLength={100}
                        />
                     </Form.Item> */}
                     <div className="form-item__wrapper">
                        <p className="form-item__label">{t(GLOBAL_CLIENT.accountBankName) + '*'}</p>
                        <Form.Item name="accountBankName" rules={[rule.requiredRule]}>
                           <Input placeholder="Nhập" maxLength={100} />
                        </Form.Item>
                     </div>
                     <div className="form-item__wrapper">
                        <p className="form-item__label">{t(GLOBAL_CLIENT.bankAccount) + '*'}</p>
                        <Form.Item name="bankNumber" rules={[rule.requiredRule]}>
                           <Input placeholder="Nhập" maxLength={30} />
                        </Form.Item>
                     </div>
                     <div className="form-item__wrapper">
                        <p className="form-item__label">{t(GLOBAL_CLIENT.password) + '*'}</p>
                        <Form.Item rules={[rule.passwordRule]} name="password">
                           <Input type="password" placeholder="Nhập" maxLength={30} />
                        </Form.Item>
                     </div>
                     <div className="form-item__wrapper">
                        <p className="form-item__label">Username Telegram *</p>
                        <Form.Item name="linkTelegram" rules={[rule.requiredUsernameTele]}>
                           <Input placeholder="Ví dụ: @vincepq" maxLength={254} />
                        </Form.Item>
                     </div>
                     <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[rule.termRule]}
                        className="signup-form__term"
                     >
                        <Checkbox>
                           {t(GLOBAL_CLIENT.bySigningUpIAccept)}
                           <Link to="/">
                              &nbsp;
                              {t(GLOBAL_CLIENT.termCondition)}
                           </Link>
                        </Checkbox>
                     </Form.Item>
                     <Form.Item style={{ marginBottom: 'unset', marginTop: 'unset' }}>
                        <div className="signup-form__footer">
                           <div className="signin__link">
                              <span>{t(GLOBAL_CLIENT.or)}</span> <Link to="/signin">{t(GLOBAL_CLIENT.login)}</Link>
                           </div>
                           <BaseButton type="primary" className="gx-mb-0">
                              {t(GLOBAL_CLIENT.register)}
                           </BaseButton>
                        </div>
                     </Form.Item>
                  </Form>
               </div>
            </div>
            <StickyChatButton />
         </SignupWrapper>
      </AuthenWrapper>
   )
})

export default SignupDesignerScreen
