import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { BUTTON_STATUS, CARD_BUTTON_ACTION, ERROR_CODES, Number_impressions, STATUS_JOB } from 'src/helpers/constants'
import { observer } from 'mobx-react-lite'
import { useStore } from 'src/hooks'
import { useTranslation } from 'react-i18next'
import BaseButton from 'src/common/components/button'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import useModal from 'src/helpers/modal/useModal'
import { size } from 'lodash'

type CardItemButtonProps = {
   status?: number
   id?: number
   count: number
   handleRefreshJobs: () => void
   isPriority: any
   targetId: any
}
// const { confirm } = Modal

const CardItemButton: React.FC<CardItemButtonProps> = observer(
   ({ status, id, handleRefreshJobs, count, isPriority, targetId }) => {
      const { t } = useTranslation()
      const AuthStore = useStore('AuthStore')
      const JobsStore = useStore('JobsStore')
      const CommonConfigStore = useStore('CommonConfigStore')
      const { confirm } = useModal()
      const { designerConfigList } = CommonConfigStore
      const [buttonStatus, setButtonStatus] = useState<any>(BUTTON_STATUS[0])
      useEffect(() => {
         //type= prioritize
         setButtonStatus(BUTTON_STATUS.find((item: any) => item.status === status))
      }, [])
      const handleButtonAction = async () => {
         const buttonAction: any = CARD_BUTTON_ACTION.find((item) => item.status === status)
         try {
            const result = await JobsStore.action__cardBtn(`${buttonAction?.path}/${id}`)
            if (result?.code === ERROR_CODES.SUCCESS) {
               handleRefreshJobs()
            }
         } catch (e) {
            // console.log(e)
         } finally {
         }
      }

      const handleClickCardButton = async (event: any) => {
         event.stopPropagation()
         const buttonAction: any = CARD_BUTTON_ACTION.find((item) => item.status === status)
         if (buttonAction?.isAction) {
            await AuthStore.action_getProfile()
            const result: any = await JobsStore.isAbleToReceiveJobs()
            if (result?.isReceive === true) {
               if (AuthStore.data_getUserInfo?.countJob < Number_impressions) {
                  confirm({
                     title: 'Xác nhận nhận việc',
                     icon: null,
                     content: t(GLOBAL_CLIENT.alertnhanviec).replace(
                        '{0}',
                        String('<b>' + designerConfigList?.timeJob * count + ' phút</b>')
                     ),
                     onOk() {
                        handleButtonAction()
                     },
                     onCancel() {},
                     okText: t(GLOBAL_CLIENT.nhanviec),
                     cancelText: t(GLOBAL_CLIENT.huy)
                  })
               } else {
                  handleButtonAction()
               }
            }
         }
      }

      return (
         <>
            {status !== STATUS_JOB.NEW && (
               <BaseButton
                  type={isPriority ? 'prioritize' : (targetId && size(targetId) > 0) ? 'tag_designer' : buttonStatus.type}
                  padding={buttonStatus?.padding}
                  size="sm"
                  className="content-footer__btn"
                  onClick={(e: any) => handleClickCardButton(e)}
               >
                  {buttonStatus.label}
               </BaseButton>
            )}
         </>
      )
   }
)

export default CardItemButton
