import { SaveOutlined } from '@ant-design/icons'
import { Col, Input, Row } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Images from 'src/common/Images'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { ROLE, STATUS_JOB } from 'src/helpers/constants'
import { showMessageError } from 'src/helpers/functions'
import { useStore } from 'src/hooks'

interface UrlInput {
   id: number
   url: string
}

const JobDetailLink = observer(({ isSaving, handleSave }: any) => {
   const { t } = useTranslation()
   const [viewMode, setViewMode] = useState(false)
   const JobDetailStore = useStore('JobDetailStore')
   const { jobDetail } = JobDetailStore
   const status = jobDetail?.status
   const AuthStore = useStore('AuthStore')
   const role = AuthStore.data_role
   const urlList = jobDetail?.url?.split(', ')
   const [urlInputs, setUrlInputs] = useState<UrlInput[]>([{ id: 0, url: '' }])
   const [isShowError, setIsShowError] = useState<boolean>(false)

   // const urlInputs = jobDetail?.url?.split(', ')

   useEffect(() => {
      setIsShowError(false)
   }, [jobDetail])

   const handleAddUrlInput = () => {
      const newUrlList = [...urlList]
      // newUrlInputs.push({ id: Date.now(), url: '' })
      newUrlList.push('')
      const urlStrings = newUrlList?.join(', ')
      // console.log(urlStrings)

      JobDetailStore.setJobDetailUrl(urlStrings)
   }

   const handleRemoveUrlInput = (index: number) => {
      const newUrlList = [...urlList]
      newUrlList.splice(index, 1)
      const urlStrings = newUrlList?.join(', ')
      // console.log(urlStrings)
      JobDetailStore.setJobDetailUrl(urlStrings)
   }

   const handleUrlInputChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
      // console.log(isSaving)
      setIsShowError(false)
      handleSave(false)
      const newUrlList = [...urlList]
      newUrlList[id] = event.target.value

      const urlStrings = newUrlList?.join(', ')
      // console.log(newUrlList[id])

      JobDetailStore.setJobDetailUrl(urlStrings)
   }

   const handleUrlSave = () => {
      // const isEmpty = urlList.includes('')

      const trimmedUrlList = urlList.map(function (e) {
         if (typeof e === 'string') {
            return e.trim()
         }
         return e
      })

      let filterList = trimmedUrlList.filter((e) => e !== '')
      // const regex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?(?:[\w-]+\.)+[\w]{2,20}(?:\.[\w]{2,20})?(?:[/?#]\S*)?$/
      if (filterList.length) {
         // const invalidUrls = urlList.filter((url) => !regex.test(url))
         // if (invalidUrls.length === 0) {
         //    const urlStrings = urlList?.join(', ')
         //    try {
         //       JobDetailStore.postDesignerUpdateURL(jobDetail.id, urlStrings)
         //       showMessageSuccess('Bạn đã lưu URL thành công')
         //    } catch (error) {
         //       showMessageError(error)
         //    } finally {
         //       setViewMode(false)
         //    }
         // } else {
         //    showMessageError(`Vui lòng nhập đúng định dạng URL`)
         // }
         const urlStrings = filterList?.join(', ')
         try {
            JobDetailStore.postDesignerUpdateURL(jobDetail?.id, urlStrings)
            // showMessageSuccess('Bạn đã lưu URL thành công')
            handleSave(true)
            JobDetailStore.setJobDetailUrl(urlStrings)
         } catch (error) {
            showMessageError(error)
         } finally {
            setViewMode(false)
         }
      } else {
         setIsShowError(true)
         // showMessageError('Vui lòng nhập đầy đủ URL')
      }
   }

   return (
      <>
         <Row className="gx-flex-row gx-align-items-center gx-justify-content-between gx-mx-1" wrap={false}>
            <h4 style={{ fontSize: 14, fontWeight: 600, lineHeight: '22px', color: '#272944' }}>
               {t(GLOBAL_CLIENT.url)}
            </h4>
            <div className="gx-flex-row gx-align-items-center gx-justify-content-end">
               {role === ROLE.DESIGNER && status === STATUS_JOB.DOING && (
                  <div className="gx-module-logo gx-mb-2" onClick={handleAddUrlInput}>
                     <img style={{ color: '#272944', marginLeft: 4, height: 20 }} src={Images.ic_plus} alt="icon" />
                     <div
                        style={{ fontSize: 14, fontWeight: 400, lineHeight: '22px', color: '#272944', marginLeft: 8 }}
                     >
                        {t(GLOBAL_CLIENT.themurl)}
                     </div>
                  </div>
               )}
               {role === ROLE.DESIGNER && status === STATUS_JOB.DOING && !isSaving && (
                  <div className="gx-module-logo gx-mb-2" onClick={handleUrlSave}>
                     <SaveOutlined style={{ color: '#272944', marginLeft: 8 }} />
                     <div
                        style={{ fontSize: 14, fontWeight: 400, lineHeight: '22px', color: '#272944', marginLeft: 8 }}
                     >
                        {t(GLOBAL_CLIENT.luuurl)}
                     </div>
                  </div>
               )}
            </div>
         </Row>

         {urlList?.map((urlInput, index) => (
            <Row
               key={index}
               className="gx-flex-row gx-align-items-center gx-justify-content-start gx-mb-2 gx-mx-1"
               wrap={false}
            >
               <Col flex="auto">
                  <div>
                     {role === ROLE.DESIGNER ? (
                        <Input
                           bordered={true}
                           value={urlInput}
                           onChange={(event) => handleUrlInputChange(event, index)}
                           readOnly={role !== ROLE.DESIGNER || status !== STATUS_JOB.DOING}
                           allowClear
                           maxLength={254}
                           style={{
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: '22px',
                              color: '#5C5C5C',
                              paddingTop: 9,
                              paddingBottom: 9,
                              border: 'solid 1px #dadada'
                           }}
                        />
                     ) : (
                        urlInput && (
                           <div
                              style={{
                                 fontSize: 14,
                                 fontWeight: 400,
                                 lineHeight: '22px',
                                 color: '#5C5C5C',
                                 padding: '9px 11px',
                                 borderRadius: 6,
                                 border: 'solid 1px #dadada'
                              }}
                           >
                              {urlInput.includes('http://') || urlInput.includes('https://') ? (
                                 <a href={urlInput} target="_blank" rel="noopener noreferrer external">
                                    {urlInput}
                                 </a>
                              ) : (
                                 <a href={`http://${urlInput}`} target="_blank" rel="noopener noreferrer external">
                                    {urlInput}
                                 </a>
                              )}
                           </div>
                        )
                     )}
                  </div>
               </Col>
               {/* {role === ROLE.DESIGNER && status === STATUS_JOB.DOING && (
                  <Col style={{ margin: '8px 0 8px 12px' }}>
                     <div onClick={() => handleRemoveUrlInput(index)}>{t(GLOBAL_CLIENT.delete)}</div>
                  </Col>
               )} */}
            </Row>
         ))}
         {isShowError && (
            <p style={{ color: '#EC1D2A', fontSize: 12, fontWeight: 400, lineHeight: '18px', marginTop: 4 }}>
               {t(GLOBAL_CLIENT.nhapduurl)}
            </p>
         )}
         {/* <Row className="gx-flex-row gx-align-items-center gx-justify-content-center gx-mx-1" wrap={false}>
            {role === ROLE.DESIGNER && status === STATUS_JOB.DOING && !isSaving && (
               <Button type="primary" onClick={handleUrlSave}>
                  {t(GLOBAL_CLIENT.save)}
               </Button>
            )}
         </Row> */}
      </>
   )
})

export default JobDetailLink
