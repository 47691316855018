import { observable, action } from "mobx";
import { DATE_FORMAT_BACKEND, DATE_FORMAT_CLIENT_PARAMS, ERROR_CODES, PAGE_SIZE, PAGE_SIZE_FULL } from "../helpers/constants";
// import { WsCode } from "../helpers/Wscode";
import { responseDefault } from './store.constants'
import { isEmpty } from "lodash";
import { Request2 } from "src/common/Request2";
import api from "src/common/api";
import moment from "moment";

export class UserStore {
  @observable listCreator: any = { ...responseDefault };
  @observable listDesigner: any = { ...responseDefault };
  @observable listTransaction: any = { ...responseDefault };
  @observable listEmployees: any = { ...responseDefault };
  @observable listMemberFilter: any = { ...responseDefault };
  @observable jobSameNumber: any = {}
  @observable fromSameNumber: Date = new Date(`${new Date().getFullYear()}/01/01`)
  @observable toSameNumber: Date = new Date(`${new Date().getFullYear()}/12/31`)

  @action
  async getCreators(params: any = {}, history?: any) {
    const result = await Request2.getWithToken(api.managerUser.getUser, { ...params, ...{ role: 'creator', limit: PAGE_SIZE_FULL } })
    if (!isEmpty(result)) {
      this.listCreator = result
    }
  }

  @action
  async getDesigners(params: any = {}, history?: any) {
    const result = await Request2.getWithToken(api.managerUser.getUser, { ...params, ...{ role: 'designer', limit: PAGE_SIZE_FULL } })
    if (!isEmpty(result)) {
      this.listDesigner = result
    }
  }

  @action
  async getTransaction(params: any = {}, history?: any) {
    const result = await Request2.getWithToken(api.managerUser.transaction, { ...params, ...{ limit: PAGE_SIZE } })
    if (!isEmpty(result)) {
      this.listTransaction = result
    }
  }

  @action
  async getEmployees(params: any = {}, history?: any) {
    const result = await Request2.getWithToken(api.managerUser.getUser, { ...params, ...{ role: 'supporter,accountant', limit: PAGE_SIZE } })
    if (!isEmpty(result)) {
      this.listEmployees = result
    }
  }

  @action
  async setFromSameNumber(value: any) {
    this.fromSameNumber = value
  }
  @action
  async setToSameNumber(value: any) {
    this.toSameNumber = value
  }
  @action
  async getSameNumber(accountId: string) {
    // console.log('this.fromSameNumber', this.fromSameNumber);
    const result = await Request2.getWithToken(api.job.sameNumber, { accountId, from: moment(this.fromSameNumber).format(DATE_FORMAT_CLIENT_PARAMS), to: moment(this.toSameNumber).format(DATE_FORMAT_CLIENT_PARAMS) })
    // console.log('getSameNumber', result);
    if (!isEmpty(result)) {
      this.jobSameNumber = result
    }
    return result
  }
  @action
  async createEmployees(params: any = {}, history?: any) {
    //  console.log('params: ', params, 'api.managerUser.create: ', api.managerUser.create);

    const result = await Request2.postWithToken(params, api.managerUser.create)
    // console.log('result: ', result);
    return result?.data;
  }

  @action
  async updateEmployees(params: any = {}, id: string | number) {
    // console.log('params: ', params, 'api.managerUser.updateEmployees: ', api.managerUser.update);

    const result = await Request2.patchWithToken(params, api.managerUser.update + '/' + id)
    // console.log('result: ', result);
    return result;
  }

  @action
  async deleteEmployees(id: string | number) {
    // console.log('api.managerUser.deleteEmployees: ', api.managerUser.delete);
    const result = await Request2.deleteWithToken({}, api.managerUser.delete + '/' + id)
    // console.log('result: ', result);
    return result;
  }

  @action
  async getMemberFilter(params: any = {}, history?: any) {
    const result = await Request2.getWithToken(api.managerUser.getUser, { ...params, ...{ role: 'designer,creator', limit: 1000 } })
    if (!isEmpty(result)) {
      this.listMemberFilter = result
    }
  }

  @action
  async resetStore() {
    this.listCreator = { ...responseDefault };
    this.listDesigner = { ...responseDefault };
  }

  @action
  async action_resetPassword(params: any) {
    const result = await Request2.putWithToken(params, api.user.changePassword)
    if (result?.code === ERROR_CODES.SUCCESS) return result
    return false
  }
}
