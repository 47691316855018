import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import i18n from '../../helpers/i18n'

export const adminMeta = [
   {
      label: i18n.t(GLOBAL_CLIENT.mJob),
      path: '/m-job'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.mCreator),
      path: '/m-creator'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.mDesigner),
      path: '/m-designer'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.mEmployee),
      path: '/m-employee'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.mProductType),
      path: '/m-product-type'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.mDesignType),
      path: '/m-design-type'
   },
   // {
   //   label: i18n.t(GLOBAL_CLIENT.mNumberItems),
   //   path: "/m-number-items",
   // },
   // {
   //    label: i18n.t(GLOBAL_CLIENT.mDebt),
   //    path: '/m-debt'
   // },
   {
      label: i18n.t(GLOBAL_CLIENT.mDepositWithdrawal),
      path: '/m-deposit-withdrawal'
   },
   // {
   //    label: i18n.t(GLOBAL_CLIENT.mFund),
   //    path: '/m-fund'
   // },
   {
      label: i18n.t(GLOBAL_CLIENT.mConfig),
      path: '/m-common-config'
   },
   // {
   //    label: i18n.t(GLOBAL_CLIENT.dashboard),
   //    path: '/dashboard'
   // },
   {
      label: i18n.t(GLOBAL_CLIENT.completedQuantity),
      path: '/dashboard/completed-quantity'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.businessResults),
      path: '/dashboard/business-results'
   },
]

export const accountantMeta = [
   {
      label: i18n.t(GLOBAL_CLIENT.mDepositWithdrawal),
      path: '/m-deposit-withdrawal'
   }
]

export const supporterMeta = [

   {
      label: i18n.t(GLOBAL_CLIENT.mJob),
      path: '/m-job'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.mCreator),
      path: '/m-creator'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.mDesigner),
      path: '/m-designer'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.completedQuantity),
      path: '/dashboard/completed-quantity'
   }
]

export const userMeta = [
   {
      label: i18n.t(GLOBAL_CLIENT.listJobs),
      path: '/jobs/recruiting',
      type: 'new'
   },
   {
      label: 'Đang xử lý',
      path: '/jobs/processing',
      type: 'doing'
   },
   {
      label: 'Cần review',
      path: '/jobs/review',
      type: 'review'
   },
   {
      label: 'Cần sửa',
      path: '/jobs/editing',
      type: 'reject'
   },
   {
      label: 'Đã hoàn thành',
      path: '/jobs/done'
   }
]

export const ROUTER_PATH = {
   // Admin
   CreatorManagementScreen: 'm-creator',
   CreatorDetailScreen: 'm-creator/detail',
   CreateCreatorScreen: 'm-creator/create',
   DesignerManagementScreen: 'm-designer',
   DesignerDetailScreen: 'm-designer/detail',
   DebtManagementScreen: 'm-debt',
   DebtDetailScreen: 'm-debt/detail',
   EmployeeManagementScreen: 'm-employee',
   CreateEmployeeScreen: 'm-employee/create',
   PayrollManagementScreen: 'm-payroll',
   CreatePayrollScreen: 'm-payroll/create',
   ReceiptsManagementScreen: 'm-receipts',
   CreateReceiptsScreen: 'm-receipts/create',
   ReceiptsTypeManagementScreen: 'm-receipt-type',
   CreateReceiptsTypeScreen: 'm-receipt-type/create',
   ProductTypeManagementScreen: 'm-product-type',
   DesignTypeManagementScreen: 'm-design-type',
   JobManagementScreen: 'm-job',
   DepositWithdrawalManagementScreen: 'm-deposit-withdrawal',
   CommonManagementScreen: 'm-common-config',
   DashboardScreen: 'dashboard',
   CompletedQuantityScreen: 'dashboard/completed-quantity',
   NumberEditsScreen: 'dashboard/number-edits',
   BusinessResultsScreen: 'dashboard/business-results',

   // User
   JobsDefaultScreen: 'jobs',
   JobsRecruitingScreen: 'jobs/recruiting',
   JobsProcessingScreen: 'jobs/processing',
   JobsReviewScreen: 'jobs/review',
   JobsEditingScreen: 'jobs/editing',
   JobsDoneScreen: 'jobs/done',
   ProfileScreen: 'profiles',
   ResetPassword: 'reset-password',

   // DESIGNER
   JobReceive: 'job/receive',
   JobReview: 'job/review',
   JobUpdateUrl: 'job/designer-update',
   JobConfirm: 'job/confirm',
   // Accountant
}
