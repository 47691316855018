import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import Images from 'src/common/Images'
import { useStore } from 'src/hooks'
import styled from 'styled-components'

const Wrapper = styled.div`
   .fixed-whatsapp {
      position: fixed;
      bottom: 30px;
      right: 30px;
      width: 60px;
      height: 60px;
      line-height: 55px;
      z-index: 9999;
      text-align: center;
   }
   .fixed-whatsapp:before {
      content: '';
      width: 60px;
      height: 60px;
      position: absolute;
      border-radius: 100%;
      box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
      z-index: 1;
      background-color: #ffffff;
      top: 0;
      left: 0;
   }
   .fixed-whatsapp img {
      vertical-align: middle;
      width: 36px;
      height: 36px;
      z-index: 2;
      position: relative;
   }
`
const StickyChatButton = observer(() => {
   const CommonConfigStore = useStore('CommonConfigStore')
   // useEffect(() => {
   //    getLinkSupport()
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [])
   // const getLinkSupport = () => {
   //    if (!CommonConfigStore.linkSupport) {
   //       CommonConfigStore.getListSupport()
   //    }
   // }
   return (
      <Wrapper>
         <a
            className="fixed-whatsapp"
            href={CommonConfigStore.linkSupport}
            rel="nofollow noopener noreferrer"
            title="Telegram"
            target="_blank"
         >
            <img src={Images.chat_support} alt="chat-support" />
         </a>
      </Wrapper>
   )
})

export default StickyChatButton
