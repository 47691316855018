import { LockOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal } from 'antd'
import { debounce } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading } from 'src/common'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { ERROR_CODES } from 'src/helpers/constants'
import { showMessageSuccess } from 'src/helpers/functions'
import { rule } from 'src/helpers/validateRule'
import { useStore } from 'src/hooks'
import styled from 'styled-components'

type PasswordModalProps = {
   isPasswordModalOpen: boolean
   handleCancel: () => void
}

const PasswordModalWrapper = styled.div``

const PasswordModal: React.FC<PasswordModalProps> = ({ isPasswordModalOpen, handleCancel }) => {
   const [form] = Form.useForm()
   const onFinishFailed = (errorInfo: any) => {}
   const { t } = useTranslation()
   const UserStore = useStore('UserStore')
   const [isLoading, setIsLoading] = useState(false)
   const onFinish = async (values: any) => {
      try {
         setIsLoading(true)
         Object.keys(values)?.forEach((k) => (values[k] = values[k]?.trim()))
         const { newPassword, currentPassword } = values
         const result = await UserStore.action_resetPassword({ newPassword, currentPassword })
         if (result.code === ERROR_CODES.SUCCESS) {
            showMessageSuccess(result?.message)
            handleCancel()
         }
      } catch (error) {
         // console.log(error)
      } finally {
         setIsLoading(false)
      }
   }
   return (
      <Modal title="Đổi mật khẩu" visible={isPasswordModalOpen} onCancel={handleCancel} footer={null}>
         <Loading isLoading={isLoading} />
         <PasswordModalWrapper>
            <Form
               form={form}
               name="basic"
               onFinish={debounce(onFinish, 400)}
               onFinishFailed={onFinishFailed}
               className="gx-signin-form gx-form-row0"
            >
               <Form.Item rules={[rule.passwordRule]} name="currentPassword">
                  <Input
                     type="password"
                     placeholder={t(GLOBAL_CLIENT.password)}
                     prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                     maxLength={30}
                  />
               </Form.Item>
               <Form.Item rules={[rule.passwordRule]} name="newPassword">
                  <Input
                     type="password"
                     placeholder={t(GLOBAL_CLIENT.txtNewPassword)}
                     prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                     maxLength={30}
                  />
               </Form.Item>
               <Form.Item
                  rules={[
                     rule.requiredPassword,
                     ({ getFieldValue }) => ({
                        validator(_, value) {
                           if (!value || getFieldValue('newPassword') === value) {
                              return Promise.resolve()
                           }
                           return Promise.reject(new Error(t(GLOBAL_CLIENT.matkhaukhongtrungkhop)))
                        }
                     })
                  ]}
                  name="confirm_password"
                  dependencies={['newPassword']}
               >
                  <Input
                     type="password"
                     placeholder={t(GLOBAL_CLIENT.xacnhanmatkhau)}
                     prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                     maxLength={30}
                  />
               </Form.Item>
               <Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                     {t(GLOBAL_CLIENT.txtUpdate)}
                  </Button>
               </Form.Item>
            </Form>
         </PasswordModalWrapper>
      </Modal>
   )
}

export default PasswordModal
