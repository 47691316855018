import styled from 'styled-components'

export const AuthenWrapper = styled.div`
   height: 100%;
   line-height: 22px;
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   color: #ffffff;
   .login__banner {
      background-color: #ff9a40;
      img {
         object-fit: cover;
         width: 100%;
         height: 100%;
      }
   }
   .login__content {
      background-color: ${(props) => props.theme.color.dark_2};
      padding: 120px 16px 16px;
      display: flex;
      flex-direction: column;
   }
   .signup__content {
      background-color: ${(props) => props.theme.color.dark_2};
      padding: 24px 16px 48px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
   }
   .ant-checkbox {
      &::after {
         border: none;
      }
      .ant-checkbox-inner {
         border-radius: 4px;
         background-color: unset;
         border: 1.5px solid #ffffff;
      }
   }
   a {
      color: ${(props) => props.theme.color.primary};
   }
   .form__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
   }
   .form__desc {
      font-weight: 400;
      font-size: 14px;
      margin-top: 8px;
      color: #f1f1f1;
   }
   .ant-input-affix-wrapper {
      padding: 9px 16px;
      .ant-input-prefix {
         margin-right: 16px;
      }
      input {
         font-size: 14px;
         line-height: 22px;
      }
   }
   .ant-form-item-explain-error {
      text-align: start;
   }
   .ant-form-item {
      &:first-child {
         margin-bottom: 16px;
      }
      margin-bottom: 24px;
   }
   .ant-checkbox-wrapper {
      color: white;
   }
   @media (max-width: 767px) {
      grid-template-columns: auto;
      .login__banner {
         display: none;
      }
   }
`

export const SignupWrapper = styled.div`
   padding: 16px;
   padding-top: unset;
   text-align: center;
   .signup-content__form {
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
      .signup-form__content {
         margin-top: 8px;
         .signup-form__footer {
            color: white;
            margin: unset;
            display: flex;
            justify-content: space-between;
            .signin__link {
               margin-top: auto;
               margin-bottom: auto;
            }
         }
         .ant-form-item.signup-form__term {
            margin-top: 16px;
            margin-bottom: 16px;
            text-align: start;
         }
         .ant-form-item {
            margin-bottom: 8px;
         }
         .form-item__wrapper {
            .form-item__label {
               margin-bottom: 8px;
               color: #ffffff;
               text-align: start;
               font-weight: 600;
            }
         }
      }
   }
`

export const LoginWrapper = styled.div`
   background-color: ${(props) => props.theme.color.dark_2};
   padding: 16px;
   padding-top: unset;
   .login-content__body {
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-width: 370px;
      margin-left: auto;
      margin-right: auto;
      .login-content__logo {
         text-align: center;
         img {
            width: auto;
            height: 32px;
         }
      }
      .login-content__form {
         text-align: center;
         .login-form__content {
            margin-top: 48px;
            padding: 0 24px;
         }
      }
   }
   .login-content__footer {
      text-align: center;
      margin-top: 140px;
      a {
         color: ${(props) => props.theme.color.primary};
      }
   }
`
