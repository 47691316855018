import { Badge } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStore } from 'src/hooks'

type SidebarItemProps = {
   data: any
}

const SidebarItem: React.FC<SidebarItemProps> = observer(({ data }) => {
   const JobsStore = useStore('JobsStore')
   const jobsCount: any = JobsStore.jobsCount
   const [jobCount, setJobCount] = useState<number>(0)
   useEffect(() => {
      const count = jobsCount[data?.type] || 0
      setJobCount(count)
      // (jobsCount)
   }, [Object.values(jobsCount).map((item: any) => item)])
   useEffect(() => {})

   return (
      <Badge count={jobCount} offset={[24, 7]} overflowCount={99} className={`${jobCount > 99 ? 'overflow' : ''}`}>
         <span className="nav-item__label">{data?.label}</span>
      </Badge>
   )
})

export default SidebarItem
