import { Input, Form } from 'antd'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ERROR_CODES } from '../../helpers/constants'
import { Loading } from '../../common'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useStore } from '../../hooks'
import { rule } from '../../helpers/validateRule'
import { UserOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import Images from 'src/common/Images'
import { showMessageSuccess } from 'src/helpers/functions'
import { debounce } from 'lodash'
import { AuthenWrapper, SignupWrapper } from 'src/styles/login/Authen.style'
import BaseButton from 'src/common/components/button'

const LoginScreen = observer((props: any) => {
   const AuthStore = useStore('AuthStore')
   const { t } = useTranslation()
   const [isLoading, setIsLoading] = useState(false)
   // const { history } = props
   const [form] = Form.useForm()

   const onFinishFailed = (errorInfo: any) => {}

   const onFinish = async (values: any) => {
      try {
         setIsLoading(true)

         Object.keys(values).forEach((k) => (values[k] = values[k].trim()))
         const result = await AuthStore.action_forgotPassword(values)
         if (result?.code === ERROR_CODES.SUCCESS) {
            showMessageSuccess(t(GLOBAL_CLIENT.confirmEmailSuccess))
         }
      } catch (error) {
         //  console.log(error)
      } finally {
         setIsLoading(false)
      }
   }

   return (
      <AuthenWrapper>
         {/* <Loading isLoading={isLoading} /> */}
         <div className="login__banner">
            <img src={Images.bg_login} alt="" />
         </div>
         <SignupWrapper className="login__content">
            <div className="signup-content__body">
               <div className="signup-content__form">
                  <div className="form__title"> {t(GLOBAL_CLIENT.forgotPassword)}</div>
                  <div className="form__desc">{t(GLOBAL_CLIENT.enterVerifyEmail)}</div>
                  <Form
                     form={form}
                     name="basic"
                     onFinish={debounce(onFinish, 400)}
                     onFinishFailed={onFinishFailed}
                     className="gx-signin-form gx-form-row0 signup-form__content"
                  >
                     <Form.Item rules={[rule.emailRule, rule.requiredEmail]} name="email">
                        <Input
                           placeholder={t(GLOBAL_CLIENT.email)}
                           prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                           maxLength={30}
                        />
                     </Form.Item>
                     <Form.Item style={{ marginBottom: 'unset', marginTop: '24px' }}>
                        <div className="signup-form__footer">
                           <div className="signin__link">
                              <span>{t(GLOBAL_CLIENT.or)}</span> <Link to="/signin">{t(GLOBAL_CLIENT.login)}</Link>
                           </div>
                           <BaseButton type="primary" className="gx-mb-0">
                              {t(GLOBAL_CLIENT.submit)}
                           </BaseButton>
                        </div>
                     </Form.Item>
                  </Form>
               </div>
            </div>
         </SignupWrapper>
      </AuthenWrapper>
   )
})

export default LoginScreen
