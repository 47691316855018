import { Menu } from 'antd'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { userMeta } from '../../routes/metaData'
import styled from 'styled-components'
import { useEffect } from 'react'
import { socket } from 'src/helpers/socket/socket'
import { useStore } from 'src/hooks'
import NavItem from './NavItem'
import { ROLE, SOCKET_EVENT } from 'src/helpers/constants'
import useModal from 'src/helpers/modal/useModal'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'

const NavMenuWrapper = styled(Menu)`
   display: flex;
   flex: 1;
   justify-content: space-between;
   .nav-item__wrapper {
      color: ${(props) => props.theme.color.secondary};
      span.ant-badge.overflow {
         .ant-scroll-number.ant-badge-count.ant-badge-multiple-words {
            padding: 0 3px;
            width: unset;
            height: unset;
         }
      }
      .ant-badge-count {
         box-shadow: unset;
         width: 16px;
         min-width: 16px;
         height: 16px;
         padding: unset;
         background-color: ${(props) => props.theme.color.red_0};
         .ant-scroll-number-only-unit {
            font-weight: 500;
            font-size: 9px;
            line-height: 16px;
         }
      }
   }
   .nav-item__wrapper.ant-menu-item-selected {
      & .nav-item__label {
         color: ${(props) => props.theme.color.primary};
      }
      border-bottom-color: ${(props) => props.theme.color.primary};
   }
   .nav-item__label {
      font-weight: 500;
      font-size: 16px;
      color: ${(props) => props.theme.color.white};
   }
`
const HorizontalNav = observer((props: any) => {
   const JobsStore = useStore('JobsStore')
   const JobDetailStore = useStore('JobDetailStore')
   const AuthStore = useStore('AuthStore')
   const { location } = props
   const { t } = useTranslation()
   const pathUrl = location.pathname
   const selectedKeys = pathUrl
   const { jobsCount } = JobsStore
   const history = useHistory()
   const role = AuthStore.data_role
   const { confirm } = useModal()

   const initSocket = async () => {
      await socket.connect()
   }
   const getDataJobCount = async () => {
      const result = await JobsStore.getJobCount()
      console.log(role, result, jobsCount?.reject)

      if (role === ROLE.DESIGNER && result && result?.reject) {
         confirm({
            title: t(GLOBAL_CLIENT.thongbao),
            icon: null,
            content: `Bạn có ${result?.reject || ''} việc cần sửa, hãy kiểm tra và xử lý`,
            onOk() {
               history.push('/jobs/editing')
               // handleJobReceive()
            },
            onCancel() {
               // console.log('Cancel')
            },
            okText: t(GLOBAL_CLIENT.xacnhan),
            cancelText: t(GLOBAL_CLIENT.huy)
         })
      }
   }
   useEffect(() => {
      getDataJobCount()
   }, [])

   useEffect(() => {
      initSocket()
      // socket.on(SOCKET_EVENT.NEW_JOB, (data) => {
      //    // console.log('on socket new job', data)
      //    JobsStore.getJobCount()
      // })
      socket.on(SOCKET_EVENT.CONNECTED, (data) => {
         console.log('on socket CONNECTED', JSON.stringify(data))
      })
      // socket.on(SOCKET_EVENT.NOTIFICATION, (data) => {
      //    console.log('on socket NOTIFICATION 2', JSON.stringify(data))
      // })
      socket.on(SOCKET_EVENT.NEW_JOB, (data) => {
         console.log('on socket new job', JSON.stringify(data))
         setTimeout(() => {
            JobsStore.getJobCount()
            JobsStore.action_setItemJobsRecruiting(data)
         }, 10000);
      })

      socket.on(SOCKET_EVENT.RECEIVE_JOB, (data) => {
         console.log('on socket RECEIVE_JOB', JSON.stringify(data))
         // JobsStore.getJobCount()
         if (data &&
            data?.designerId &&
            role === ROLE.DESIGNER &&
            AuthStore?.data_getUserInfo?.id !== data?.designerId) {
            JobsStore.action_removeJob(data.id)
            JobsStore.getJobCount()
         }
      })

      socket.on(SOCKET_EVENT.CHANGE_STATUS, (data) => {
         // console.log('on socket change status', data)
         JobsStore.getJobCount()
      })

      socket.on(SOCKET_EVENT.MESSAGE, (data) => {
         // console.log('on socket change MESSAGE', data)
         JobDetailStore.setNewComment(data, role)
      })

      socket.on(SOCKET_EVENT.REJECT_JOB, (data) => {
         console.log('on socket change REJECT_JOB', data)
         // JobDetailStore.setNewComment(data, role)
         if (role === ROLE.DESIGNER) {
            confirm({
               title: t(GLOBAL_CLIENT.thongbao),
               icon: null,
               content: `Bạn có ${data?.countJobReject || ''} việc cần sửa, hãy kiểm tra và xử lý`,
               onOk() {
                  history.push('/jobs/editing')
                  // handleJobReceive()
               },
               onCancel() {
                  // console.log('Cancel')
               },
               okText: t(GLOBAL_CLIENT.xacnhan),
               cancelText: t(GLOBAL_CLIENT.huy)
            })
         }
      })
   }, [])

   const handleClickMenuItem = (item: any) => {
      // JobsStore.resetJobCount(item?.type)
      history.push(item?.path)
   }

   return (
      <NavMenuWrapper selectedKeys={[selectedKeys]} mode="horizontal" className="m-horizon-nav__menu">
         {userMeta?.map((item) => {
            return (
               <Menu.Item key={item?.path} className="nav-item__wrapper" onClick={() => handleClickMenuItem(item)}>
                  <NavItem data={item} jobsCount={jobsCount} />
               </Menu.Item>
            )
         })}
      </NavMenuWrapper>
   )
})

export default HorizontalNav
