import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { userMeta } from "../routes/metaData";
const DefaultUserScreen = observer((props: any) => {
  const history = useHistory();
  useEffect(() => {
    history.push(userMeta[0].path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
});

export default DefaultUserScreen;
