import { Col, Row, Modal, Select, Space, InputNumber, message } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import JobDetailTagList from './components/JobDetailTagList'
import JobDetailUpload from './components/JobDetailUpload'
import JobDetailDescription from './components/JobDetailDescription'
import { Request2 } from 'src/common/Request2'
import api from 'src/common/api'
import { Loading } from 'src/common'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useStore } from 'src/hooks'
import { formatCurrency, showMessageError, showMessageSuccess } from 'src/helpers/functions'
import JobDetailOrderName from './components/JobDetailOrderName'
import JobDetailOrderInfo from './components/JobDetailOrderInfo'
import { ExchangeFeeMinMax, ROLE, getUniqueCode } from 'src/helpers/constants'

import CustomButton from 'src/common/components/button/Custom'
interface ImageType {
   id: number
   path: string
   description: string
}

interface JobDetailProps {
   // handleOk: () => void
   handleRefreshJobs?: () => void
}

export const AddJobDetailModal: React.FC<JobDetailProps> = observer(({ handleRefreshJobs }) => {
   const { t } = useTranslation()
   const [isLoading, setIsLoading] = useState(false)
   const [jobId, setJobId] = useState<number>(0)
   const [editedImageId, setEditedImageId] = useState(null)
   const [isEditingDescription, setIsEditingDescription] = useState<boolean>(false)
   const [isShowErrorPrice, setIsShowErrorPrice] = useState<boolean>(false)
   const [exchangeFee, setExchangeFee] = useState<any>({
      min: ExchangeFeeMinMax.min,
      max: ExchangeFeeMinMax.max
   })
   const [createMode, setCreateMode] = useState<boolean>(true)
   const CommonConfigStore = useStore('CommonConfigStore')
   const { designTypesList, productTypesList, designerConfigList, trustDesigner } = CommonConfigStore
   const JobDetailStore = useStore('JobDetailStore')
   const {
      jobDetail,
      setJobDetailName,
      setJobDetailProductTypeId,
      setJobDetailDesignTypeId,
      setJobDetailPrice,
      setJobDetailCount,
      setTrustDesigner
   } = JobDetailStore
   const {
      name,
      count,
      designTypeId,
      productTypeId,
      note,
      price,
      tag,
      images,
      target,
      targetId,
      status,
      targetIdSave
   } = jobDetail

   const [lastJobPrice, setLastJobPrice] = useState<number>(price)
   const AuthStore = useStore('AuthStore')
   const role = AuthStore.data_role
   const JobsStore = useStore('JobsStore')
   const updatedAddImages = useRef<any>([])
   const { confirm } = Modal

   const handlePaste = async (event: any) => {
      const items = event.clipboardData?.items
      // console.log('pasteeeee', items)

      try {
         if (items) {
            setIsLoading(true)
            for (let i = 0; i < items?.length; i++) {
               const item = items[i]
               if (item?.type?.indexOf('image') !== -1) {
                  const blob = item.getAsFile()
                  if (blob) {
                     const { data } = await Request2.uploadFile(blob, api.upload.uploadFile)
                     const newImage = {
                        path: data.filename,
                        description: `Ảnh ${updatedAddImages.current?.length + 1}`
                     }
                     updatedAddImages.current = [...updatedAddImages.current, newImage]
                     // console.log('newImage', newImage)
                     // console.log('updatedAddImages.current', updatedAddImages.current)
                     JobDetailStore.setJobDetailImages(updatedAddImages.current)
                     setIsLoading(false)
                     break
                  }
               }
            }
         }
      } catch (error) {
         showMessageError(error)
      } finally {
         event.clipboardData?.items.clear()
         setIsLoading(false)
      }
   }

   const handleOrderNameChange = (event: any) => {
      setJobDetailName(event.target.value)
   }
   const handleCancel = () => {
      AuthStore.action_HideAddJobDetail()
   }
   const countOptions = []

   for (let i = 1; i <= 2; i++) {
      countOptions.push({
         value: i,
         label: i.toString()
      })
   }

   const designTypesOption = designTypesList?.map((item: any) => {
      return {
         value: item?.id,
         label: item?.name
      }
   })
   // const designTypesOption = designTypesList?.map((item: any) => {
   //    return {
   //       value: item?.id,
   //       label: item?.name
   //    }
   // })

   const productTypesOption = productTypesList?.map((item: any) => {
      return {
         value: item.id,
         label: item.name
      }
   })

   const handleClearImage = () => {
      // console.log('up11', jobDetail?.images)
      updatedAddImages.current = [...jobDetail?.images]
      // console.log('up', updatedAddImages.current)
   }

   const handleAfterClose = () => {
      // clearClipboard()
      updatedAddImages.current = []
      JobDetailStore.resetJobDetail()
   }

   const getConfigDesigner = async () => {
      await CommonConfigStore.getConfigDesigner()
   }
   const getTrustDesigner = async () => {
      await CommonConfigStore.getTrustDesigner()
   }

   const handleOk = async () => {
      setIsEditingDescription(false)
      const imageReq = jobDetail.images?.map((image: any) => {
         const { id, ...rest } = image
         return rest
      })

      const data = {
         name: name,
         price: price,
         note: note?.trim(),
         images: imageReq,
         count,
         tag: tag?.trim(),
         // productTypeId: 2,
         // designTypeId: 3
         designTypeId,
         productTypeId,
         target: targetId
      }
      try {
         setIsLoading(true)
         // console.log(price)
         if (
            count &&
            price >= exchangeFee.min &&
            price <= exchangeFee.max &&
            imageReq?.length &&
            editedImageId === null &&
            !isEditingDescription &&
            !isShowErrorPrice
         ) {
            // console.log(editedImageId)
            const res = await JobDetailStore.postJob(data)
            console.log('----1111--res', res)

            setJobId(res?.data?.id)
            if (res?.code === 200) {
               handleRefreshJobs && handleRefreshJobs()
               AuthStore.action_getProfile()
               setCreateMode(false)
               handleCancel()
            }
         } else {
            if (!count) showMessageError('Vui lòng nhập số lượng')
            else if (!(price >= exchangeFee.min && price <= exchangeFee.max))
               showMessageError(
                  `Vui lòng nhập giá trong khoảng từ ${formatCurrency(exchangeFee?.min, true)} đến ${formatCurrency(
                     exchangeFee?.max,
                     true
                  )}`
               )
            else if (!imageReq?.length) showMessageError('Vui lòng chọn ảnh')
            else if (editedImageId !== null) showMessageError('Vui lòng lưu mô tả ảnh')
            else if (isEditingDescription) showMessageError('Vui lòng lưu mô tả chi tiết')
         }
      } catch (error) {
         console.error(error)
      } finally {
         setIsLoading(false)
      }
   }

   const fetchExchangeFee = async () => {
      let isDesignTypeExist = designTypesOption.some((item: any) => item.value === jobDetail?.designTypeId)
      let isProductTypeExist = productTypesOption.some((item: any) => item.value === jobDetail?.productTypeId)
      if (!isProductTypeExist) {
         setJobDetailProductTypeId(undefined)
      }
      if (!isDesignTypeExist) {
         setJobDetailDesignTypeId(undefined)
      }
      try {
         const params = {
            designTypeId: jobDetail?.designTypeId,
            productTypeId: jobDetail?.productTypeId
            // designTypeId: 3,
            // productTypeId: 2
         }
         if (jobDetail?.designTypeId && jobDetail?.productTypeId) {
            const { max, min } = await Request2.getWithTokenNoMessage(api.exchangeFee.getFeeInfo, params)
            setExchangeFee({
               max: max ?? ExchangeFeeMinMax.max,
               min: min ?? ExchangeFeeMinMax.min
            })
         }
      } catch (error) {
         console.error(error)
      }
   }

   const handleSelectOption = async () => {
      await fetchExchangeFee()
   }

   useEffect(() => {
      if (AuthStore.isOpenAddJobDetail) {
         // console.log('updatedAddImages.current', updatedAddImages.current)
         // console.log('jobDetail?.images', jobDetail?.images)
         window.addEventListener('paste', handlePaste)
         setCreateMode(true)
         getConfigDesigner()
         getTrustDesigner()
         fetchExchangeFee()
         updatedAddImages.current = []
         setJobDetailName(getUniqueCode())
         // console.log('updatedAddImages.current2', updatedAddImages.current)
         // console.log('jobDetail?.images2', jobDetail?.images)
      } else {
         setEditedImageId(null)
         setIsEditingDescription(false)
         JobDetailStore?.setImageUrls([])
      }
      return () => {
         window.removeEventListener('paste', handlePaste)
      }
   }, [AuthStore.isOpenAddJobDetail])

   useEffect(() => {
      JobDetailStore.setJobDetailPrice(exchangeFee.min)
   }, [exchangeFee])

   useEffect(() => {
      CommonConfigStore.filterTrustDesigner(targetId || targetIdSave)
   }, [targetId, targetIdSave, trustDesigner])

   const handleChangeTarget = (value: any) => {
      if (value.length <= parseInt(designerConfigList.maxTarget)) {
         setTrustDesigner(value)
      } else {
         message.warning(`Bạn chỉ có thể chọn tối đa ${parseInt(designerConfigList.maxTarget)} designer`)
      }
   }

   const handleJobUpdate = async () => {
      setIsEditingDescription(false)
      const data = {
         name: name?.trim(),
         price,
         note: note?.trim(),
         images,
         count,
         tag: tag?.trim(),
         productTypeId: productTypeId,
         designTypeId: designTypeId
      }
      try {
         if (
            count &&
            price >= exchangeFee.min &&
            price <= exchangeFee.max &&
            images?.length &&
            jobId &&
            editedImageId === null &&
            !isShowErrorPrice
         ) {
            setIsLoading(true)

            const response = await JobDetailStore.updateJob(jobId, data)
            if (response?.code === 200) {
               handleRefreshJobs && handleRefreshJobs()
               // showMessageSuccess('Bạn đã sửa job thành công')
               // handleCancel()
            }
         } else {
            if (!count) showMessageError(t(GLOBAL_CLIENT.vuilongnhapsoluong))
            else if (!(price >= exchangeFee.min && price <= exchangeFee?.max))
               showMessageError(
                  t(GLOBAL_CLIENT.vuilongnhapgia)
                     .replace('{0}', formatCurrency(exchangeFee?.min, true))
                     .replace('{1}', formatCurrency(exchangeFee?.max, true))
               )
            else if (!images?.length) showMessageError(t(GLOBAL_CLIENT.vuilongnhapanh))
            else if (editedImageId !== null) showMessageError(t(GLOBAL_CLIENT.vuilongluumotaanh))
         }
      } catch (error) {
         // console.log(error)
      } finally {
         setIsLoading(false)
      }
   }

   const handleJobDelete = async () => {
      try {
         setIsLoading(true)
         await JobDetailStore.deleteJob(jobId)
         handleRefreshJobs && handleRefreshJobs()
         setTimeout(() => {
            handleCancel()
         }, 500)
      } catch (error) {
         // console.log(error)
      } finally {
         setIsLoading(false)
      }
   }
   console.log('jobDetail?.targetId', jobDetail?.targetId)

   const handlePriceChange = (value: any) => {
      const parsedValue = parseFloat(value)
      if (parsedValue % 1000 === 0) {
         setJobDetailPrice(parsedValue)
         setIsShowErrorPrice(false)
      } else {
         setIsShowErrorPrice(true)
      }
   }
   console.log('v112112')

   return (
      <>
         <Modal
            visible={AuthStore.isOpenAddJobDetail}
            onOk={handleOk}
            onCancel={handleCancel}
            afterClose={handleAfterClose}
            closable={true}
            width={'90%'}
            style={{ maxWidth: '800px' }}
            maskClosable={true}
            footer={null}
            zIndex={999}
         >
            <Loading isLoading={isLoading} />

            {createMode ? (
               <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                  <Row
                     gutter={[16, 16]}
                     className="gx-flex-row gx-align-items-center gx-justify-content-start gx-mb-2"
                     wrap={false}
                     style={{ paddingLeft: 4, paddingRight: 4 }}
                  >
                     <Col xs={23}>
                        <div
                           style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: 'vertical'
                           }}
                        >
                           <h4
                              className="gx-font-weight-semi-bold gx-mb-0"
                              style={{
                                 fontSize: '20px',
                                 lineHeight: '28px',
                                 fontWeight: 600,
                                 color: '#272944'
                              }}
                           >
                              {name}
                           </h4>
                        </div>
                     </Col>
                  </Row>
                  <Row
                     style={{ paddingTop: 6, paddingLeft: 4, paddingRight: 4 }}
                     gutter={[16, 8]}
                     className="gx-flex-row gx-justify-content-start"
                  >
                     <Col
                        className="gx-flex-column gx-align-items-start gx-py-1"
                        xs={{ span: 12, order: 2 }}
                        md={{ span: 6, order: 2 }}
                     >
                        <h4
                           style={{
                              fontSize: 14,
                              fontWeight: 600,
                              lineHeight: '22px',
                              color: '#272944',
                              marginBottom: 8
                           }}
                        >
                           {t(GLOBAL_CLIENT.designType)}
                        </h4>
                        <Select
                           showSearch
                           style={{ width: '100%' }}
                           value={jobDetail?.designTypeId}
                           placeholder={t(GLOBAL_CLIENT.chon)}
                           optionFilterProp="children"
                           onChange={(val) => setJobDetailDesignTypeId(val)}
                           filterOption={(input: string, option: any) =>
                              (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())
                           }
                           options={designTypesOption}
                           onSelect={handleSelectOption}
                        />
                     </Col>
                     <Col
                        className="gx-flex-column gx-align-items-start gx-py-1"
                        xs={{ span: 12, order: 4 }}
                        md={{ span: 6, order: 3 }}
                     >
                        <h4
                           style={{
                              fontSize: 14,
                              fontWeight: 600,
                              lineHeight: '22px',
                              color: '#272944',
                              marginBottom: 8
                           }}
                        >
                           {t(GLOBAL_CLIENT.productType)}
                        </h4>
                        <Select
                           showSearch
                           style={{ width: '100%' }}
                           placeholder={t(GLOBAL_CLIENT.chon)}
                           value={jobDetail?.productTypeId}
                           onChange={(val) => setJobDetailProductTypeId(val)}
                           optionFilterProp="children"
                           filterOption={(input: string, option: any) =>
                              (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())
                           }
                           options={productTypesOption}
                           onSelect={handleSelectOption}
                        />
                     </Col>
                     <Col
                        className="gx-flex-column gx-align-items-start gx-py-1"
                        xs={{ span: 8, order: 3 }}
                        md={{ span: 6, order: 4 }}
                     >
                        <h4
                           style={{
                              fontSize: 14,
                              fontWeight: 600,
                              lineHeight: '22px',
                              color: '#272944',
                              marginBottom: 8
                           }}
                        >
                           {t(GLOBAL_CLIENT.quantityModel)}
                        </h4>
                        <Select
                           showSearch
                           style={{ width: '100%' }}
                           value={count || undefined}
                           placeholder={t(GLOBAL_CLIENT.chon)}
                           onChange={(val) => setJobDetailCount(val)}
                           optionFilterProp="children"
                           filterOption={(input: string, option: any) =>
                              (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())
                           }
                           options={countOptions}
                           disabled={Boolean(!productTypeId || !designTypeId)}
                        />
                     </Col>
                     <Col
                        className=" gx-flex-column gx-py-1 gx-align-items-start"
                        xs={{ span: 12, order: 5 }}
                        md={{ span: 6, order: 5 }}
                     >
                        <h4
                           style={{
                              fontSize: 14,
                              fontWeight: 600,
                              lineHeight: '22px',
                              color: '#272944',
                              marginBottom: 8
                           }}
                        >
                           {t(GLOBAL_CLIENT.pricePerModel)}
                        </h4>
                        <InputNumber
                           value={price}
                           formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                           parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                           min={exchangeFee.min}
                           max={exchangeFee.max}
                           step={1000}
                           placeholder={t(GLOBAL_CLIENT.inputPrice)}
                           onChange={handlePriceChange}
                           onBlur={() => setIsShowErrorPrice(false)}
                           style={{ marginBottom: '10px' }}
                           disabled={Boolean(!productTypeId || !designTypeId)}
                           // onChange={() => onChangePrice}
                        />
                        {isShowErrorPrice && (
                           <p
                              style={{
                                 fontSize: 11,
                                 fontWeight: 400,
                                 lineHeight: '16px',
                                 color: '#EC1D2A',
                                 marginBottom: 8,
                                 fontStyle: 'italic'
                              }}
                           >
                              {t(GLOBAL_CLIENT.vuilongnhapgiachiahet1000)}
                           </p>
                        )}
                        <p
                           style={{
                              fontSize: 11,
                              fontWeight: 400,
                              lineHeight: '16px',
                              color: '#272944',
                              marginBottom: 8,
                              fontStyle: 'italic'
                           }}
                        >
                           {t(GLOBAL_CLIENT.giatu)
                              .replace('{0}', formatCurrency(exchangeFee?.min, true))
                              .replace('{1}', formatCurrency(exchangeFee?.max, true))}
                        </p>
                        {/* <Input
                        placeholder={t(GLOBAL_CLIENT.inputPrice)}
                        type="number"
                        value={price || undefined}
                        onChange={(e) => setJobDetailPrice(Number(e.target.value))}
                        style={{ marginBottom: '10px' }}
                        disabled={Boolean(!productTypeId || !designTypeId)}
                     /> */}
                     </Col>
                  </Row>
                  <Row
                     gutter={[16, 16]}
                     className="gx-flex-row gx-align-items-center gx-justify-content-start gx-mb-2"
                     wrap={false}
                     style={{ paddingLeft: 4, paddingRight: 4 }}
                  >
                     <Col
                        className="gx-flex-column gx-align-items-start gx-py-1"
                        xs={{ span: 12, order: 2 }}
                        md={{ span: 12, order: 2 }}
                     >
                        <h4
                           style={{
                              fontSize: 14,
                              fontWeight: 600,
                              lineHeight: '22px',
                              color: '#272944',
                              marginBottom: 8
                           }}
                        >
                           {'Chọn Designer'}
                        </h4>
                        <Select
                           showSearch
                           style={{ width: '100%' }}
                           value={jobDetail?.targetId ? jobDetail?.targetId : undefined}
                           placeholder={t(GLOBAL_CLIENT.chon)}
                           optionFilterProp="children"
                           onChange={handleChangeTarget}
                           filterOption={(input: string, option: any) =>
                              (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())
                           }
                           options={trustDesigner || []}
                           mode="multiple"
                           onSelect={handleSelectOption}
                        />
                     </Col>
                  </Row>
                  <JobDetailUpload
                     clearImage={handleClearImage}
                     editedImageId={editedImageId}
                     setEditedImageId={setEditedImageId}
                  />
                  <JobDetailDescription isEditingDescription={true} setIsEditingDescription={setIsEditingDescription} />
                  <JobDetailTagList />
                  <Row
                     className="gx-flex-row gx-align-items-center gx-justify-content-center gx-mb-2 gx-mx-1"
                     wrap={false}
                  >
                     <CustomButton type="secondary" onClick={handleCancel}>
                        {t(GLOBAL_CLIENT.huy)}
                     </CustomButton>
                     <CustomButton key="submit" type="primary" onClick={handleOk}>
                        {t(GLOBAL_CLIENT.xacnhan)}
                     </CustomButton>
                  </Row>
               </Space>
            ) : (
               <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                  <JobDetailOrderName />
                  <JobDetailOrderInfo />
                  <JobDetailUpload
                     clearImage={handleClearImage}
                     editedImageId={editedImageId}
                     setEditedImageId={setEditedImageId}
                  />
                  <JobDetailDescription
                     isEditingDescription={isEditingDescription}
                     setIsEditingDescription={setIsEditingDescription}
                  />
                  {role === ROLE.CREATOR && <JobDetailTagList />}
                  <Row
                     className="gx-flex-row gx-align-items-center gx-justify-content-center gx-mb-2 gx-mx-1"
                     wrap={false}
                  >
                     <CustomButton type="secondary" onClick={handleJobDelete}>
                        {t(GLOBAL_CLIENT.delete)}
                     </CustomButton>
                     <CustomButton type="primary" onClick={handleJobUpdate}>
                        {t(GLOBAL_CLIENT.edit)}
                     </CustomButton>
                  </Row>
               </Space>
            )}
         </Modal>
      </>
   )
})
