import { observable, action } from "mobx";
import { FILTER_TIME, PAGE_SIZE, PAGE_SIZE_CHAT, tabIDs } from "../helpers/constants";
import { Request2 } from "src/common/Request2";
import api from "src/common/api";
import { isEmpty, map, reverse, size } from "lodash";
import moment from "moment";
import { responseDefault } from "./store.constants";

export class DashboardStore {
  @observable activeKeyBusinessResult: string = tabIDs.designer;
  @observable activeKeyCompletedQuantity: string = tabIDs.designer;
  @observable activeKeyNumberEdit: string = tabIDs.designer;
  @observable startTime: moment.Moment = FILTER_TIME[1].startTime;
  @observable endTime: moment.Moment = FILTER_TIME[1].endTime;
  @observable startTimeCompletedQuantity: moment.Moment = FILTER_TIME[1].startTime;
  @observable endTimeCompletedQuantity: moment.Moment = FILTER_TIME[1].endTime;
  @observable listRevenue = [];
  @observable listJobFinish = { ...responseDefault }

  @action
  setDate(date: Array<moment.Moment>) {
    console.log("date nè", date);

    if (date) {
      this.startTime = date[0];
      this.endTime = date[1];
    }
  }

  @action
  setStartTime(value: any) {
    if (value) {
      this.startTime = value;
    }
  }

  @action
  setEndTime(value: any) {
    if (value) {
      this.endTime = value;
    }
  }

  @action
  setActiveKeyBusinessResult(value: string) {
    this.activeKeyBusinessResult = value;
  }
  @action
  setActiveKeyCompletedQuantity(value: string) {
    this.activeKeyCompletedQuantity = value;
    console.log('vakue', value);

  }
  @action
  setActiveKeyNumberEdit(value: string) {
    this.activeKeyNumberEdit = value;
  }

  @action
  async getJobFinish(params: any = {},) {
    const result = await Request2.getWithToken(api.dashboard.jobFinish, { ...params, ...{ limit: PAGE_SIZE_CHAT } })
    if (!isEmpty(result)) {
      // console.log('getFundList', result);
      if (size(result?.rows) > 0) {
        //@ts-ignore
        let data = []
        map(result?.rows, (item: any) => {
          if (size(item?.data) > 0) {
            let total = 0;

            let days = {}
            map(reverse(item?.data), (itemChild: any) => {
              const keyName = itemChild.day;
              let temp = { [keyName]: { ...itemChild } }
              days = { ...days, ...temp }
              total += (itemChild?.value || 0)
            })
            data.push({ name: item?.name, userId: item?.userId, total, data: item?.data, ...days })
          } else {
            data.push({ name: item?.name, userId: item?.userId, total: 0, data: item?.data })
          }
        })

        // const firstItem = { name: 'Tổng cộng', total: this.totalJobFinish }
        // data.unshift(firstItem)
        //@ts-ignore
        this.listJobFinish = { ...result, rows: [...data] };
        // console.log('result;', result)
        // console.log('----data', JSON.stringify({ ...result, rows: [...data] }));

      }
    }
  }

  @action
  async getRevenue(params: any = {},) {
    const result = await Request2.getWithToken(api.dashboard.revenue, { ...params, ...{ limit: PAGE_SIZE_CHAT } })
    if (!isEmpty(result)) {
      console.log('getRevenue', result);
      if (size(result) > 0) {
        //@ts-ignore
        let data = []
        map(result, (item: any) => {
          if (size(item?.day) > 0) {
            let totalRevenue = 0;
            let totalExpense = 0;
            let totalProfit = 0;
            let days = {}
            map(reverse(item?.day), (itemChild: any) => {
              const keyName = itemChild.day;
              let temp = { [keyName]: { ...itemChild, key: item?.key } }
              days = { ...days, ...temp }
              if (item?.key === 'revert') {
                totalRevenue += itemChild?.revenue
              }
              if (item?.key === 'expense') {
                totalExpense += itemChild?.expense
              }
              if (item?.key === 'profit') {
                totalProfit += itemChild?.profit
              }
            })
            if (item?.key === 'revert') {
              data.push({ key: item?.key, total: totalRevenue, day: item?.day, ...days })
            }
            if (item?.key === 'expense') {
              data.push({ key: item?.key, total: totalExpense, day: item?.day, ...days })
            }
            if (item?.key === 'profit') {
              data.push({ key: item?.key, total: totalProfit, day: item?.day, ...days })
            }
          } else {
            data.push({ key: item?.key, total: 0, day: item?.day })
          }
        })
        //@ts-ignore
        this.listRevenue = data;
        // console.log('data', data);

      }
    }
  }
  @action
  async resetStore() {
    // this.listProductType = { ...resultInit };
  }
}

