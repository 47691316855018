import { Route, Switch } from 'react-router-dom'
import NotFoundPage from '../404/NotFoundPage'
import DefaultAccountantScreen from '../default/DefaultAccountantScreen'
import { ROUTER_PATH } from './metaData'
import DepositWithdrawalManagementScreen from '../deposit-withdrawal-management'
import ProfileScreen from '../profile'
const AccountantRouters = (props: any) => {
   return (
      <Switch>
         <Route exact path={`/`} component={DefaultAccountantScreen} />

         {/* <Route exact path={`${props.match.url}${ROUTER_PATH.CreatorManagementScreen}`} component={CreatorManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.CreateCreatorScreen}`} component={CreateCreatorScreen} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.CreatorDetailScreen}`} component={CreatorDetail} />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.DesignerManagementScreen}`}
            component={DesignerManagement}
         />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.EmployeeManagementScreen}`}
            component={EmployeeManagement}
         />
         <Route exact path={`${props.match.url}${ROUTER_PATH.FundManagementScreen}`} component={FundManagementScreen} />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.ProductTypeManagementScreen}`}
            component={productTypeManagementScreen}
         />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.DesignTypeManagementScreen}`}
            component={DesignTypeManagementScreen}
         />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.NumberItemsManagementScreen}`}
            component={NumberItemsManagementScreen}
         />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.NumberItemsManagementScreen}`}
            component={NumberItemsManagementScreen}
         />
         <Route exact path={`${props.match.url}${ROUTER_PATH.JobManagementScreen}`} component={JobManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.DebtManagementScreen}`} component={DebtManagementScreen} /> */}
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.DepositWithdrawalManagementScreen}`}
            component={DepositWithdrawalManagementScreen}
         />
         {/* <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.CommonManagementScreen}`}
            component={CommonManagementScreen}
         /> */}

         <Route exact path={`${props.match.url}${ROUTER_PATH.ProfileScreen}`} component={ProfileScreen} />

         <Route path="*" component={NotFoundPage} />
      </Switch>
   )
}

export default AccountantRouters
