import { observer } from "mobx-react-lite";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { COMMON_STATUS } from "../../helpers/constants";

const CommonStatus = observer((props: any) => {
    const { type } = props;

    if (type === COMMON_STATUS.ACTIVE) {
        return <CheckCircleOutlined style={{ color: "#52c41a" }} />;
    } else if (type === COMMON_STATUS.INACTIVE) {
        return <CloseCircleOutlined style={{ color: "#fa7716" }} />;
    }

    return null;
});

export { CommonStatus };
