import { useEffect, useState } from 'react'
import { Table, Space, Button, Col, Row, Modal, Divider } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import { DATE_TIME_FORMAT_CLIENT } from '../../helpers/constants'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import Create from './Create'
import moment from 'moment'
import useWindowDimensions from '../../common/useWindowDimensions'
import Detail from './Detail'
import useModal from 'src/helpers/modal/useModal'

const DesignTypeManagementScreen = observer(() => {
   const { t } = useTranslation()
   const CommonConfigStore = useStore('CommonConfigStore')
   const [textSearch, set_textSearch] = useState('')
   const { height } = useWindowDimensions()

   const [visibleCreate, setVisibleCreate] = useState(false)
   const [sortDateDesc, setSortDateDesc] = useState(true)
   const [visibleDetail, setVisibleDetail] = useState(false)
   const [item, setItem] = useState(null)
   const data = CommonConfigStore.designTypesList
   const { confirm } = useModal()
   useEffect(() => {
      handle_getListData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [textSearch, sortDateDesc])

   const handle_getListData = async () => {
      await CommonConfigStore.getDesignTypeList()
   }
   const onChange = (currentPage: number) => {
      handle_getListData()
   }

   const handleChangeTextSearch = (value: any) => {
      set_textSearch(value)
   }

   const showCreate = () => {
      // props.history.push(ROUTER_PATH.CreateEmployeeScreen);
      setVisibleCreate(true)
   }

   const handleDelete = (value: any) => {
      confirm({
         title: t(GLOBAL_CLIENT.banmuonxoabanghi),
         icon: <ExclamationCircleOutlined />,
         okText: t(GLOBAL_CLIENT.dongy),
         cancelText: t(GLOBAL_CLIENT.huy),
         onCancel() {},
         async onOk() {
            await CommonConfigStore.deleteDesignType(value?.id)
            handle_getListData()
         }
      })
   }

   const handleCreate = async () => {
      handle_getListData()
      setVisibleCreate(false)
   }

   const handleCancel = () => {
      setVisibleCreate(false)
   }

   const clearDetailInfo = () => {
      setItem(null)
   }

   const handleEdit = () => {
      handle_getListData()
      setVisibleDetail(false)
      clearDetailInfo()
   }

   const hideDetail = () => {
      setVisibleDetail(false)
      clearDetailInfo()
   }
   const setDetailInfo = (value: any) => {
      setItem(value)
   }

   const showDetail = (value: any) => {
      setDetailInfo(JSON.parse(JSON.stringify(value)))
      // console.log('showDetail; ', JSON.parse(JSON.stringify(value)))

      setTimeout(() => {
         setVisibleDetail(true)
      }, 500)
   }

   const columns = [
      {
         title: t(GLOBAL_CLIENT.thutu),
         dataIndex: 'index',
         key: 'index',
         align: 'center' as 'center',
         width: 60
         // fixed: 'left',
         //  render: (value: string, record: any, index: number) =>
         //      (page-1) * PAGE_SIZE + index + 1,
      },
      {
         title: t(GLOBAL_CLIENT.id),
         dataIndex: 'id',
         key: 'id',
         align: 'center' as 'center',
         width: 60
         // fixed: 'left',
         //  render: (value: string, record: any, index: number) =>
         //      (page-1) * PAGE_SIZE + index + 1,
      },
      {
         title: t(GLOBAL_CLIENT.designType),
         dataIndex: 'name',
         key: 'name',
         width: 120,
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: t(GLOBAL_CLIENT.createAt),
         dataIndex: 'updatedAt',
         key: 'updatedAt',
         width: 80,
         render: (value: string) => {
            return <span className="txtMax2Line">{moment(value).format(DATE_TIME_FORMAT_CLIENT)}</span>
         }
         // sorter: {
         //    compare: (a: any, b: any) =>
         //       parseFloat(moment(a.createdAt).format('YYYYMMDDHHmmss')) -
         //       parseFloat(moment(b.createdAt).format('YYYYMMDDHHmmss')),
         //    multiple: 3
         // },
         // defaultSortOrder: 'descend' as 'descend'
      },
      {
         title: 'Action',
         key: 'action',
         dataIndex: 'updatedAt',
         width: 80,
         align: 'center' as 'center',
         fixed: 'right' as 'right',
         render: (text: string, record: any) => {
            return (
               <span className="txtMax2Line">
                  <span onClick={() => showDetail(record)} className="gx-link ">
                     {t(GLOBAL_CLIENT.edit)}
                  </span>
                  <Divider type="vertical" />
                  <span onClick={() => handleDelete(record)} className="gx-link">
                     {t(GLOBAL_CLIENT.delete)}
                  </span>
               </span>
            )
         }
      }
   ]

   // const renderPagination = () => {
   //     if (isEmpty(results)) {
   //         return null;
   //     }

   //     return (
   //         <div className="containerPagination">
   //             <Pagination
   //                 onChange={onChange}
   //                 pageSize={PAGE_SIZE}
   //                 current={page}
   //                 showSizeChanger={false}
   //                 total={totalResults}
   //             />
   //         </div>
   //     );
   // };

   function onChangeTable(pagination: any, filters: any, sorter: any, extra: any) {
      // console.log('params', pagination, filters, sorter, extra)
      if (sorter.order === 'ascend') {
         setSortDateDesc(false)
      } else {
         setSortDateDesc(true)
      }
   }

   return (
      <>
         <Row>
            <Col span={24}>
               <Row className="row-container-head">
                  <Col xs={24} md={12}>
                     {/* <Input.Search
                                onSearch={handleChangeTextSearch}
                                placeholder={t(GLOBAL_CLIENT.txtSearch)}
                                allowClear
                            /> */}
                  </Col>
                  <Col xs={24} md={12}>
                     <div className="text-right">
                        <Space>
                           {/* {renderFilter()} */}
                           <Button type="primary" className="btn-add" onClick={showCreate}>
                              <i className="icon icon-add icon-in-button" />
                              {t(GLOBAL_CLIENT.create)}
                           </Button>
                        </Space>
                     </div>
                  </Col>
               </Row>

               {/* <Space className="total-items">
                        {t(GLOBAL_CLIENT.totalItems)}: {totalResults}
                    </Space> */}

               <Table
                  // scroll={{ x: 1200 }}
                  scroll={{ y: height - 200 }}
                  columns={columns}
                  dataSource={data}
                  rowKey="id"
                  pagination={false}
                  onChange={onChangeTable}
               />

               {/* {renderPagination()} */}
            </Col>
         </Row>
         {visibleCreate && <Create visible={visibleCreate} onOk={handleCreate} onCancel={handleCancel} />}
         {visibleDetail && item && (
            <Detail visible={visibleDetail} item={item} onOk={handleEdit} onCancel={hideDetail} />
         )}
      </>
   )
})

export default DesignTypeManagementScreen
