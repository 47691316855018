
import { useEffect } from 'react'
import { Select, Modal, Button } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from '../../../helpers/functions'
import { DATE_FORMAT_CLIENT_PARAMS } from '../../../helpers/constants'
import { Area, AreaChart, ResponsiveContainer, XAxis, Tooltip, YAxis, CartesianGrid } from 'recharts'
import moment from 'moment'

const Chart = observer((props: any) => {
   const { t } = useTranslation()

   const { item, visible, startTime, endTime, onOk, onCancel } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars

   console.log('recharts', item)

   useEffect(() => {
      // getDataEmployee()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   
   //@ts-ignore
   const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
         return (
            <div className="custom-tooltip">
               <div style={{ color: 'blue' }}>{`${label} : ${formatCurrency(payload[0].value, true)} mẫu`}</div>
            </div>
         )
      }

      return null
   }
   return (
      <Modal
         width={'78%'}
         visible={visible}
         centered
         title={`Số lượng mẫu hoàn thành của ${item?.name} từ ${moment(startTime).format(
            DATE_FORMAT_CLIENT_PARAMS
         )}-${moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS)}`}
         okText={t(GLOBAL_CLIENT.edit)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         footer={[
            <Button key="back" onClick={onCancel}>
               Đóng
            </Button>
         ]}
      >
         <div style={{ marginBottom: 20 }}>Tổng cộng: {item?.total} mẫu</div>
         <ResponsiveContainer width="100%" height={250}>
            <AreaChart data={item?.data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
               {/* @ts-ignore */}
               <Tooltip content={<CustomTooltip />} />
               <XAxis dataKey="day" />
               <YAxis dataKey={'value'} />
               <defs>
                  <linearGradient id="color15" x1="0" y1="0" x2="0" y2="1">
                     <stop offset="35%" stopColor="#FFAD63" stopOpacity={1} />
                     <stop offset="95%" stopColor="#FFAD6330" stopOpacity={0.2} />
                  </linearGradient>
               </defs>
               <CartesianGrid strokeDasharray="2 2" />
               <Area
                  dataKey={'value'}
                  strokeWidth={2}
                  stackId="2"
                  stroke="#10316B"
                  fill="url(#color15)"
                  fillOpacity={1}
               />
            </AreaChart>
         </ResponsiveContainer>
      </Modal>
   )
})

export default Chart
