import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { adminMeta, supporterMeta } from "../routes/metaData";
const DefaultSupporterScreen = observer((props: any) => {
   const history = useHistory()
   useEffect(() => {
      history.push(supporterMeta[0].path);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return null;
});

export default DefaultSupporterScreen;
