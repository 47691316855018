import { EditOutlined } from '@ant-design/icons'
import { Row, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Images from 'src/common/Images'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { ROLE, STATUS_JOB } from 'src/helpers/constants'
import { useStore } from 'src/hooks'
// @ts-ignore
// import renderHTML from 'react-render-html'
const JobDetailDescription = observer(({ isEditingDescription, setIsEditingDescription }: any) => {
   const { t } = useTranslation()
   const JobDetailStore = useStore('JobDetailStore')
   const { jobDetail } = JobDetailStore
   const status = jobDetail?.status
   const description = jobDetail?.note
   const AuthStore = useStore('AuthStore')
   const role = AuthStore.data_role

   const handleKeyDown = (e: any) => {
      // if (e.key === 'Enter' && !e.shiftKey) {
      //    // Submit the form
      //    e.preventDefault()
      //    JobDetailStore.setJobDetailDescription(e.target.value)
      // } else if (e.key === 'Enter' && e.shiftKey) {
      //    // Add new line
      // }
   }

   const handleDescriptionChange = (e: any) => {
      if (isEditingDescription) {
         JobDetailStore.setJobDetailDescription(e.target.value)
      }
   }

   return (
      <>
         <Row className="gx-flex-row gx-align-items-center gx-justify-content-between gx-mb-2 gx-mx-1" wrap={false}>
            <h4 style={{ fontSize: 14, fontWeight: 600, lineHeight: '22px', color: '#272944' }}>
               {t(GLOBAL_CLIENT.detailDescription)}
            </h4>
            {role === ROLE.CREATOR &&
               status !== STATUS_JOB.DOING &&
               status !== STATUS_JOB.REVIEW &&
               status !== STATUS_JOB.REJECT &&
               status !== STATUS_JOB.FINISH &&
               !isEditingDescription && (
                  <div
                     className="gx-module-logo gx-mb-2"
                     onClick={() => {
                        setIsEditingDescription(true)
                     }}
                  >
                     <img style={{ color: '#272944', marginLeft: 4, height: 20 }} src={Images.ic_edit} alt="icon" />
                     <div
                        style={{ fontSize: 14, fontWeight: 400, lineHeight: '22px', color: '#272944', marginLeft: 8 }}
                     >
                        {t(GLOBAL_CLIENT.chinhsua)}
                     </div>
                  </div>
               )}
         </Row>
         <Row className="gx-flex-row gx-align-items-center gx-justify-content-start gx-mb-2 gx-mx-1" wrap={false}>
            {role === ROLE.CREATOR &&
            status !== STATUS_JOB.DOING &&
            status !== STATUS_JOB.REVIEW &&
            status !== STATUS_JOB.REJECT &&
            status !== STATUS_JOB.FINISH &&
            isEditingDescription ? (
               <>
                  <TextArea
                     className="gx-mb-2 gx-w-100"
                     bordered={true}
                     value={description}
                     autoFocus={isEditingDescription}
                     autoSize
                     onChange={handleDescriptionChange}
                     onKeyDown={handleKeyDown}
                     maxLength={1500}
                     onBlur={() => setIsEditingDescription(false)}
                     showCount
                  />
               </>
            ) : (
               <>
                  <TextArea
                     className="gx-mb-2 gx-w-100"
                     bordered={true}
                     value={description}
                     // autoFocus={isEditingDescription}
                     autoSize
                     // onChange={handleDescriptionChange}
                     // onKeyDown={handleKeyDown}
                     maxLength={1500}
                     // onBlur={() => setIsEditingDescription(false)}
                     // showCount
                  />
                  {/* <pre
                  style={{
                     fontFamily: 'InferFont',
                     fontSize: 14,
                     fontWeight: 400,
                     lineHeight: '22px',
                     color: '#272944',
                     overflow: 'hidden',
                     textOverflow: 'ellipsis',
                     display: '-webkit-box',
                     WebkitLineClamp: 10,
                     WebkitBoxOrient: 'vertical',
                     whiteSpace: 'pre-wrap'
                  }}
               >
                  {description}
               </pre> */}
                  {/* <CKEditor initData={description} /> */}
                  {/* {renderHTML(description)} */}
               </>
            )}
         </Row>
      </>
   )
})

export default JobDetailDescription
