import { BankOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { debounce, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading } from 'src/common'
import Widget from 'src/common/components/Widgets'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { BANKS, ERROR_CODES, ROLE } from 'src/helpers/constants'
import { showMessageSuccess } from 'src/helpers/functions'
import { rule } from 'src/helpers/validateRule'
import { useStore } from 'src/hooks'
import Select, { components } from 'react-select'
import { useHistory } from 'react-router-dom'

type AboutProps = {
   data: any
}

const Contact: React.FC<AboutProps> = ({ data }) => {
   const { avatar, name, email, phoneNumber, bankName, bankNumber, linkTelegram, accountBankName } = data
   const AuthStore = useStore('AuthStore')
   const role = AuthStore.data_role
   const { t } = useTranslation()
   const [isLoading, setIsLoading] = useState(false)
   const [fields, setFields] = useState<any>([])
   const [form] = Form.useForm()
   const history = useHistory()
   const onFinishFailed = (errorInfo: any) => {}

   const onFinish = async (values: any) => {
      try {
         setIsLoading(true)
         console.log('values', values)

         values.bankName = values?.bankName?.short_name
         Object.keys(values)?.forEach((k) => (values[k] = values[k]?.trim()))
         const { name, phoneNumber, linkTelegram, bankName, bankNumber, accountBankName } = values
         const result = await AuthStore.action_updateProfiles({
            name,
            phoneNumber,
            linkTelegram,
            bankName,
            bankNumber,
            accountBankName
         })
         if (result.code === ERROR_CODES.SUCCESS) {
            showMessageSuccess(t(GLOBAL_CLIENT.updateSuccess))
         }
      } catch (error) {
         //  console.log(error)
      } finally {
         setIsLoading(false)
      }
   }
   const handleChangeBankName = (value: any) => {
      form.setFieldsValue({ bankName: value })
   }

   const creatorRequiredRule: any = () => {
      if (isEmpty(role)) {
         AuthStore.action_getRole(history)
      }
      if (role !== ROLE.CREATOR) {
         return rule.requiredRule
      }
      return {
         required: false
      }
   }

   useEffect(() => {
      setFields([
         { name: 'email', value: email },
         { name: 'name', value: name },
         { name: 'phoneNumber', value: phoneNumber },
         { name: 'bankNumber', value: bankNumber },
         { name: 'bankName', value: BANKS.find((item) => bankName == item.shortName || bankName == item?.code) },
         { name: 'accountBankName', value: accountBankName },
         { name: 'linkTelegram', value: linkTelegram }
      ])
   }, [])

   const ValueContainer: React.FC<any> = ({ children, ...props }) => {
      return (
         components.ValueContainer && (
            <components.ValueContainer {...props}>
               {!!children && <BankOutlined style={{ color: 'rgba(0,0,0,.25)', position: 'absolute', left: '11px' }} />}
               {children}
            </components.ValueContainer>
         )
      )
   }
   return (
      <Widget title="Thông tin cá nhân" styleName="gx-card-tabs gx-card-profile">
         <Loading isLoading={isLoading} />
         <Form
            form={form}
            name="basic"
            fields={fields}
            onFinish={debounce(onFinish, 400)}
            onFinishFailed={onFinishFailed}
            className="gx-signin-form gx-form-row0 gx-pt-4"
         >
            <Form.Item name="email">
               <Input readOnly prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={254} />
            </Form.Item>
            <Form.Item name="name" rules={[rule.maxRule]}>
               <Input
                  placeholder={t(GLOBAL_CLIENT.fullname)}
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  maxLength={30}
               />
            </Form.Item>
            <Form.Item name="phoneNumber" rules={[rule.phoneRule]}>
               <Input
                  readOnly={role == ROLE.DESIGNER}
                  placeholder="Số điện thoại"
                  prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  maxLength={20}
               />
            </Form.Item>
            <Form.Item name="bankName" rules={[creatorRequiredRule()]}>
               <Select
                  isDisabled={role == ROLE.DESIGNER}
                  name="bankName"
                  options={BANKS}
                  placeholder="Ngân hàng"
                  defaultValue={BANKS.find((item) => bankName == item.shortName || bankName == item?.code)}
                  onChange={handleChangeBankName}
                  getOptionLabel={(option: any) => option?.shortName}
                  getOptionValue={(option: any) => option?.shortName}
                  className="bank-name__select"
                  components={{ ValueContainer }}
                  classNamePrefix="select"
                  menuPlacement="top"
               />
            </Form.Item>
            <Form.Item name="accountBankName" rules={[creatorRequiredRule()]}>
               <Input
                  readOnly={role == ROLE.DESIGNER}
                  placeholder="Tên tài khoản"
                  prefix={<BankOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  maxLength={30}
               />
            </Form.Item>
            <Form.Item name="bankNumber" rules={[creatorRequiredRule()]}>
               <Input
                  readOnly={role == ROLE.DESIGNER}
                  placeholder="Số tài khoản"
                  prefix={<BankOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  maxLength={30}
               />
            </Form.Item>
            <Form.Item name="linkTelegram">
               <Input
                  readOnly={role == ROLE.DESIGNER}
                  placeholder="Telegram"
                  prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  maxLength={100}
               />
            </Form.Item>
            <Form.Item>
               <Button type="primary" className="gx-mb-0" htmlType="submit">
                  {t(GLOBAL_CLIENT.txtUpdate)}
               </Button>
            </Form.Item>
         </Form>
      </Widget>
   )
}

export default Contact
