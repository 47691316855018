import { Layout, Tabs } from 'antd'

import { useTranslation } from 'react-i18next'
import 'moment/locale/vi'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../hooks'
import ContentFee from './Content'
const { Content } = Layout
const TabPane = Tabs.TabPane

const FeeConfigManagement = observer((props: any) => {
   const { t } = useTranslation()
   const CommonConfigStore = useStore('CommonConfigStore')
   // useEffect(() => {
   //    if (CommonConfigStore.activeKeyPhiSan) {
   //       CommonConfigStore.getExchangeFeeList(CommonConfigStore.activeKeyPhiSan)
   //    }

   //    // if (size(trim(textSearch)) > 0) {
   //    //    getListData(0, { ...fieldFilter, ...{ name: trim(textSearch) } })
   //    // } else {
   //    //    getListData(0, { ...fieldFilter })
   //    // }
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [CommonConfigStore.activeKeyPhiSan])
   const onChangeTab = (key: string) => {
      CommonConfigStore.setActiveKeyPhiSan(key)
   }

   return (
      <Content {...props}>
         <Tabs activeKey={CommonConfigStore.activeKeyPhiSan} onChange={onChangeTab}>
            {CommonConfigStore.designTypesList &&
               CommonConfigStore.designTypesList?.map((tag: any) => {
                  return (
                     <TabPane tab={tag?.name} key={tag?.id}>
                        <ContentFee {...props} data={tag} />
                     </TabPane>
                  )
               })}
         </Tabs>
      </Content>
   )
})

export default FeeConfigManagement
