import { Form, Input, InputNumber, Pagination, Table, Typography } from 'antd'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { PAGE_SIZE, PhiSanMinMax, GiaMinMax } from 'src/helpers/constants'
import { formatCurrency, showMessageError } from 'src/helpers/functions'
import { useStore } from 'src/hooks'

interface Item {
   createdAt?: string
   deletedAt?: string
   designType: any
   designTypeId: string
   productType: any
   productTypeId: string
   id: number
   min: number
   max: number
   percent: number
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
   editing: boolean
   dataIndex: string
   title: any
   inputType: 'number' | 'text'
   record: Item
   index: number
   children: React.ReactNode
}

const EditableCell: React.FC<EditableCellProps> = ({
   editing,
   dataIndex,
   title,
   inputType,
   record,
   index,
   children,
   ...restProps
}) => {
   const renderInputNumber = () => {
      return title === 'Giá tối thiểu' || title === 'Giá tối đa' ? (
         <InputNumber
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            // min={GiaMinMax.min}
            // max={GiaMinMax.max}
            step={1000}
         />
      ) : (
         <InputNumber
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            // min={PhiSanMinMax.min}
            // max={PhiSanMinMax.max}
            step={1000}
         />
      )
   }

   const inputNode = inputType === 'number' ? renderInputNumber() : <Input maxLength={254} />

   return (
      <td {...restProps}>
         {editing ? (
            <Form.Item
               name={dataIndex}
               style={{ margin: 0 }}
               rules={[
                  {
                     required: true,
                     // message: `Không được để trống ${title}!`
                     message: `Không được để trống`
                  }
               ]}
            >
               {inputNode}
            </Form.Item>
         ) : (
            children
         )}
      </td>
   )
}

const ContentFee: React.FC = observer((props: any) => {
   const [form] = Form.useForm()
   // const [data, setData] = useState(originData)
   const [editingKey, setEditingKey] = useState('')
   const { t } = useTranslation()
   const UserStore = useStore('UserStore')
   const CommonConfigStore = useStore('CommonConfigStore')
   const { results, totalResults, page } = UserStore.listCreator
   useEffect(() => {
      // console.log('prop: ', props?.data)
      setEditingKey('')
      CommonConfigStore.getExchangeFeeList(props?.data?.id)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props?.data?.id])

   const isEditing = (record: Item) => {
      // console.log(
      //    // 'record',
      //    // record,
      //    ' record.id.toString()',
      //    record.id.toString(),
      //    'editingKey',
      //    editingKey,
      //    'record.id.toString() === editingKey',
      //    record.id.toString() === editingKey
      // )

      return record.id.toString() === editingKey
   }

   const edit = (record: Partial<Item> & { id: React.Key }) => {
      form.setFieldsValue({ productType: '', min: '', max: '', percent: '', ...record })
      setEditingKey(record.id + '')
   }

   const cancel = () => {
      setEditingKey('')
   }

   const save = async (record: Item) => {
      try {
         const row = (await form.validateFields()) as Item

         const newData = [...CommonConfigStore.exchangeFeeList]
         const index = newData.findIndex((item) => record?.id === item.id)
         if (index > -1) {
            const item = newData[index]
            newData?.splice(index, 1, {
               ...item,
               ...row
            })
            if (row?.min >= row?.max) {
               showMessageError('Giá tối thiểu phải nhỏ hơn giá tối đa!')
            } else {
               // console.log('row save; ', row, 'record :', JSON.parse(JSON.stringify(record)))

               const paramUpdate = {
                  min: row?.min,
                  max: row?.max,
                  productTypeId: record?.productTypeId,
                  designTypeId: record?.designTypeId,
                  percent: row?.percent
               }
               CommonConfigStore.updateExchangeFee(record?.id + '', paramUpdate)
               CommonConfigStore.setExchangeFeeList(newData)
               setEditingKey('')
            }
         } else {
            newData.push(row)
            CommonConfigStore.setExchangeFeeList(newData)
            setEditingKey('')
         }
      } catch (errInfo) {
         // console.log('Validate Failed:', errInfo)
      }
   }

   const columns = [
      {
         title: t(GLOBAL_CLIENT.productType),
         dataIndex: 'productType',
         key: 'productType',
         width: '30%',
         editable: false,
         render: (value: any) => {
            return <span className="txtMax2Line">{value?.name}</span>
         }
      },
      {
         title: t(GLOBAL_CLIENT.minPrice),
         dataIndex: 'min',
         key: 'min',
         width: '20%',
         editable: true,
         render: (value: any) => {
            return <span className="txtMax2Line">{formatCurrency(value, true)}</span>
         }
      },
      {
         title: t(GLOBAL_CLIENT.maxPrice),
         dataIndex: 'max',
         key: 'max',
         width: '20%',
         editable: true,
         render: (value: any) => {
            return <span className="txtMax2Line">{formatCurrency(value, true)}</span>
         }
      },
      {
         title: t(GLOBAL_CLIENT.phiSan) + '(%)',
         dataIndex: 'percent',
         key: 'percent',
         width: '15%',
         editable: true
      },
      {
         title: 'Action',
         width: '15%',
         dataIndex: 'operation',
         render: (_: any, record: Item) => {
            const editable = isEditing(record)

            return editable ? (
               <span>
                  <Typography.Link onClick={() => save(record)} style={{ marginRight: 8 }}>
                     {t(GLOBAL_CLIENT.save)}
                  </Typography.Link>
                  <Typography.Link onClick={cancel} style={{ marginRight: 8 }}>
                     {t(GLOBAL_CLIENT.huy)}
                  </Typography.Link>
               </span>
            ) : (
               <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                  {t(GLOBAL_CLIENT.edit)}
               </Typography.Link>
            )
         }
      }
   ]

   const mergedColumns = columns?.map((col) => {
      if (!col.editable) {
         return col
      }
      return {
         ...col,
         onCell: (record: Item) => ({
            record,
            inputType: 'number',
            // inputType: col.dataIndex === 'minPrice' ? 'number' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record)
         })
      }
   })

   const onChange = (currentPage: number) => {
      // handle_getListData(currentPage)
   }

   const renderPagination = () => {
      if (isEmpty(results)) {
         return null
      }

      return (
         <div className="containerPagination">
            <Pagination
               onChange={onChange}
               pageSize={PAGE_SIZE}
               current={page}
               showSizeChanger={false}
               total={totalResults}
            />
         </div>
      )
   }

   return (
      <Form form={form} component={false}>
         <Table
            components={{
               body: {
                  cell: EditableCell
               }
            }}
            bordered
            dataSource={CommonConfigStore.exchangeFeeList}
            columns={mergedColumns}
            rowClassName="editable-row"
            rowKey="id"
            pagination={false}
         />
         {renderPagination()}
      </Form>
   )
})

export default ContentFee
