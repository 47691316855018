import React, { useMemo, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Loading } from 'src/common'
import { ERROR_CODES } from 'src/helpers/constants'
import { showMessageSuccess } from 'src/helpers/functions'
import { useStore } from 'src/hooks'
import Images from 'src/common/Images'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { Form, Input } from 'antd'
import { rule } from 'src/helpers/validateRule'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import { AuthenWrapper, SignupWrapper } from 'src/styles/login/Authen.style'
import BaseButton from 'src/common/components/button'

const ResetPasswordScreen = () => {
   const { search } = useLocation()
   const searchParams = useMemo(() => new URLSearchParams(search), [search])
   const AuthStore = useStore('AuthStore')
   const history = useHistory()
   const { t } = useTranslation()
   const [form] = Form.useForm()

   const onFinishFailed = (errorInfo: any) => {}
   const [isLoading, setIsLoading] = useState(false)
   const resetPassword = async (values: any) => {
      try {
         setIsLoading(true)
         Object.keys(values)?.forEach((k) => {
            if (typeof values[k] == 'string') return (values[k] = values[k].trim())
         })
         const { password } = values
         const result = await AuthStore.action_resetPassword({ password }, searchParams.get('token'))
         if (result.code === ERROR_CODES.SUCCESS) {
            showMessageSuccess(result?.message, () => history.push('/signin'))
         }
      } catch (e) {
         // console.log(e)
      } finally {
         setIsLoading(false)
      }
   }
   return (
      <AuthenWrapper>
         {/* <Loading isLoading={isLoading} /> */}
         <div className="login__banner">
            <img src={Images.bg_login} alt="" />
         </div>
         <SignupWrapper className="login__content">
            <div className="signup-content__body">
               <div className="signup-content__form">
                  <div className="form__title">{t(GLOBAL_CLIENT.doimatkhau)}</div>
                  <Form
                     form={form}
                     name="basic"
                     onFinish={debounce(resetPassword, 400)}
                     onFinishFailed={onFinishFailed}
                     className="gx-signin-form gx-form-row0 signup-form__content"
                  >
                     <Form.Item rules={[rule.passwordRule]} name="password">
                        <Input type="password" placeholder={t(GLOBAL_CLIENT.password)} maxLength={30} />
                     </Form.Item>
                     <Form.Item
                        rules={[
                           rule.requiredConfirmPassword,
                           ({ getFieldValue }) => ({
                              validator(_, value) {
                                 if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve()
                                 }
                                 return Promise.reject(new Error(t(GLOBAL_CLIENT.matkhaukhongtrungkhop)))
                              }
                           })
                        ]}
                        name="confirm_password"
                        dependencies={['password']}
                     >
                        <Input type="password" placeholder={t(GLOBAL_CLIENT.xacnhanmatkhau)} maxLength={30} />
                     </Form.Item>
                     <Form.Item style={{ marginBottom: 'unset', marginTop: '24px' }}>
                        <div className="signup-form__footer">
                           <div className="signin__link">
                              <span>Or</span> <Link to="/signin">Sign In</Link>
                           </div>
                           <BaseButton type="primary" className="gx-mb-0">
                              Submit
                           </BaseButton>
                        </div>
                     </Form.Item>
                  </Form>
               </div>
            </div>
         </SignupWrapper>
      </AuthenWrapper>
   )
}

export default ResetPasswordScreen
