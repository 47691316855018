import React from 'react'
import { Layout } from 'antd'
import UserInfo from '../sidebar/UserProfile'
import HorizontalNav from './HorizontalNav'
import { Link } from 'react-router-dom'
import CustomScrollbars from '../sidebar/CustomScrollbars'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../hooks'
import Images from 'src/common/Images'
import { TAB_SIZE } from 'src/helpers/constants'
import UserNotification from '../sidebar/UserNotification'

const { Header } = Layout

const Topbar = observer((props: any) => {
   const AuthStore = useStore('AuthStore')

   const handleOpenSidebar = () => {
      AuthStore.action_openSidebar()
   }

   const languageMenu = () => (
      <CustomScrollbars className="gx-popover-lang-scroll">
         {/* <ul className="gx-sub-popover">
            {languageData?.map(language =>
               <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
                  dispatch(switchLanguage(language))
               }>
                  <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
                  <span className="gx-language-text">{language.name}</span>
               </li>
            )}
         </ul> */}
      </CustomScrollbars>
   )

   return (
      <div
         className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal"
         style={{ zIndex: 900 }}
      >
         <Header className="gx-header-horizontal-main">
            <div className="gx-container m-container">
               <div className="gx-header-horizontal-main-flex" style={{ flexWrap: 'nowrap' }}>
                  <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
                     <i className="gx-icon-btn icon icon-menu" onClick={handleOpenSidebar} />
                  </div>
                  <Link to="/" className="gx-pointer gx-mr-xs-5 gx-logo">
                     <img src={Images.logo_white} style={{ width: '175px', height: 'auto' }} alt="Logo" />
                  </Link>

                  <div className="gx-header-horizontal-nav gx-header-horizontal-nav gx-d-none gx-d-lg-block gx-m-auto">
                     <HorizontalNav {...props} />
                  </div>
                  {/* <ul className="gx-header-notifications gx-ml-auto">
                     <li className="gx-notify gx-notify-search">
                        <Popover overlayClassName="gx-popover-horizantal"
                           placement="bottomRight" content={
                              <div className="gx-d-flex"><Dropdown overlay={menu}>
                                 <Button>
                                    Category <Icon type="down" />
                                 </Button>
                              </Dropdown>
                                 <SearchBox styleName="gx-popover-search-bar"
                                    placeholder="Search in app..."
                                    onChange={updateSearchChatUser}
                                    value={searchText} /></div>
                           } trigger="click">

                           <span className="gx-pointer gx-d-block"><i className="icon icon-search-new" /></span>

                        </Popover>
                     </li>

                     <li className="gx-notify">
                        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification />}
                           trigger="click">
                           <span className="gx-pointer gx-d-block"><i className="icon icon-notification" /></span>
                        </Popover>
                     </li>

                     <li className="gx-msg">
                        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                           content={<MailNotification />} trigger="click">
                           <span className="gx-pointer gx-status-pos gx-d-block">
                              <i className="icon icon-chat-new" />
                              <span className="gx-status gx-status-rtl gx-small gx-orange" />
                           </span>
                        </Popover>
                     </li>
                     <li className="gx-language">
                        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                           content={languageMenu()} trigger="click">
                           <span className="gx-pointer gx-flex-row gx-align-items-center"><i
                              className={`flag flag-24 flag-${locale.icon}`} />
                           </span>
                        </Popover>
                     </li>
                  </ul> */}
                  <ul
                     className={`gx-header-notifications ${
                        AuthStore.width_screen < TAB_SIZE ? 'gx-ml-auto' : 'gx-pl-4'
                     }`}
                  >
                     <li
                        className="gx-user-nav"
                        style={{
                           paddingTop: '8px',
                           paddingBottom: '8px',
                           marginLeft: 'unset'
                        }}
                     >
                        <UserInfo isTopbar={true} />
                     </li>
                  </ul>
                  <div
                     className={`gx-header-notifications ${AuthStore.width_screen < TAB_SIZE ? 'gx-ml-2' : 'gx-ml-0'}`}
                  >
                     <UserNotification />
                  </div>
               </div>
            </div>
         </Header>
      </div>
   )
})

export default Topbar
