import { Avatar } from 'antd'
import React, { useState } from 'react'
import PasswordModal from './PasswordModal'
import { isEmpty } from 'lodash'
import Images from 'src/common/Images'
import { CONFIG_URL, ERROR_CODES } from 'src/helpers/constants'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import Upload from 'antd/lib/upload'
import useUpload from 'src/helpers/useUpload'
import { CameraFilled, EditOutlined, LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useStore } from 'src/hooks'
import { showMessageSuccess } from 'src/helpers/functions'

type ProfileHeaderProps = {
   data: any
}

const AvatarWrapper = styled.div`
   position: relative;
   cursor: pointer;
   .avatar__edit {
      background-color: #242526;
      padding: 6px;
      border-radius: 50%;
      position: absolute;
      right: 5px;
      z-index: 1;
      top: 70px;
   }
   &.uploading {
      .avatar__img {
         opacity: 0.3;
      }
      .avatar-uploader__wrapper {
         visibility: visible;
      }
   }
   &:hover {
      .avatar__img {
         opacity: 0.3;
      }
      .avatar-uploader__wrapper {
         visibility: visible;
      }
   }
   .avatar-uploader__wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      display: flex;
      justify-content: center;
      visibility: hidden;
      .avatar-uploader__content { 
         width: 90px;
         height: 100%;
         display: flex;
         justify-content: center;
         flex-direction: column;
         font-size: 24px;
         color: white;
      }
   }
   .avatar__img {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      padding: 3px;
      border: 3px solid #d2d6de;
   }
`

const ProfileHeader: React.FC<ProfileHeaderProps> = ({ data }) => {
   const { avatar, name, email, role } = data
   const AuthStore = useStore('AuthStore')
   const [visiblePopover, setVisiblePopover] = useState(false)
   const [loading, setLoading] = useState<boolean>(false);
   const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)
   const { t } = useTranslation()
   const useFileUpload = useUpload()
   const showModal = () => {
      setIsPasswordModalOpen(true)
   }

   const handleCancel = () => {
      setIsPasswordModalOpen(false)
   }

   const handleVisibleChange = (visible: any) => {
      setVisiblePopover(visible)
   }

   const handleOpenPasswordModal = () => {
      setVisiblePopover(false)
      showModal()
   }

   const userMenuOptions = (
      <ul className="gx-user-popover" style={{ padding: '12px' }}>
         <li onClick={handleOpenPasswordModal}>Đổi mật khẩu</li>
      </ul>
   )

   const handleUploadFile = async (options: any) => {
      const { onSuccess, onError, file, onProgress } = options;
      try {
         setLoading(true)
         const result = await AuthStore.action_updateAvatar(file)
         if (result?.code === ERROR_CODES.SUCCESS) {
            showMessageSuccess(t(GLOBAL_CLIENT.updateSuccess))
         }
         return false
      } catch (err) {
         // console.log(err)
         onError({ err })
      } finally {
         setLoading(false)
      }
   };

   const uploadButton = (
      <div className='avatar-uploader__content'>
         {loading ? <LoadingOutlined /> : <EditOutlined />}
      </div>
   );

   return (
      <div className="gx-profile-banner">
         <div className="gx-profile-container">
            <div className="gx-profile-banner-top gx-pt-2">
               <div className="gx-profile-banner-top-left gx-max-width gx-no-wrap">
                  <AvatarWrapper className={`gx-profile-banner-avatar ${loading ? "uploading" : ""}`}>
                     <div className="avatar__edit">
                        <CameraFilled />
                     </div>
                     <Upload
                        name="avatar"
                        className="avatar-uploader__wrapper"
                        showUploadList={false}
                        beforeUpload={useFileUpload.beforeUpload}
                        customRequest={handleUploadFile}
                     >
                        {uploadButton}
                     </Upload>
                     <Avatar className="gx-size-90 avatar__img" alt="..." src={isEmpty(avatar) ? Images.icon_avatar : CONFIG_URL.IMAGE_URL + '/' + avatar} />
                  </AvatarWrapper>
                  <div className="gx-profile-banner-avatar-info gx-max-width">
                     <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light gx-max-width text-oneline" >{name || email}</h2>
                     <p className="gx-mb-0 gx-fs-lg" style={{ fontWeight: 'bold' }}>
                        {role}
                     </p>
                  </div>
               </div>
               <div className="gx-profile-banner-top-right">
                  <ul className="gx-follower-list">
                     <li>
                        <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">2k+</span>
                        <span className="gx-fs-sm">Followers</span>
                     </li>
                     <li>
                        <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">847</span>
                        <span className="gx-fs-sm">Following</span>
                     </li>
                     <li>
                        <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">327</span>
                        <span className="gx-fs-sm">Friends</span>
                     </li>
                  </ul>
               </div>
            </div>
            <div className="gx-profile-banner-bottom">
               {/* <div className="gx-tab-list">
                  <ul className="gx-navbar-nav">
                     <li>
                        <span className="gx-link">Timeline</span>
                     </li>
                     <li>
                        <span className="gx-link">About</span>
                     </li>
                     <li>
                        <span className="gx-link">Photos</span>
                     </li>
                     <li>
                        <span className="gx-link">
                           Friends <span className="gx-fs-xs">287</span>
                        </span>
                     </li>
                     <li>
                        <span className="gx-link">More</span>
                     </li>
                  </ul>
               </div> */}
               <PasswordModal isPasswordModalOpen={isPasswordModalOpen} handleCancel={handleCancel} />

               {/* <Popover
                  placement="bottomRight"
                  content={userMenuOptions}
                  trigger="click"
                  visible={visiblePopover}
                  onVisibleChange={handleVisibleChange}
               > */}
               <span className="gx-link gx-profile-setting" onClick={handleOpenPasswordModal}>
                  <i className="icon icon-setting gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle" />
                  <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0">{t(GLOBAL_CLIENT.doimatkhau)}</span>
               </span>
               {/* </Popover> */}
            </div>
         </div>
      </div>
   )
}

export default ProfileHeader
