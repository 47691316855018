import { Row, Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import theme from 'src/helpers/theme'
import { useStore } from 'src/hooks'
import CustomButton from 'src/common/components/button/Custom'

type TopupModalProps = {
   isTopupModalOpen: boolean
   topup?: any
   handleCancel: () => void
   onRefreshList: () => void
}

const TopupAdminModal: React.FC<TopupModalProps> = ({ isTopupModalOpen, handleCancel, topup, onRefreshList }) => {
   const FundStore = useStore('FundStore')
   const CommonConfigStore = useStore('CommonConfigStore')
   const [message, setMessage] = useState<any>('')
   const { t } = useTranslation()
   const topupInfo = JSON.parse(JSON.stringify(topup))

   // https://www.vietqr.io/danh-sach-api/link-tao-ma-nhanh/
   console.log(
      'Topup: ',
      topupInfo,
      ' topupInfo?.price:',
      topupInfo?.price,
      'topupInfo?.account?.bankName',
      topupInfo?.account?.bankName,
      'topupInfo?.account?.bankNumber',
      topupInfo?.account?.bankNumber
   )
   const description = `POD.design ${topup?.code}`
   const baseUrl: string = `https://img.vietqr.io/image/${topupInfo?.account?.bankName}-${topupInfo?.account?.bankNumber}-compact2.jpg?addInfo=${description}&accountName=${topupInfo?.account?.accountBankName}&amount=${topupInfo?.price}`
   // console.log('bankAccount; ', CommonConfigStore.bankAccount, 'baseUrl: ', baseUrl, 'topupInfo;', topupInfo)

   // const handleApplyTopup = () => {
   //    setBankQRUrl(baseUrl + `&amount=${amount}`)
   //    const params = {
   //       price: amount || '',
   //       code: bankAccount?.DESCRIPTION || '',
   //       accountId: AuthStore?.data_getUserInfo?.id || '',
   //       description: 'Creator nạp tiền',
   //       type: typeFund.RECHARGE
   //       // status:1
   //    }
   //    console.log('params ', params, 'AuthStore?.data_getUserInfo;', AuthStore?.data_getUserInfo)
   //    FundStore.createFund(params)
   //    setShowMessage(true)
   // }

   const handleReject = async () => {
      const result = await FundStore.approveFund(topupInfo?.id, { reject: true })
      if (result) {
         setMessage(t(GLOBAL_CLIENT.tuchoichuyentien))
         onRefreshList()
         setTimeout(() => {
            onClose()
         }, 2000)
      }
   }

   const handleConfirm = async () => {
      const result = await FundStore.approveFund(topupInfo?.id, { reject: false })
      if (result) {
         setMessage(t(GLOBAL_CLIENT.xacnhanchuyentien))
         onRefreshList()
         setTimeout(() => {
            onClose()
         }, 2000)
      }
   }
   const onClose = () => {
      setMessage('')
      handleCancel()
   }
   return (
      <Modal
         title={t(GLOBAL_CLIENT.chuyentientoi) + ` ${topupInfo?.account?.name || topupInfo?.account?.email}`}
         visible={isTopupModalOpen}
         onCancel={onClose}
         footer={null}
      >
         <img src={baseUrl} alt="" />
         {/* <Input.Group compact style={{ textAlign: 'center' }}> */}
         {/* <Input
               style={{ width: 'calc(100% - 200px)', textAlign: 'start' }}
               placeholder="Số tiền muốn nạp"
               onChange={handleChangeAmount}
               maxLength={254}
            /> */}
         {/* <InputNumber
               style={{ width: 'calc(100% - 200px)', textAlign: 'start' }}
               placeholder="Số tiền muốn nạp"
               formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
               parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
               min={GiaMinMax.minNap}
               max={GiaMinMax.maxNap}
               // onChange={handleChangeAmount}
               onChange={(value: number | string | undefined | null) => handleChangeAmount(value)}
            />
            <Button type="primary" onClick={handleApplyTopup}>
               {t(GLOBAL_CLIENT.apply)}
            </Button> */}
         {/* </Input.Group> */}
         <Row className="gx-flex-row gx-align-items-center gx-justify-content-center gx-mb-2 gx-mx-1" wrap={false}>
            <CustomButton type="secondary" onClick={handleReject}>
               {t(GLOBAL_CLIENT.tuchoi)}
            </CustomButton>
            <CustomButton key="submit" type="primary" onClick={handleConfirm}>
               {t(GLOBAL_CLIENT.approve)}
            </CustomButton>
         </Row>
         {message && (
            <p
               className="ant-upload-hint"
               style={{
                  paddingTop: 12,
                  fontSize: 12,
                  textAlign: 'center',
                  fontStyle: 'italic',
                  color: theme.color.dark_0
               }}
            >
               {message}
               {/* Sau khi chuyển khoản, tiền sẽ được nạp vào ví trong 5-10 phút. Nếu quá hạn mà ví chưa cập nhật vui lòng
               thông báo supporter để kiểm tra. */}
            </p>
         )}
      </Modal>
   )
}

export default TopupAdminModal
