import React, { useCallback, useEffect, useState, useRef } from 'react'
import { List, Comment, Avatar, Form, Input, Row, Col, Image, Tag } from 'antd'
import moment, { MomentInput } from 'moment'
import { CameraOutlined, CloseCircleFilled, SendOutlined } from '@ant-design/icons'
import UploadImageModal from 'src/common/upload-image/UploadImageModal'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { Request2 } from 'src/common/Request2'
import api from 'src/common/api'
import { useStore } from 'src/hooks'
import { isEmpty } from 'lodash'
import { CONFIG_URL, PAGE_SIZE, PAGE_SIZE_CHAT, ROLE, SOCKET_EVENT } from 'src/helpers/constants'
import Images from 'src/common/Images'
import { socket } from 'src/helpers/socket/socket'
import { Loading } from 'src/common'
import { observer } from 'mobx-react-lite'

type CommentItem = {
   createdAt: MomentInput
   fromData: any
   author: string
   avatar: string
   description: React.ReactNode
}

const JobDetailComment: React.FC = observer(() => {
   const { t } = useTranslation()
   // const [comments, setComments] = useState<CommentItem[]>([])
   const [value, setValue] = useState('')
   const [imageUrl, setImageUrl] = useState('')
   // const [imageUrls, setImageUrls] = useState<string[]>([])
   const [isOpenModal, setIsOpenModal] = useState(false)
   const [isLoading, setIsLoading] = useState(false)
   const JobDetailStore = useStore('JobDetailStore')
   const [addPageSize, setAddPageSize] = useState(0)
   const [commentCount, setCommentCount] = useState(0)
   const { jobDetail, imageUrls, setComments } = JobDetailStore
   const AuthStore = useStore('AuthStore')
   const avatar = AuthStore?.data_getUserInfo?.avatar
   const name = AuthStore?.data_getUserInfo?.name
   const email = AuthStore?.data_getUserInfo?.email
   const role = AuthStore?.data_getUserInfo?.role
   const bottomCommentRef = useRef<any>(null)
   console.log('fetchComments---', jobDetail?.id)
   const fetchComments = async (params?: any) => {
      if (jobDetail?.id) {
         try {
            // setIsLoading(true)
            // console.log('fetchComments', jobDetail?.id)

            const response = await Request2.getWithToken(api.groupChat.getListMessage + `/${jobDetail?.id}`, {
               ...params,
               ...{ limit: PAGE_SIZE_CHAT, offset: addPageSize }
            })
            const commentRows = response?.rows ? response?.rows.reverse() : []
            setComments(commentRows)
            setCommentCount(response?.count)
            JobDetailStore?.setImageUrls([])
            setValue('')
            // setComments(response)
         } catch (error) {
            console.error(error)
         } finally {
            // setIsLoading(false)
         }
      }
   }

   useEffect(() => {
      fetchComments()
   }, [])

   // console.log(comments)

   const handleSeeMoreComment = () => {
      setAddPageSize(addPageSize + 20)
   }

   const CommentList = useCallback(
      ({ comments }: { comments: any }) => (
         <List
            className="comment-list"
            itemLayout="horizontal"
            header={
               <div className="gx-flex-row gx-justify-content-between">
                  <div>{comments?.length} bình luận</div>
                  {comments.length < commentCount && <div onClick={handleSeeMoreComment}>Xem thêm</div>}
               </div>
            }
            dataSource={comments}
            style={{ margin: '0 4px' }}
            renderItem={(item: any) => {
               const itemData = item?.fromData
               const imageList = item?.image ? item?.image.split(', ') : []

               return (
                  <li style={{ marginTop: 8, marginBottom: 8 }}>
                     <Row className="gx-flex-row gx-justify-content-between" style={{ marginLeft: 0, width: '100%' }}>
                        <Col flex="40px">
                           <Avatar
                              src={
                                 !isEmpty(itemData?.avatar)
                                    ? CONFIG_URL.IMAGE_URL + '/' + itemData?.avatar
                                    : Images.icon_avatar
                              }
                              size={28}
                              alt="Avatar"
                           />
                        </Col>
                        <Col flex="1" style={{ padding: 8, background: '#f1f1f1', borderRadius: 8 }}>
                           <div style={{ marginBottom: 8 }}>
                              <span
                                 style={{
                                    fontSize: 14,
                                    fontWeight: 700,
                                    lineHeight: '22px',
                                    color: '#5C5C5C',
                                    marginRight: 4
                                 }}
                              >
                                 {itemData?.name ?? itemData?.email}
                              </span>
                              {(itemData?.role === ROLE.ADMIN || itemData?.role === ROLE.SUPPORTER) && (
                                 <Tag color="gold" style={{ textTransform: 'capitalize' }}>
                                    {itemData?.role}
                                 </Tag>
                              )}
                              <span
                                 style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    lineHeight: '22px',
                                    color: '#5C5C5C'
                                 }}
                              >
                                 {item?.content}
                              </span>
                           </div>
                           <div className="gx-flex-row" style={{ marginLeft: -4, marginTop: -4 }}>
                              {imageList.length > 0 &&
                                 imageList.map((image: any, index: number) => (
                                    <div style={{ padding: 4 }} key={index}>
                                       <Image
                                          src={CONFIG_URL.IMAGE_URL + '/' + image}
                                          alt="image"
                                          preview={{
                                             mask: null
                                          }}
                                          width={140}
                                          height={105}
                                          style={{ objectFit: 'cover', borderRadius: 4 }}
                                       />
                                    </div>
                                 ))}
                           </div>
                           <div
                              style={{
                                 fontSize: 12,
                                 fontWeight: 400,
                                 lineHeight: '20px',
                                 color: '#5C5C5C'
                              }}
                           >
                              {moment(item?.createdAt).fromNow()}
                           </div>
                        </Col>
                     </Row>
                  </li>
               )
            }}
         />
      ),
      []
   )

   const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
      const items = event.clipboardData?.items

      if (items && imageUrls.length < 5) {
         for (let i = 0; i < items?.length; i++) {
            const item = items[i]
            if (item?.type?.indexOf('image') !== -1) {
               const blob = item.getAsFile()
               if (blob) {
                  const reader = new FileReader()
                  reader.readAsDataURL(blob)
                  reader.onloadend = () => {
                     const dataURL = reader.result as string
                     setImageUrl(dataURL)
                     JobDetailStore?.setImageUrls([...imageUrls, ...[dataURL]])
                  }
               }
            }
         }
      }
      // console.log('paste', imageUrls)
   }

   // const handleRemoveImage = () => {
   //    setImageUrl('')
   // }

   const handleRemoveImage = (id: number) => {
      // console.log(id)
      const filterImages = imageUrls
      filterImages?.splice(id, 1)
      JobDetailStore?.setImageUrls(filterImages)
      // console.log(imageUrls)
   }

   const convertBase64ToFile = function (image: any) {
      const byteString = atob(image.split(',')[1])
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i += 1) {
         ia[i] = byteString.charCodeAt(i)
      }
      const newBlob = new Blob([ab], {
         type: 'image/jpeg'
      })
      return newBlob
   }

   const handleSubmit = async () => {
      if (!value && !imageUrls.length) return
      // setIsLoading(true)
      // initSocket()
      bottomCommentRef.current.scrollIntoView({ behavior: 'smooth' })
      await handleSendMessage()
      setValue('')
      setImageUrl('')
      JobDetailStore?.setImageUrls([])
      // fetchComments()
      // socket.on(SOCKET_EVENT.MESSAGE, (data) => {
      //    console.log('on socket message', data)
      // })
      // setIsLoading(false)
   }

   const handleChange = (e: any) => {
      setValue(e.target.value)
   }

   // const handleUploadImage = async () => {
   //    const imageFile = convertBase64ToFile(imageUrl)
   //    try {
   //       const { data } = await Request2.uploadFile(imageFile, api.upload.uploadFile)
   //       console.log(data)
   //       return data
   //    } catch (error) {
   //       console.log(error)
   //    }
   // }

   const handleUploadImages = async () => {
      const fileList = imageUrls?.map((imageUrl) => convertBase64ToFile(imageUrl))
      console.log('1api.upload.uploadFile + `/${jobDetail?.id}`',api.upload.uploadFile + `/${jobDetail?.id}`);
         
      try {
         console.log('2api.upload.uploadFile + `/${jobDetail?.id}`',api.upload.uploadFile + `/${jobDetail?.id}`);
         
         const uploadRequests = Array.from(fileList).map(async (file, index) => {
            const { data } = await Request2.uploadFile(file, api.upload.uploadFile + `/${jobDetail?.id}`)
            return data?.filename
         })
         const newImages = await Promise.all(uploadRequests)
         // console.log('newImages', newImages)
         return newImages
      } catch (error) {
         // console.log(error)
      }
   }

   const handleSendMessage = async () => {
      const image = await handleUploadImages()
      let idTo = jobDetail?.designerId + ''
      let idFrom = jobDetail?.creatorId + ''
      if (AuthStore.data_role === ROLE.DESIGNER) {
         idTo = jobDetail?.creatorId + ''
         idFrom = jobDetail?.designerId + ''
      }
      const textData = {
         content: value?.trim(),
         type: '0',
         to: idTo,
         image: image?.join(', ')
      }
      if (value?.trim() || image?.length) {
         try {
            await Request2.postWithToken(textData, api.groupChat.getListMessage + `/${jobDetail?.id}`)
            const updatedComment = [...JobDetailStore.comments]
            updatedComment.push({
               content: value?.trim(),
               type: 0,
               to: idTo,
               from: idFrom,
               image: image?.join(', '),
               fromData: {
                  avatar,
                  name,
                  email,
                  role
               },
               createdAt: Date.now()
            })
            JobDetailStore.setComments(updatedComment)

            // fetchComments(jobDetail?.id)
         } catch (error) {
            // console.log(error)
         }
      }
   }

   return (
      <>
         {/* <Loading isLoading={isLoading} /> */}
         {JobDetailStore.comments?.length > 0 && <CommentList comments={JobDetailStore.comments} />}
         <div className="gx-flex-row gx-justify-content-start" style={{ marginLeft: 0, width: '100%' }}>
            <Col flex="40px">
               <Avatar
                  src={isEmpty(avatar) ? Images.icon_avatar : CONFIG_URL.IMAGE_URL + '/' + avatar}
                  size={28}
                  alt="avatar"
                  style={{ marginRight: 8, marginBottom: 18 }}
               />
            </Col>
            <Col flex="1">
               <Form.Item>
                  <Row className="gx-flex-row gx-align-items-end gx-mx-1" wrap={false}>
                     <Col flex="1">
                        <div
                           className="gx-ml-3 gx-mr-2 gx-flex-row gx-align-items-center"
                           style={{
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: '22px',
                              color: '#5C5C5C',
                              border: 'solid 1px #dadada',
                              borderRadius: 20
                           }}
                        >
                           {/* {imageUrl && (
                              <div
                                 style={{
                                    width: '100%',
                                    maxWidth: 100,
                                    height: 'auto',
                                    padding: 10,
                                    position: 'relative'
                                 }}
                              >
                                 <Image
                                    src={imageUrl}
                                    style={{ objectFit: 'contain', borderRadius: 8 }}
                                    preview={{
                                       mask: null
                                    }}
                                 />
                                 <CloseCircleFilled
                                    style={{
                                       position: 'absolute',
                                       top: 2,
                                       right: 0,
                                       cursor: 'pointer',
                                       fontSize: 20,
                                       backgroundColor: 'white',
                                       borderRadius: '100%'
                                    }}
                                    onClick={handleRemoveImage}
                                 />
                              </div>
                           )} */}
                           {imageUrls &&
                              imageUrls?.map((imageUrl, index) => (
                                 <div
                                    style={{
                                       width: '100%',
                                       maxWidth: 100,
                                       height: 'auto',
                                       padding: 10,
                                       position: 'relative'
                                    }}
                                    key={index}
                                 >
                                    <Image
                                       src={imageUrl}
                                       alt="image"
                                       preview={{
                                          mask: null
                                       }}
                                       height={65}
                                       style={{ objectFit: 'cover', borderRadius: 8, width: '100px' }}
                                    />
                                    <CloseCircleFilled
                                       style={{
                                          position: 'absolute',
                                          top: 2,
                                          right: 0,
                                          cursor: 'pointer',
                                          fontSize: 20,
                                          backgroundColor: 'white',
                                          borderRadius: '100%'
                                       }}
                                       onClick={() => handleRemoveImage(index)}
                                    />
                                 </div>
                              ))}
                           <Input
                              style={{ padding: '9px 16px' }}
                              placeholder={t(GLOBAL_CLIENT.writeComment)}
                              value={value}
                              bordered={false}
                              onChange={handleChange}
                              // onPaste={handlePaste}
                              onPressEnter={handleSubmit}
                              maxLength={254}
                              suffix={
                                 <div className="gx-ml-2" onClick={handleSubmit}>
                                    {t(GLOBAL_CLIENT.submit)}
                                 </div>
                              }
                           />
                        </div>
                     </Col>
                     <Col flex="none">
                        <div className="gx-module-logo gx-mr-3" onClick={() => setIsOpenModal(true)}>
                           <img
                              style={{ color: '#272944', marginLeft: 4, height: 20, marginBottom: 10 }}
                              src={Images.ic_upload}
                              alt="icon"
                           />
                        </div>
                     </Col>
                  </Row>
               </Form.Item>
               <UploadImageModal
                  visible={isOpenModal}
                  handleOk={() => setIsOpenModal(true)}
                  handleCancel={() => setIsOpenModal(false)}
               />
            </Col>
            <div ref={bottomCommentRef}></div>
         </div>
      </>
   )
})

export default JobDetailComment
