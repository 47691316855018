const theme = {
   color: {
      primary: '#FFAD63',
      secondary: '#C1C1C1',
      info: '#1847F0',
      success: '#0EC758',
      white: '#ffffff',
      // red
      red_0: 'red',
      // grey
      grey_1: '#5c5c5c',
      // dark
      dark_0: '#323347',
      dark_1: '#949494',
      dark_2: '#272944'
   }
}
export default theme
