import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { ROLE } from 'src/helpers/constants' 
import { useTranslation } from 'react-i18next'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { useStore } from 'src/hooks'

type JobStateType = {
   state: number
   name: string
   color: string
}

const JobState = observer(({ state = -1 }: { state?: number }) => {
   const [isLoading, setIsLoading] = useState(false)
   const [error, setError] = useState('')
   const [data, setData] = useState<any>(null)
   const JobsStore = useStore('JobsStore')
   const { t } = useTranslation()

   const AuthStore: any = useStore('AuthStore')
   const role: any = AuthStore.data_role

   const JOB_STATES: JobStateType[] = [
      {
         state: 1,
         name: t(GLOBAL_CLIENT.danglam),
         color: '#FFAD63'
      },
      {
         state: 2,
         name: t(GLOBAL_CLIENT.review),
         color: '#1847F0'
      },
      {
         state: 3,
         name: role === ROLE.DESIGNER ? t(GLOBAL_CLIENT.cansualai) : t(GLOBAL_CLIENT.chodesignernhanlai),
         color: '#FFAD63'
      },
      {
         state: 4,
         name: t(GLOBAL_CLIENT.hoanthanh),
         color: '#0EC758'
      }
   ]

   const jobStateItem = JOB_STATES.find((x) => x.state === state)

   return (
      <>
         {jobStateItem ? (
            <div
               style={{
                  padding: '2px 8px',
                  borderRadius: 4,
                  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                  backgroundColor: jobStateItem?.color
               }}
            >
               <div className="gx-text-white gx-fs-md gx-text-center" style={{ fontSize: 12, lineHeight: '20px' }}>
                  {jobStateItem.name}
               </div>
            </div>
         ) : null}
      </>
   )
})

export default JobState
