import { Layout } from 'antd'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'
import UserRouters from '../../routes/UserRouters'
import Sidebar from '../sidebar/Sidebar'
import Topbar from '../topbar'
import { userMeta } from '../../routes/metaData'
import { AddJobDetailModal } from 'src/screens/job-detail/AddJobDetailCreator'
import { useStore } from 'src/hooks'
import StickyChatButton from 'src/common/components/sticky-chat/StickyChatButton'
import { ROLE } from 'src/helpers/constants'

const { Content } = Layout

const LayoutUser = observer((props: any) => {
   const match = useRouteMatch()
   // const history = useHistory()
   const JobsStore = useStore('JobsStore')
   const AuthStore = useStore('AuthStore')
   const { data_getUserInfo } = AuthStore
   const handleRefreshJobs = async () => {
      try {
         await JobsStore.getJobList({ ...JobsStore?.fieldFilter, offset: 0 })
      } catch (error) {
         // console.log(error)
      } finally {
      }
   }

   return (
      <Layout className="gx-app-layout layout-admin">
         {data_getUserInfo.role !== ROLE.DESIGNER && <StickyChatButton />}
         <Sidebar {...props} sideBarContent={userMeta} />
         <Layout>
            <Topbar {...props} />
            <Content>
               <UserRouters match={match} />
            </Content>
         </Layout>
         <AddJobDetailModal
            // handleOk={openAddJobModal}
            handleRefreshJobs={handleRefreshJobs}
         />
      </Layout>
   )
})

export default LayoutUser
