import { useEffect, useState } from 'react'
import { Table, Space, Col, Row, Pagination, Checkbox } from 'antd'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../hooks'
import { DATE_FORMAT_CLIENT_PARAMS, FILTER_TIME, PAGE_SIZE_CHAT, getPage } from '../../../helpers/constants'
import { GLOBAL_CLIENT } from '../../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
// import Create from './Create'
import useWindowDimensions from '../../../common/useWindowDimensions'
// import Detail from './Detail'
import moment, { Moment } from 'moment'
import 'moment/locale/vi'
import { isEmpty, map } from 'lodash'
import Chart from './Chart'
import Transaction from './Transaction'

const ContentTab = observer((props: any) => {
   const { startTime, endTime, role, typeFilter } = props
   const { t } = useTranslation()
   const DashboardStore = useStore('DashboardStore')
   const { height } = useWindowDimensions()

   const [sortDateDesc, setSortDateDesc] = useState(true)
   const [visibleDetail, setVisibleDetail] = useState(false)
   const [visibleDetailTransaction, setVisibleDetailTransaction] = useState(false)
   const [item, setItem] = useState(null)
   const [hideZero, setHideZero] = useState(true)
   const { rows, count, offset } = DashboardStore.listJobFinish

   useEffect(() => {
      handle_getListData(0, { from: startTime, to: endTime, hideZeroCheck: hideZero, role })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [startTime, endTime, role, hideZero])

   const handle_getListData = async (
      currentPage: number,
      params: { from: Moment; to: Moment; role: string; hideZeroCheck: boolean }
   ) => {
      try {
         // setIsLoading(true)
         const offset = getPage(currentPage)
         const param = {
            offset,
            ...{
               from: moment(params.from).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
               to: moment(params.to).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
               hideZero: params.hideZeroCheck ? 1 : 0,
               role
            }
         }
         // console.log('params', params)
         await DashboardStore.getJobFinish(param)
      } catch (error) {
         // console.log(error)
      } finally {
         // setIsLoading(false)
      }
   }

   const showDetail = (value: any) => {
      setItem(JSON.parse(JSON.stringify(value)))
      // console.log('showDetail; ', JSON.parse(JSON.stringify(value)))

      setTimeout(() => {
         setVisibleDetail(true)
      }, 500)
   }

   const showDetailTransaction = (value: any) => {
      if (value?.userId) {
         setItem(JSON.parse(JSON.stringify(value)))
         // console.log('showDetail; ', JSON.parse(JSON.stringify(value)))

         setTimeout(() => {
            setVisibleDetailTransaction(true)
         }, 500)
      }
   }

   const handleEdit = () => {
      setVisibleDetail(false)
      setItem(null)
   }

   const hideDetail = () => {
      setVisibleDetail(false)
      setItem(null)
   }

   const hideDetailTransaction = () => {
      setVisibleDetailTransaction(false)
      setItem(null)
   }

   const onChange = (currentPage: number) => {
      handle_getListData(currentPage - 1, { from: startTime, hideZeroCheck: hideZero, to: endTime, role })
   }

   let columns = [
      {
         title: '',
         dataIndex: 'name',
         key: 'name',
         align: 'center' as 'center',
         width: 120,
         fixed: 'left',
         render: (value: any, record: any) => {
            return (
               <span
                  onClick={() => showDetailTransaction(record)}
                  className={`txtMax2Line ${record?.userId ? 'gx-link' : ''}`}
               >
                  {value}
               </span>
            )
         }
      },
      {
         title: 'Tổng SL mẫu',
         dataIndex: 'total',
         key: 'total',
         align: 'center' as 'center',
         width: 110,
         fixed: 'left',
         render: (value: any, record: any) => {
            return (
               <span onClick={() => showDetail(record)} className={`txtMax2Line gx-link`}>
                  {value}
               </span>
            )
         }
         // fixed: 'left',
         //  render: (value: string, record: any, index: number) =>
         //      (page-1) * PAGE_SIZE + index + 1,
      }
   ]

   if (rows[0]) {
      //@ts-ignore
      map(rows[0]?.data, (customer: any, index: number) => {
         // @ts-ignore
         columns.push({
            title: moment(customer.day, 'DD/MM/YYYY').format('DD/MM'),
            dataIndex: customer?.day,
            width: 80,
            key: customer?.day,

            render: (value: any, object: any) => {
               // console.log('object', JSON.parse(JSON.stringify(object)), 'value', JSON.parse(JSON.stringify(value)))
               return <span className="txtMax2Line">{object[`${customer?.day}`].value || 0}</span>
            }
         })
      })
   }

   const renderPagination = () => {
      if (isEmpty(rows)) {
         return null
      }

      return (
         <div className="containerPagination">
            <Pagination
               onChange={onChange}
               pageSize={PAGE_SIZE_CHAT}
               current={offset / PAGE_SIZE_CHAT + 1}
               showSizeChanger={false}
               total={count}
            />
         </div>
      )
   }

   function onChangeTable(pagination: any, filters: any, sorter: any, extra: any) {
      // console.log('params', pagination, filters, sorter, extra)
      if (sorter.order === 'ascend') {
         setSortDateDesc(false)
      } else {
         setSortDateDesc(true)
      }
   }

   return (
      <>
         <Row>
            <Col span={24}>
               <Row style={{ marginBottom: 8 }}>
                  <Col xs={24} md={12}>
                     <div className="total-items">
                        {t(GLOBAL_CLIENT.totalItems)}: {count}
                     </div>
                     {/* <Input.Search onSearch={handleChangeTextSearch} placeholder={t(GLOBAL_CLIENT.search)} allowClear /> */}
                  </Col>
                  <Col xs={24} md={12}>
                     <div className="text-right">
                        <Space>
                           <Checkbox
                              style={{ paddingLeft: 4, paddingRight: 4 }}
                              defaultChecked={hideZero}
                              onChange={(e) => setHideZero(e?.target?.checked)}
                           >
                              {'Ẩn Tổng =0'}
                           </Checkbox>
                           {/* <Button className="btn-add">
                              <span style={{ fontWeight: 'bold', paddingRight: 8 }}>Tổng mẫu</span>
                              <span style={{ fontSize: 20, color: '#89AD88', fontWeight: 'bolder' }}>{`${formatCurrency(
                                 DashboardStore.totalJobFinish,
                                 true
                              )}`}</span>
                           </Button> */}
                        </Space>
                     </div>
                  </Col>
               </Row>

               <Table
                  // scroll={{ x: 1200 }}
                  scroll={{ y: height - 300, x: typeFilter === FILTER_TIME[0].type ? 1000 : 2000 }}
                  //@ts-ignore
                  columns={columns}
                  dataSource={rows}
                  rowKey="id"
                  pagination={false}
                  onChange={onChangeTable}
               />

               {renderPagination()}
            </Col>
         </Row>
         {visibleDetail && item && (
            <Chart
               visible={visibleDetail}
               item={item}
               onOk={handleEdit}
               onCancel={hideDetail}
               startTime={startTime}
               endTime={endTime}
            />
         )}
         {visibleDetailTransaction && item && (
            <Transaction
               visible={visibleDetailTransaction}
               item={item}
               onOk={handleEdit}
               onCancel={hideDetailTransaction}
               startTime={startTime}
               endTime={endTime}
               role={role}
            />
         )}
      </>
   )
})

export default ContentTab
