import React from 'react'

type CheckboxProps = {
   id: any
   type: any
   name: any
   handleOnChange: any
   isChecked: any
   value: any
}

const Checkbox: React.FC<CheckboxProps> = ({ id, type, name, handleOnChange, isChecked, value }) => {
   return <input id={id} name={name} type={type} onChange={handleOnChange} checked={isChecked} value={value} maxLength={254}/>
}

export default Checkbox
