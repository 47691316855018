import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { adminMeta } from "../routes/metaData";
const DefaultAdminScreen = observer((props: any) => {
   const history = useHistory()
   useEffect(() => {
      history.push(adminMeta[0].path);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return null;
});

export default DefaultAdminScreen;
