import styled from 'styled-components'

export const ListJobsWrapper = styled.div`
   padding-top: 16px;
   .list-jobs__header {
      margin-top: 12px;
      font-weight: bold;
   }
`
export const FilterContentWrapper = styled.div`
   position: relative;
   .job-filter__content-wrapper {
      width: 475px;
      padding: 16px;
      padding-bottom: 0;
      max-height: 480px;
      min-height: 250px;
      overflow: auto;
   }
   .job-filter__content-wrapper2 {
      width: 475px;
      padding: 16px;
      padding-bottom: 0;
      max-height: 480px;
      min-height: 400px;
      overflow: auto;
   }
   .filter-group__btn-group {
      padding: 16px;
      background-color: white;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .filter-group__reset-btn {
      }
      .filter-group__apply-btn {
         width: 113px;
         padding: unset !important;
      }
   }
   .filter-group__btn-group2 {
      padding: 16px;
      background-color: white;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      .filter-group__reset-btn {
      }
      .filter-group__apply-btn {
         width: 113px;
         padding: unset !important;
      }
   }
   .job-filter__wrapper {
      position: relative;
   }
   .filter__see-more-btn {
      font-size: 13px;
      font-style: italic;
      font-weight: 300;
      cursor: pointer;
   }
   .ant-checkbox-group {
      padding-top: 8px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
   }
   @media (max-width: 768px) {
      width: auto;
   }
`

export const ListJobFilterWrapper = styled.div`
   display: flex;
   flex-wrap: nowrap;
   width: 100%;
   justify-content: space-between;
   gap: 24px;
   p {
      margin: unset;
   }
   .filter-options__wrapper--left {
      button {
         margin-bottom: unset;
      }
      display: flex;
      flex: 1;
      max-width: 590px;
   }
   .filter-options__wrapper--right {
      display: flex;
      line-height: 22px;
      font-weight: 400;
      font-size: 14px;
      color: ${(props) => props.theme.color.grey_1};
      flex-wrap: nowrap;
      .btn-creator__wrapper {
         margin-left: 20px;
         height: 40px;
         button {
            border: none;
            margin: unset;
            border-radius: 8px;
            height: 100%;
            padding: 9px 16px;
            line-height: unset;
            background: ${(props) => props.theme.color.primary};
            color: ${(props) => props.theme.color.white};
            & > span {
               font-size: 14px;
               line-height: 22px;
            }
         }
      }
      .jobs_time-range__wrapper {
         display: flex;
         .ant-select-selector {
            background-color: transparent;
         }
         .ant-select-arrow {
            color: ${(props) => props.theme.color.grey_1};
         }
         .time-range__title {
            margin-top: auto;
            margin-bottom: auto;
            white-space: nowrap;
            @media (max-width: 768px) {
               display: none;
            }
         }
         .jobs__time-range {
            margin: unset;
            margin-top: auto;
            margin-bottom: auto;
            width: 200px;
            .ant-select-selection-item {
               font-weight: 400;
               font-size: 14px;
               color: ${(props) => props.theme.color.grey_1};
            }
         }
      }
   }
   .filter-options__popover {
      margin: unset;
      margin-left: 16px;
      &.active::before {
         content: '';
         width: 12px;
         height: 12px;
         display: block;
         background: #e40e1b;
         border-radius: 50%;
         position: absolute;
         top: 0;
         right: -6px;
         left: unset;
         opacity: 1;
      }
   }
   .ant-input-group-wrapper {
      margin-bottom: unset;
   }
   .jobs__search-input {
      width: 100%;
      border-radius: 22.5px;
      padding: 9px 18px;
      font-size: 14px;
      flex: 1;
      line-height: 22px;
      /* identical to box height, or 157% */
      border: none;
      color: #646464;
      .ant-input-prefix {
         margin-right: 12px;
      }
   }
   @media (max-width: 576px) {
      flex-direction: column;
      gap: 16px;
      .filter-options__wrapper--right {
         justify-content: space-between;
      }
   }
`
