import { Button, Comment, List, Row, Avatar } from 'antd'
import Title from 'antd/lib/typography/Title'
import React, { useState, useEffect, useCallback } from 'react'
import { Request2 } from 'src/common/Request2'
import api from 'src/common/api'
import moment from 'moment'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { useStore } from 'src/hooks'
import { isEmpty } from 'lodash'
import { CONFIG_URL, DATE_FORMAT_ACTiVITY, DATE_FORMAT_BACKEND, STATUS_ACTIVITY_ITEM } from 'src/helpers/constants'
import Images from 'src/common/Images'
import { Col } from 'antd'
import ReactMarkdown from 'react-markdown'

const JobDetailActivity = ({ showDetails, setShowDetails }: any) => {
   const { t } = useTranslation()
   const JobDetailStore = useStore('JobDetailStore')
   const { jobDetail } = JobDetailStore
   // const [showDetails, setShowDetails] = useState(false)
   // const [isLoading, setIsLoading] = useState(true)
   const [data, setData] = useState<any>([])

   const toggleDetails = () => {
      setShowDetails(!showDetails)
   }

   const fetchActivity = useCallback(async () => {
      if (jobDetail?.id)
         try {
            const response = await Request2.getWithToken(api.activityHistory.getList + `/${jobDetail?.id}`)
            //  console.log(response)
            setData(response)
         } catch (error) {
            console.error(error)
         }
   }, [jobDetail?.id])

   useEffect(() => {
      fetchActivity()
   }, [fetchActivity])

   return (
      <>
         <Row className="gx-flex-row gx-align-items-center gx-justify-content-between gx-mx-1" wrap={false}>
            <h4 style={{ fontSize: 14, fontWeight: 600, lineHeight: '22px', color: '#272944' }}>
               {t(GLOBAL_CLIENT.activity)}
            </h4>
            <div className="gx-module-logo gx-mb-2" onClick={toggleDetails}>
               <img
                  style={{ color: '#272944', height: 20 }}
                  src={showDetails ? Images.ic_caret_up : Images.ic_caret_down}
                  alt="icon"
               />
               <div style={{ fontSize: 14, fontWeight: 400, lineHeight: '22px', color: '#272944', marginLeft: 8 }}>
                  {showDetails ? t(GLOBAL_CLIENT.anchitiet) : t(GLOBAL_CLIENT.hienchitiet)}
               </div>
            </div>
         </Row>
         {showDetails && (
            <List
               className="comment-list"
               itemLayout="horizontal"
               dataSource={data}
               style={{ margin: '0 4px' }}
               renderItem={(item: any) => {
                  const itemData =
                     [STATUS_ACTIVITY_ITEM.DOING, STATUS_ACTIVITY_ITEM.REVIEW, STATUS_ACTIVITY_ITEM.UPDATE_URL].indexOf(
                        item?.status
                     ) !== -1
                        ? item?.designer
                        : item?.status === STATUS_ACTIVITY_ITEM.CANCEL
                        ? null
                        : item?.creator
                  return (
                     <li style={{ marginTop: 8, marginBottom: 8 }}>
                        <Row
                           className="gx-flex-row gx-justify-content-between"
                           style={{ marginLeft: 0, width: '100%' }}
                        >
                           <Col flex="40px">
                              <Avatar
                                 src={
                                    !isEmpty(itemData?.avatar)
                                       ? CONFIG_URL.IMAGE_URL + '/' + itemData?.avatar
                                       : Images.icon_avatar
                                 }
                                 size={28}
                                 alt="avatar"
                              />
                           </Col>
                           <Col
                              flex="1"
                              style={{
                                 padding: 8,
                                 background: '#f1f1f1',
                                 borderRadius: 8,
                                 width: '100%'
                              }}
                           >
                              <div
                                 style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    lineHeight: '22px',
                                    color: '#5C5C5C',
                                    marginBottom: 8
                                 }}
                              >
                                 <ReactMarkdown>{item?.description}</ReactMarkdown>
                              </div>
                              <div
                                 style={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    color: '#5C5C5C'
                                 }}
                              >
                                 {moment(item?.createdAt).format(DATE_FORMAT_ACTiVITY)}
                              </div>
                           </Col>
                        </Row>
                     </li>
                  )
               }}
            />
         )}
      </>
   )
}

export default JobDetailActivity
