import { io } from 'socket.io-client'
import { LOCAL_STORAGE } from 'src/helpers/constants'

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.REACT_APP_SOCKET_URL || ''
const dataAuth = localStorage.getItem(LOCAL_STORAGE.DATA_AUTH)
const tokensString = dataAuth ? JSON.parse(dataAuth) : ''
const token = tokensString?.tokens?.access?.token

export const socket = io(URL, {
   reconnectionDelayMax: 10000,
   autoConnect: false,
   query: {
      token: token
   }
})
