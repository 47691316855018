import { Badge } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

type NavItemProps = {
   data: any
   jobsCount: any
}

const NavItem: React.FC<NavItemProps> = observer(({ data, jobsCount }) => {
   const [jobCount, setJobCount] = useState<number>(0)
   useEffect(() => {
      const count = jobsCount[data?.type] || 0
      setJobCount(count)
   }, [Object.values(jobsCount).map((item: any) => item)])

   return (
      <Badge count={jobCount} offset={[(jobCount > 99 ? 18 : 14), 6]} overflowCount={99} className={`${jobCount > 99 ? 'overflow' : ''}`}>
         <span className="nav-item__label">{data?.label}</span>
      </Badge>
   )
})

export default NavItem
