import { useEffect, useState } from 'react'
import { Input, Select, Modal, Form, InputNumber } from 'antd'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { DEPOSIT_WITHDRAW_TYPE, getUniqueCode } from '../../helpers/constants'
import { debounce, get, map, size } from 'lodash'
import { ClearOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
const { Option } = Select

const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
   }
}

const CreateDepositWithdrawal = observer((props: any) => {
   const { t } = useTranslation()

   const FundStore = useStore('FundStore')
   const UserStore = useStore('UserStore')
   const { visible, onOk, onCancel } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [userId, setUserId] = useState('')
   const [form] = Form.useForm()

   useEffect(() => {
      handleGetMemberFilter()
      // handleSetDataDefault();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const handleGetMemberFilter = () => {
      if (size(UserStore.listMemberFilter.rows) === 0) {
         UserStore.getMemberFilter()
      }
   }
   const onFinish = async (values: any) => {
      // console.log('Received values of form: ', values)
      let params = {
         price: values?.price || '',
         code: values?.code || '',
         description: values?.description || '',
         type: values?.type || ''
         // status:1
      }
      if (values?.account) {
         // @ts-ignore
         params.accountId = values?.account
      }

      // console.log('params ', params)

      const result = await FundStore.createFund(params)
      if (result) {
         form.resetFields()

         onOk()
      }
   }
   const handleChangeUser = (value: string) => {
      setUserId(value)
   }
   return (
      <Modal
         width={'68%'}
         visible={visible}
         centered
         title={t(GLOBAL_CLIENT.create)}
         okText={t(GLOBAL_CLIENT.txtCreate)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         onOk={form.submit}
      >
         <Form
            {...formItemLayout}
            form={form}
            name="create"
            onFinish={debounce(onFinish, 400)}
            initialValues={{
               // residence: ['zhejiang', 'hangzhou', 'xihu'],
               prefix: '+84'
            }}
            scrollToFirstError
         >
            <Form.Item
               rules={[{ required: true, message: 'Vui lòng điền loại phiếu!' }]}
               name="code"
               initialValue={getUniqueCode()}
               label={t(GLOBAL_CLIENT.receiptType)}
            >
               <Input disabled />
            </Form.Item>
            <Form.Item
               rules={[{ required: true, message: 'Vui lòng chọn loại thu chi!' }]}
               name="type"
               initialValue={DEPOSIT_WITHDRAW_TYPE[1].id}
               label={t(GLOBAL_CLIENT.type)}
            >
               <Select placeholder="Chọn Loại thu chi" onChange={handleChangeUser}>
                  <Option value={DEPOSIT_WITHDRAW_TYPE[1].id}>{DEPOSIT_WITHDRAW_TYPE[1].title}</Option>
                  <Option value={DEPOSIT_WITHDRAW_TYPE[2].id}>{DEPOSIT_WITHDRAW_TYPE[2].title}</Option>
                  <Option value={DEPOSIT_WITHDRAW_TYPE[3].id}>{DEPOSIT_WITHDRAW_TYPE[3].title}</Option>
                  <Option value={DEPOSIT_WITHDRAW_TYPE[4].id}>{DEPOSIT_WITHDRAW_TYPE[4].title}</Option>
               </Select>
            </Form.Item>
            <Form.Item
               name="account"
               rules={
                  userId == DEPOSIT_WITHDRAW_TYPE[3].id + '' || userId == DEPOSIT_WITHDRAW_TYPE[4].id + ''
                     ? [{ required: true, message: 'Vui lòng chọn người nạp/rút tiền!' }]
                     : []
               }
               // initialValue={TYPE_FUND.THU}
               label={t(GLOBAL_CLIENT.withdrawerAndDepositer)}
            >
               <Select
                  placeholder="Chọn người nạp/rút tiền"
                  showSearch
                  clearIcon={<CloseCircleOutlined />}
                  allowClear
                  // onChange={(value: any) => setUserId(value)}
                  filterOption={(input: string, option: any) =>
                     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
               >
                  {map(get(UserStore, 'listMemberFilter.rows', []), (user: any, index: number) => {
                     return (
                      
                           <Option key={index} value={user?.id}>
                              {user?.name + ` [${user?.role}]`} 
                           </Option>
                          
                      
                     )
                  })}
                  {/* {UserStore.listMemberFilter.map((item: any) => {
                     return (
                        <Option key={item?.id} value={item?.code}>
                           {item?.name + `(${item?.role})`}
                        </Option>
                     )
                  })} */}
               </Select>
            </Form.Item>
            <Form.Item
               name="price"
               label={t(GLOBAL_CLIENT.money) + '(VND)'}
               rules={[{ required: true, message: 'Vui lòng nhập số tiền!' }]}
            >
               <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  step={1000}
                  max={500000000}
                  // formatter={value => `${numeral(value).format(NUMBER_FORMAT_SALARY_CLIENT)} đ`}
               />
            </Form.Item>
            {/* <Form.Item name="groupwithdrawerAndDepositer" label={t(GLOBAL_CLIENT.groupwithdrawerAndDepositer)}>
               <Input />
            </Form.Item> */}
            {/* <Form.Item name="status" initialValue={statusFund.NEW} label={t(GLOBAL_CLIENT.status)}>
               <Select placeholder="Chọn trạng thái">
                  {statusFundTitle.map((item: any) => {
                     return (
                        <Option key={item?.id} value={item?.id}>
                           {item?.title}
                        </Option>
                     )
                  })}
               </Select>
            </Form.Item> */}

            <Form.Item
               name="description"
               label={t(GLOBAL_CLIENT.note)}
               rules={[
                  {
                     required: false,
                     message: 'Vui lòng nhập mô tả'
                  }
               ]}
            >
               <Input maxLength={254} />
            </Form.Item>
         </Form>
      </Modal>
   )
})

export default CreateDepositWithdrawal
