import { observer } from 'mobx-react-lite'
import JobsList from 'src/common/components/jobs/jobs-list'
import { useStore } from 'src/hooks'
import { STATUS_ACTIVITY } from 'src/helpers/constants'

const JobsRecruiting = observer((props: any) => {
   const JobsStore = useStore('JobsStore')
   const { rows, count, offset } = JobsStore.jobsRecruiting

   return (
         <JobsList status={STATUS_ACTIVITY.NEW} rows={rows} count={count} offset={offset} />
   )
})

export default JobsRecruiting
