import React from 'react'
import Widget from 'src/common/components/Widgets'

type ContactProps = {
   data: any
}

const Contact: React.FC<ContactProps> = ({ data }) => {
   const { avatar, name, email, role, phoneNumber } = data
   const contactList: any = [
      {
         id: 1,
         title: 'Email',
         icon: 'email',
         desc: [
            <span className="gx-link" key={1}>
               {email}
            </span>
         ]
      },
      {
         id: 2,
         title: 'Phone',
         icon: 'phone',
         desc: [phoneNumber]
      }
   ]
   return (
      <Widget title="Liên hệ" styleName="gx-card-profile-sm">
         {contactList?.map((data: any, index: any) => (
            <div key={index} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
               <div className="gx-mr-3">
                  <i className={`icon icon-${data?.icon} gx-fs-xxl gx-text-grey`} />
               </div>
               <div className="gx-media-body gx-max-width">
                  <span className="gx-mb-0 gx-text-grey gx-fs-sm">{data?.title}</span>
                  <p className="gx-mb-0 text-oneline">{data?.desc}</p>
               </div>
            </div>
         ))}
      </Widget>
   )
}

export default Contact
