import { Col, Input, Modal, Row } from 'antd'
import React, { useState } from 'react'

import { InboxOutlined } from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger'
import { CONFIG_URL, ROLE } from 'src/helpers/constants'
import { showMessageError } from 'src/helpers/functions'
import { Request2 } from '../Request2'
import api from '../api'
import { useStore } from 'src/hooks'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import Images from '../Images'

interface ModalProps {
   visible: boolean
   handleOk: () => void
   handleCancel: () => void
}

const UploadImageModal: React.FC<ModalProps> = ({ visible, handleOk, handleCancel }) => {
   const { t } = useTranslation()
   const [value, setValue] = useState('')
   const [imageUrl, setImageUrl] = useState('')
   const [imageUrls, setImageUrls] = useState<any>([])
   const [isShowComment, setIsShowComment] = useState<boolean>(false)
   const [fileList, setFileList] = useState([])
   // const [isLoading, setIsLoading] = useState(false)
   const JobDetailStore = useStore('JobDetailStore')
   const AuthStore = useStore('AuthStore')
   const avatar = AuthStore?.data_getUserInfo?.avatar
   const name = AuthStore?.data_getUserInfo?.name
   const email = AuthStore?.data_getUserInfo?.email
   const role = AuthStore?.data_getUserInfo?.role
   const { jobDetail, uploadUrls } = JobDetailStore

   const props: any = {
      name: 'file',
      multiple: true,
      action: `${CONFIG_URL.SERVICE_URL}/upload/` + jobDetail?.id,
      fileList: fileList,
      beforeUpload: (file: any) => {
         const validFileTypes = ['image/jpeg', 'image/png', 'image/gif']
         const isValidFileType = validFileTypes.includes(file.type)
         if (!isValidFileType) {
            showMessageError('Chỉ cho phép các file có định dạng ảnh (JPEG, PNG, GIF)')
         }
         return isValidFileType
      },

      onChange(info: any) {
         const { status } = info.file
         if (info.fileList.length) setIsShowComment(true)
         else setIsShowComment(false)
         if (status !== 'uploading') {
            // console.log(info.file.response)
         }
         if (status === 'done') {
            const updatedUrls = uploadUrls
            updatedUrls.push(info.file?.response?.data?.filename)
            JobDetailStore.setUploadUrls(updatedUrls)
            // console.log(uploadUrls)
         } else if (status === 'error') {
            showMessageError(`Upload file không thành công`)
         }
         setFileList(info.fileList)
      }
   }

   const handleSubmit = async () => {
      if (!value && !uploadUrls.length) return
      // setIsLoading(true)
      // initSocket()
      await handleSendMessage()
      setValue('')
      setImageUrl('')
      setImageUrls([])
      JobDetailStore.setUploadUrls([])
      handleCancel()
      // setIsLoading(false)
   }

   const handleChange = (e: any) => {
      setValue(e.target.value)
   }

   // const handleUploadImage = async () => {
   //    const imageFile = convertBase64ToFile(imageUrl)
   //    try {
   //       const { data } = await Request2.uploadFile(imageFile, api.upload.uploadFile)
   //       console.log(data)
   //       return data
   //    } catch (error) {
   //       console.log(error)
   //    }
   // }

   const handleSendMessage = async () => {
      // console.log(imageUrls)
      let idTo = jobDetail?.designerId + ''
      let idFrom = jobDetail?.creatorId + ''
      if (AuthStore.data_role === ROLE.DESIGNER) {
         idTo = jobDetail?.creatorId + ''
         idFrom = jobDetail?.designerId + ''
      }
      const textData = {
         content: value?.trim(),
         type: '0',
         to: idTo,
         image: uploadUrls?.join(', ')
      }
      if (value?.trim() || uploadUrls?.length) {
         try {
            const response = await Request2.postWithToken(textData, api.groupChat.sendMessage + `/${jobDetail?.id}`)
            // console.log(response?.data)
            const updatedComment = [...JobDetailStore.comments]
            updatedComment.push({
               content: value?.trim(),
               type: 0,
               to: idTo,
               from: idFrom,
               image: uploadUrls?.join(', '),
               fromData: {
                  avatar,
                  name,
                  email,
                  role
               },
               createdAt: Date.now()
            })
            JobDetailStore.setComments(updatedComment)
            // fetchComments(jobDetail?.id)
         } catch (error) {
            // console.log(error)
         }
      }
   }

   const handleAfterClose = () => {
      setValue('')
      setImageUrl('')
      setImageUrls([])
      setFileList([])
      JobDetailStore.setUploadUrls([])
   }

   return (
      <>
         {/* <Loading isLoading={isLoading} /> */}
         <Modal
            title="Upload File"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            afterClose={handleAfterClose}
            footer={
               isShowComment && [
                  <Row
                     className="gx-flex-row gx-align-items-center gx-justify-content-start gx-mb-2 gx-mx-1"
                     wrap={false}
                  >
                     <Col flex="auto">
                        <div className="gx-px-2">
                           <Input
                              style={{ padding: '9px 16px' }}
                              placeholder={t(GLOBAL_CLIENT.writeComment)}
                              value={value}
                              bordered={false}
                              onChange={handleChange}
                              onPressEnter={handleSubmit}
                              suffix={
                                 <div className="gx-ml-2" onClick={handleSubmit}>
                                    Gửi
                                 </div>
                              }
                           />
                        </div>
                     </Col>
                  </Row>
               ]
            }
         >
            <Dragger {...props}>
               <p className="ant-upload-drag-icon">
                  <InboxOutlined />
               </p>
               <p className="ant-upload-text">Nhấp hoặc kéo tệp vào khu vực này để tải lên</p>
               <p className="ant-upload-hint">
                  Hỗ trợ tải lên một lần hoặc hàng loạt. Nghiêm cấm tải lên dữ liệu công ty hoặc các tệp định dạng khác
               </p>
            </Dragger>
         </Modal>
      </>
   )
}

export default UploadImageModal
