import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { useStore } from 'src/hooks'
import { observer } from 'mobx-react-lite'
import { STATUS_JOB } from 'src/helpers/constants'
import { ClockCircleOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

interface CountdownProps {
   expireAt: string
}

const Countdown: React.FC<CountdownProps> = observer(() => {
   const { t } = useTranslation()
   const JobDetailStore = useStore('JobDetailStore')
   const { jobDetail } = JobDetailStore
   const status = jobDetail?.status
   const expiresAt = jobDetail?.expiresAt
   const expireTime = moment(expiresAt)
   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

   useEffect(() => {
      const countdownInterval = setInterval(() => {
         setTimeLeft(calculateTimeLeft())
      }, 1000)

      return () => {
         setTimeLeft(-1)
         clearInterval(countdownInterval)
      }
   }, [expiresAt])

   function calculateTimeLeft() {
      const currentTime = moment()
      const diff = Math.floor(moment.duration(expireTime.diff(currentTime)).asSeconds())
      return diff > 0 ? diff : 0
   }

   const formatTime = (time: number): string => {
      const hours = Math.floor(time / 3600)
      const minutes = Math.floor((time % 3600) / 60)
      const seconds = time % 60
      return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${
         seconds < 10 ? '0' + seconds : seconds
      }`
   }

   return (
      <>
         {timeLeft >= 0 && (
            <div
               style={{
                  padding: '2px 8px',
                  borderRadius: 4,
                  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                  backgroundColor: '#EC1D2A'
               }}
               className="gx-w-100"
            >
               <div className="gx-text-white gx-fs-md gx-text-center gx-flex-row gx-align-items-center gx-justify-content-center">
                  <ClockCircleOutlined style={{ color: '#fff', marginRight: 4 }} />
                  {timeLeft === 0 ? (
                     <div style={{ fontSize: 12, lineHeight: '20px' }}>{t(GLOBAL_CLIENT.timeout)}</div>
                  ) : (
                     <div style={{ fontSize: 12, lineHeight: '20px' }}>{formatTime(timeLeft)}</div>
                  )}
               </div>
            </div>
         )}
      </>
   )
})

export default Countdown
