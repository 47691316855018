import React from 'react'
import { getBgColor } from 'src/helpers/constants'
const CustomButton = ({ onClick, type = 'primary', children }: any) => {
   
   return (
      <div
         style={{
            fontSize: '14px',
            lineHeight: '22px',
            fontWeight: 400,
            backgroundColor: getBgColor(type),
            color: '#fff',
            padding: '9px 16px',
            borderRadius: 8,
            margin: '0 8px',
            textAlign: 'center'
         }}
         onClick={onClick}
      >
         {children}
      </div>
   )
}

export default CustomButton
