import { Modal, Space, Rate, Row, Button, Divider, Skeleton, Input } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import JobDetailActivity from './components/JobDetailActivity'
import JobDetailComment from './components/JobDetailComment'
import JobDetailTagList from './components/JobDetailTagList'
import JobDetailUpload from './components/JobDetailUpload'
import JobDetailDescription from './components/JobDetailDescription'
import JobDetailLink from './components/JobDetailLink'
import { useTranslation } from 'react-i18next'
import { useStore } from 'src/hooks'
import { Request2 } from 'src/common/Request2'
import api from 'src/common/api'
import { observer } from 'mobx-react-lite'
import { Number_impressions, ROLE, STATUS_ACTIVITY, STATUS_JOB } from 'src/helpers/constants'
import JobDetailButton from './components/JobDetailButton'
import JobDetailOrderInfo from './components/JobDetailOrderInfo'
import JobDetailOrderName from './components/JobDetailOrderName'
import { showMessageError, showMessageSuccess } from 'src/helpers/functions'
import { useHistory } from 'react-router-dom'
import { Loading } from 'src/common'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import useModal from 'src/helpers/modal/useModal'

interface JobDetailProps {
   data: any
   visible: boolean
   handleOk: () => void
   handleCancel: () => void
   handleRefreshJobs?: () => void
}
interface ImageType {
   id: number
   path: string
   description: string
}

export const EditJobDetailModal: React.FC<JobDetailProps> = observer(
   ({ data, visible, handleOk, handleCancel, handleRefreshJobs }) => {
      const AuthStore = useStore('AuthStore')
      const CommonConfigStore = useStore('CommonConfigStore')
      const JobsStore = useStore('JobsStore')
      const { confirm } = useModal()
      const { t } = useTranslation()
      const [isLoading, setIsLoading] = useState<boolean>(false)
      const [isSpinning, setIsSpinning] = useState<boolean>(false)
      const [editedImageId, setEditedImageId] = useState(null)
      const [showRateModal, setShowRateModal] = useState(false)
      const [isShowRatingError, setIsShowRatingError] = useState(false)
      // const [updatedImages, setUpdatedImages] = useState<any[]>([])
      const updatedUploadImages = useRef<any>([])
      const bottomModalRef = useRef<any>(null)
      const statusRef = useRef<number>(0)
      const roleRef = useRef<string>('')
      const inputRef = useRef<any>(null)
      const JobDetailStore = useStore('JobDetailStore')
      const { jobDetail } = JobDetailStore
      const status = jobDetail?.status
      const { name, count, designTypeId, productTypeId, note, price, tag, images, targetId, targetIdSave } = jobDetail
      const role = AuthStore.data_role
      const [rating, setRating] = useState(0)
      const { designerConfigList, trustDesigner } = CommonConfigStore
      const [showDetails, setShowDetails] = useState(false)
      const [isSavingUrl, setIsSavingUrl] = useState<boolean>(true)
      const [isEditingDescription, setIsEditingDescription] = useState<boolean>(false)
      // const { confirm } = Modal
      const history = useHistory()

      const handleRatingChange = (value: number) => {
         setIsShowRatingError(false)
         setRating(value)
      }

      const handleGetDetail = async () => {
         // nếu job đó của designer khác + trạng thái job k phải mới => Ko đc hiện
         // Xoá job khỏi list công việc

         // if (data?.status !== STATUS_ACTIVITY.NEW) {
         //    if (
         //       data?.designer?.id &&
         //       role === ROLE.DESIGNER &&
         //       AuthStore?.data_getUserInfo?.id !== data?.designer?.id
         //    ) {
         //       // Xoá job này khỏi danh sách job
         //       JobsStore.action_removeJob(data?.id)
         //       handleCancel()
         //    }
         // }
         //Nếu job đó mới => gọi API check lại xem có designer chưa, có rồi thì xoá job khỏi list
         try {
            setIsLoading(true)
            const response = await JobDetailStore.getJobDetail(data?.id)
            // nếu mình là designer
            // Nếu job này đã tồn tại designer & designer không phải mình
            if (
               response &&
               response?.designer?.id &&
               role === ROLE.DESIGNER &&
               AuthStore?.data_getUserInfo?.id !== response?.designer?.id
            ) {
               // Xoá job này khỏi danh sách job & đóng popup
               JobsStore.action_removeJob(data?.id)
               handleCancel()
            }
            statusRef.current = response?.status
         } catch (error) {
            // console.log(error)
         } finally {
            setIsLoading(false)
         }
      }

      console.log('====================================')
      console.log('jobDetailjobDetail', jobDetail)
      console.log('====================================')

      useEffect(() => {
         if (visible) {
            // window.addEventListener('keydown', handleKeyDown)
            window.addEventListener('paste', handlePasteOption)
            setRating(0)
            setIsEditingDescription(false)
            setShowDetails(false)
            setEditedImageId(null)
            getConfigDesigner()
            handleGetDetail()
            getTrustDesigner()
            // console.log('jobDetail.images', jobDetail.images)
            // setUpdatedImages(jobDetail?.images)
            updatedUploadImages.current = [...jobDetail?.images]
            statusRef.current = jobDetail?.status
            roleRef.current = AuthStore.data_role
            // console.log('updated', updatedUploadImages.current)
         }
         return () => {
            setRating(0)
            // setUpdatedImages([])
            updatedUploadImages.current = []
            JobDetailStore.resetJobDetail()
            JobDetailStore?.setImageUrls([])
            window.removeEventListener('paste', handlePasteOption)
            // window.removeEventListener('keydown', handleKeyDown)
            // setIsEditingDescription(false)
            // setShowDetails(false)
            // setIsEditingDescription(false)
            // setShowDetails(false)
            // setEditedImageId(null)
            // getConfigDesigner()
            // handleGetDetail()
         }
      }, [visible])

      useEffect(() => {
         CommonConfigStore.filterTrustDesigner(targetId || targetIdSave)
      }, [targetIdSave, targetId, trustDesigner])

      const getTrustDesigner = async () => {
         await CommonConfigStore.getTrustDesigner()
      }

      const handleJobReceive = async () => {
         try {
            // setIsLoading(true)
            const response = await Request2.postWithToken({}, api.job.receive + `/${data?.id}`)
            if (response) {
               JobDetailStore.setJobDetail(response?.data)
               handleCancel()
            } else {
               handleCancel()
            }
            handleRefreshJobs && handleRefreshJobs()
            //  console.log(response)
         } catch (error) {
            // console.log(error)
         } finally {
            // setIsLoading(false)
         }
      }

      const handleJobReview = async () => {
         if (jobDetail?.url && isSavingUrl) {
            try {
               // setIsLoading(true)
               const response = await Request2.postWithToken({}, api.job.review + `/${data?.id}`)
               JobDetailStore.setJobDetail(response?.data)
               handleRefreshJobs && handleRefreshJobs()
               // showMessageSuccess(t(GLOBAL_CLIENT.creatorsereview))// a vinh bao comment 12/12/2023
               //  console.log(response)
            } catch (error) {
               // console.log(error)
            } finally {
               // setIsLoading(false)
            }
         } else if (!jobDetail?.url) {
            showMessageError(t(GLOBAL_CLIENT.vuilongnhapurl))
         } else if (!isSavingUrl) {
            showMessageError(t(GLOBAL_CLIENT.vuilongluuurl))
         }
      }

      const handleJobReReceive = async () => {
         try {
            // setIsLoading(true)
            const response = await Request2.postWithToken({}, api.job.reReceive + `/${data?.id}`)
            JobDetailStore.setJobDetail(response?.data)
            handleRefreshJobs && handleRefreshJobs()
            //  console.log(response)
         } catch (error) {
            // console.log(error)
         } finally {
            // setIsLoading(false)
         }
      }

      const handleJobConfirm = async (status: boolean) => {
         try {
            // setIsLoading(true)
            const response = await Request2.postWithToken({ reject: status }, api.job.confirm + `/${data?.id}`)
            JobDetailStore.setJobDetail(response?.data)
            if (status === false) handleJobRate()
            handleRefreshJobs && handleRefreshJobs()
            //  console.log(response)
         } catch (error) {
            // console.log(error)
         } finally {
            // setIsLoading(false)
         }
      }

      const handleJobRate = () => {
         // Tạm thời comment show rate
         // setShowRateModal(true)
      }

      const hideJobRate = () => {
         setShowRateModal(false)
      }

      const handleJobUpdate = async () => {
         setIsEditingDescription(false)
         const data = {
            name: name?.trim(),
            price: price,
            images: images,
            note: note?.trim(),
            tag: tag?.trim(),
            count: count,
            productTypeId: productTypeId,
            designTypeId: designTypeId
         }
         try {
            if (name?.trim() && count && price && images?.length && jobDetail?.id && editedImageId === null) {
               // setIsLoading(true)
               const response = await JobDetailStore.updateJob(jobDetail?.id, data)
               if (response?.code === 200) {
                  handleRefreshJobs && handleRefreshJobs()
                  // showMessageSuccess('Bạn đã sửa job thành công')
                  // handleCancel()
               }
            } else {
               if (!name?.trim()) showMessageError(t(GLOBAL_CLIENT.vuilongnhapten))
               else if (!count) showMessageError(t(GLOBAL_CLIENT.vuilongnhapsoluong))
               else if (!price) showMessageError(t(GLOBAL_CLIENT.vuilongnhapgia))
               else if (!images?.length) showMessageError(t(GLOBAL_CLIENT.vuilongchonanh))
               else if (editedImageId !== null) showMessageError(t(GLOBAL_CLIENT.vuilongluumotaanh))
            }
         } catch (error) {
            // console.log(error)
         } finally {
            // setIsLoading(false)
         }
      }

      const handleJobCancel = async () => {
         try {
            // setIsLoading(true)
            await JobDetailStore.cancelJob(jobDetail?.id)
            handleRefreshJobs && handleRefreshJobs()
            handleCancel()
            showMessageSuccess(t(GLOBAL_CLIENT.huyjobthanhcong))
            AuthStore.action_getRole(history)
         } catch (error) {
            // console.log(error)
         } finally {
            // setIsLoading(false)
         }
      }

      const handleJobDelete = async () => {
         try {
            // setIsLoading(true)
            await JobDetailStore.deleteJob(jobDetail?.id)
            handleRefreshJobs && handleRefreshJobs()
            handleCancel()
            showMessageSuccess(t(GLOBAL_CLIENT.xoajobthanhcong))
            AuthStore.action_getRole(history)
         } catch (error) {
            // console.log(error)
         } finally {
            // setIsLoading(false)
         }
      }

      const showCancelConfirm = async () => {
         // call API count cancel
         const result = await JobDetailStore.countCancel()
         console.log('resultxxx', result)
         if (result) {
            confirm({
               title: t(GLOBAL_CLIENT.huyjob),
               icon: null,
               content: t(GLOBAL_CLIENT.alerthuyjob2).replace('{0}', result?.cancel || 0),
               onOk() {
                  handleJobCancel()
               },
               onCancel() {
                  // console.log('Cancel')
               },
               okText: t(GLOBAL_CLIENT.yes),
               cancelText: t(GLOBAL_CLIENT.no)
            })
         } else {
            showMessageError(t(GLOBAL_CLIENT.coloxayra))
         }
      }

      const showReceiveConfirm = async () => {
         await AuthStore.action_getProfile()
         const result: any = await JobsStore.isAbleToReceiveJobs()

         if (result?.isReceive === true) {
            if (AuthStore.data_getUserInfo?.countJob < Number_impressions) {
               confirm({
                  title: 'Xác nhận nhận việc',
                  icon: null,
                  content: t(GLOBAL_CLIENT.alertnhanviec).replace(
                     '{0}',
                     String('<b>' + designerConfigList?.timeJob * count + ' phút</b>')
                  ),
                  onOk() {
                     handleJobReceive()
                  },
                  onCancel() {
                     // console.log('Cancel')
                  },
                  okText: t(GLOBAL_CLIENT.nhanviec),
                  cancelText: t(GLOBAL_CLIENT.huy)
               })
            } else {
               handleJobReceive()
            }
         }
      }

      const getConfigDesigner = async () => {
         await CommonConfigStore.getConfigDesigner()
      }

      const handleRate = async () => {
         if (rating > 0) {
            const result = await JobDetailStore.rateJob(jobDetail?.id, rating)
            // console.log('result;', result)
            if (result) {
               setTimeout(() => {
                  hideJobRate()
                  handleCancel()
               }, 500)
            }
         } else {
            setIsShowRatingError(true)
         }
      }

      const handlePasteOption = (event: any) => {
         // console.log('handlePasteOption', statusRef.current, roleRef.current)
         if (statusRef.current === STATUS_JOB.NEW && roleRef.current === ROLE.CREATOR) {
            handlePasteUpload(event)
         } else if (
            statusRef.current === STATUS_JOB.DOING ||
            statusRef.current === STATUS_JOB.REVIEW ||
            statusRef.current === STATUS_JOB.REJECT ||
            statusRef.current === STATUS_JOB.FINISH
         ) {
            // scroll
            bottomModalRef.current.scrollIntoView({ behavior: 'smooth' })
            handlePasteComment(event)
         }
      }

      const handlePasteComment = async (event: any) => {
         const items = event.clipboardData?.items
         // console.log('handlePasteComment', items)

         if (items) {
            for (let i = 0; i < items?.length; i++) {
               const item = items[i]
               // console.log('handlePasteComment')
               if (item?.type?.indexOf('image') !== -1) {
                  const blob = item.getAsFile()
                  if (blob) {
                     const reader = new FileReader()
                     reader.readAsDataURL(blob)
                     reader.onloadend = () => {
                        const dataURL = reader.result as string
                        // setImageUrl(dataURL)
                        JobDetailStore?.setImageUrls([...JobDetailStore.imageUrls, ...[dataURL]])
                     }
                  }
               }
            }
         }
      }

      const handlePasteUpload = async (event: any) => {
         const items = event.clipboardData?.items

         // console.log('handlePasteUpload', items)
         try {
            if (items) {
               setIsSpinning(true)
               for (let i = 0; i < items?.length; i++) {
                  const item = items[i]
                  if (item?.type?.indexOf('image') !== -1) {
                     const blob = item.getAsFile()
                     if (blob) {
                        const { data } = await Request2.uploadFile(blob, api.upload.uploadFile)
                        const newImage = {
                           path: data.filename,
                           description: `Ảnh ${updatedUploadImages.current?.length + 1}`
                        }
                        updatedUploadImages.current = [...updatedUploadImages.current, newImage]
                        // console.log('newImage', newImage)
                        // console.log('updatedUploadImages.current', updatedUploadImages.current)
                        JobDetailStore.setJobDetailImages(updatedUploadImages.current)
                        break
                     }
                  }
               }
            }
         } catch (error) {
            showMessageError(error)
         } finally {
            setIsSpinning(false)
         }
      }

      const handleClearImage = () => {
         // console.log('up11', jobDetail?.images)
         updatedUploadImages.current = [...jobDetail?.images]
         // console.log('up', updatedUploadImages.current)
      }
      console.log('vo dayt')

      return (
         <Modal
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            closable={false}
            footer={null}
            maskClosable={true}
            width={'90%'}
            style={{ maxWidth: '800px' }}
            zIndex={999}
         >
            <Loading isLoading={isSpinning} />
            <Skeleton loading={isLoading} active>
               <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                  <JobDetailOrderName />
                  <Divider />
                  <JobDetailOrderInfo status={status} />
                  <JobDetailUpload
                     clearImage={handleClearImage}
                     editedImageId={editedImageId}
                     setEditedImageId={setEditedImageId}
                  />
                  <JobDetailDescription isEditingDescription={true} setIsEditingDescription={setIsEditingDescription} />
                  {role === ROLE.CREATOR && <JobDetailTagList />}
                  {status !== STATUS_JOB.NEW && !(role === ROLE.CREATOR && status === STATUS_JOB.DOING) && (
                     <JobDetailLink isSaving={isSavingUrl} handleSave={(status: boolean) => setIsSavingUrl(status)} />
                  )}
                  {jobDetail && (
                     <JobDetailButton
                        handleJobConfirm={handleJobConfirm}
                        handleJobReceive={showReceiveConfirm}
                        handleJobReReceive={handleJobReReceive}
                        handleJobReview={handleJobReview}
                        handleJobRate={handleJobRate}
                        handleJobUpdate={handleJobUpdate}
                        handleJobDelete={handleJobDelete}
                        handleJobCancel={showCancelConfirm}
                     />
                  )}
                  {/* {status !== STATUS_JOB.NEW && ( */}
                  <>
                     <JobDetailActivity showDetails={showDetails} setShowDetails={setShowDetails} />
                     <JobDetailComment />
                  </>
                  {/* )} */}
               </Space>
            </Skeleton>
            <Modal
               visible={showRateModal}
               onOk={() => {}}
               onCancel={() => {
                  setIsShowRatingError(false)
                  hideJobRate()
               }}
               footer={null}
               maskClosable={false}
            >
               <Space
                  direction="vertical"
                  size="large"
                  style={{
                     display: 'flex',
                     flexDirection: 'column',
                     justifyContent: 'center',
                     alignItems: 'center'
                  }}
               >
                  <div style={{ fontSize: 16, fontWeight: 500 }}>
                     {t(GLOBAL_CLIENT.danhgiadesigner)}:{' '}
                     {jobDetail?.designer?.name ? jobDetail?.designer?.name : jobDetail?.designer?.email}
                  </div>
                  <Rate value={rating} onChange={handleRatingChange} style={{ fontSize: 36 }} />
                  {isShowRatingError && <p>{t(GLOBAL_CLIENT.vuilongdanhgia)}</p>}
                  <Row
                     className="gx-flex-row gx-align-items-center gx-justify-content-center gx-mb-2 gx-mx-1"
                     wrap={false}
                  >
                     <Button type="primary" onClick={handleRate}>
                        {t(GLOBAL_CLIENT.guidanhgia)}
                     </Button>
                  </Row>
               </Space>
            </Modal>
            <div ref={bottomModalRef}></div>
         </Modal>
      )
   }
)
