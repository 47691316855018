import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import CustomScrollbars from './CustomScrollbars'
import SidebarLogo from './SidebarLogo'
import UserProfile from './UserProfile'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../hooks'
import theme from 'src/helpers/theme'
import SidebarItem from './SidebarItem'
import styled from 'styled-components'
import { ROLE } from 'src/helpers/constants'
import { ROUTER_PATH } from 'src/screens/routes/metaData'

const SidebarItemWrapper = styled(Link)`
   span.ant-badge.overflow {
      .ant-scroll-number.ant-badge-count.ant-badge-multiple-words {
         padding: 0 3px;
         width: unset;
         height: unset;
      }
   }
   .ant-badge-count {
      box-shadow: unset;
      width: 20px;
      min-width: 20px;
      height: 20px;
      padding: unset;
      background-color: ${(props) => props.theme.color.red_0};
      .ant-scroll-number-only-unit {
         font-weight: 500;
         font-size: 9px;
         line-height: 20px;
      }
   }
`

const SidebarContent = observer((props: any) => {
   const { location } = props
   const { t } = useTranslation()
   const AuthStore = useStore('AuthStore')
   const role = AuthStore.data_role
   const pathUrl = location.pathname
   const selectedKeys = pathUrl
   const handleCloseSideBar = () => {
      AuthStore.action_closeSidebar()
   }

   const renderDashboard = () => {
      // console.log(props)
      return (
         <>
            <Menu.SubMenu key="dashboard" title={t(GLOBAL_CLIENT.dashboard)}>
               <Menu.Item key={ROUTER_PATH.CompletedQuantityScreen}>
                  <SidebarItemWrapper to={'/' + ROUTER_PATH.CompletedQuantityScreen} onClick={handleCloseSideBar}>
                     <span>{t(GLOBAL_CLIENT.completedQuantity)}</span>
                  </SidebarItemWrapper>
               </Menu.Item>
               {/* <Menu.Item key={ROUTER_PATH.NumberEditsScreen}>
                  <Link to={'/' + ROUTER_PATH.NumberEditsScreen} onClick={handleCloseSideBar}>
                     <span>{t(GLOBAL_CLIENT.numberEdits)}</span>
                  </Link>
               </Menu.Item> */}
               <Menu.Item key={ROUTER_PATH.BusinessResultsScreen}>
                  <SidebarItemWrapper to={'/' + ROUTER_PATH.BusinessResultsScreen} onClick={handleCloseSideBar}>
                     <span>{t(GLOBAL_CLIENT.businessResults)}</span>
                  </SidebarItemWrapper>
               </Menu.Item>
            </Menu.SubMenu>
         </>
      )
   }

   const renderMenus = () => {
      // console.log(props)
      return (
         <>
            {props?.sideBarContent?.map((item: any) => {
               return (
                  //  item.path === '/' + ROUTER_PATH.DashboardScreen && role === ROLE.ADMIN ? (
                  //    renderDashboard()
                  // ) : (
                  <Menu.Item key={item?.path}>
                     <SidebarItemWrapper to={item?.path} onClick={handleCloseSideBar}>
                        <SidebarItem data={item} />
                     </SidebarItemWrapper>
                  </Menu.Item>
               )
               // )
            })}
         </>
      )
   }
   return (
      <>
         <SidebarLogo sidebarCollapsed={props.sidebarCollapsed} setSidebarCollapsed={props.setSidebarCollapsed} />
         <div className="gx-sidebar-content">
            <div className={`gx-sidebar-notifications`}>
               <UserProfile {...props} />
            </div>
            <CustomScrollbars className="gx-layout-sider-scrollbar">
               <Menu
                  selectedKeys={[selectedKeys]}
                  theme={'dark'}
                  mode="inline"
                  className="menu-container"
                  style={{ background: theme?.color.dark_0 }}
               >
                  {/* {role === ROLE.ADMIN && renderDashboard()} */}
                  {renderMenus()}
               </Menu>
            </CustomScrollbars>
         </div>
      </>
   )
})

export default SidebarContent
