import { observable, action } from "mobx";
import { PAGE_SIZE, ROLE } from "../helpers/constants";
import { Request2 } from "src/common/Request2";
import { isEmpty } from "lodash";
import { responseDefault } from "./store.constants";
import api from "src/common/api";
// import { WsCode } from "../helpers/Wscode";

export const resultInit = {
  results: [],
  limit: 50,
  page: 1,
  totalPages: 1,
  totalResults: 0,
};
export class DebtsStore {

  @observable listDebtPartnerData: any = resultInit;

  @observable totalDebtsDesigner: number = 0;
  @observable totalDebtsCreator: number = 0;
  @observable totalNotTrackingVND: number = 0;
  @observable receivable: number = 0;
  @observable totalPartnerDebts: number = 0;

  @observable totalDebtsCustomer: number = 0;
  @observable totalPriceOrderDetailCustomer: number = 0;
  @observable totalDebtsCustomerWithDate: number = 0;

  @observable debtCreatorList: any = { ...responseDefault }
  @observable debtDesignerList: any = { ...responseDefault }
  @observable activeKeyMain: string = "CreatorDebt";
  @observable customerSearch: any = { value: "", id: "" };
  @observable partnerSearch: any = { value: "", id: "" };

  @action
  setActiveKeyMain(value: string) {
    this.activeKeyMain = value;
  }

  @action
  async getDebtCreatorList(params: any = {},) {
    const result = await Request2.getWithToken(api.debt.getList, { ...params, ...{ role: 'creator', limit: PAGE_SIZE } })
    if (!isEmpty(result)) {
      this.debtCreatorList = result
    }
  }

  @action
  async getDebtDesignList(params: any = {},) {
    const result = await Request2.getWithToken(api.debt.getList, { ...params, ...{ role: 'designer', limit: PAGE_SIZE } })
    if (!isEmpty(result)) {
      this.debtDesignerList = result
    }
  }
  @action
  async getTotalPriceDebt(role: string) {
    const result = await Request2.getWithToken(api.debt.total, { role })
    // console.log('gettotalPriceDebt :', result);

    // if ((result)) {
    if (role === ROLE.CREATOR) {
      this.totalDebtsCreator = result || 0
    } else {
      this.totalDebtsDesigner = result || 0
    }
    // }
  }
  // @action
  // async resetStore() {
  //   this.listDebtData = { ...resultInit };
  //   this.totalDebts = 0;
  //   this.totalDebtsCustomer = 0;
  //   this.totalDebtsCustomerWithDate = 0;
  //   this.totalPriceOrderDetailCustomer = 0;
  // }

  // @action
  // async actionGetCustomerDebt(
  //   type: string = "customer",
  //   customerId: string,
  //   page: number,
  //   time: string,
  //   pageSize?: number
  // ) {
  //   let searchCustomerPartner = "";
  //   if (type === "customer") {
  //     searchCustomerPartner = customerId ? `&customers[]=${customerId}` : "";
  //   } else {
  //     searchCustomerPartner = customerId ? `&partners[]=${customerId}` : "";
  //   }
  //   const toDate = time ? `&to=${time}` : "";
  //   const DOMAIN = `${WsCode.debts.getTotalPrice}?limit=${
  //     pageSize || PAGE_SIZE
  //   }&page=${page}${searchCustomerPartner}${toDate}&type=${type}`;
  //   const result = await Request.getWithToken(DOMAIN);
  //   console.log("DOMAIN", DOMAIN);
  //   console.log("vao dayyyyyyyyyy result y", JSON.stringify(result));
  //   if (result?.page) {
  //     result.page = page;
  //   }
  //   if (type === "customer") {
  //     console.log("vao dayyyyyyyyyyy");

  //     this.listDebtData = result || resultInit;
  //   } else {
  //     this.listDebtPartnerData = result || resultInit;
  //   }

  //   console.log("result; ", result);
  // }


  @action
  resetTotalDebts() {
    // this.totalDebtsCustomer = 0;
  }
}
