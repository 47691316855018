import { Input, Form } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ERROR_CODES, ROLE } from '../../helpers/constants'
import { Loading } from '../../common'
import Images from '../../common/Images'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useStore } from '../../hooks'
import { rule } from '../../helpers/validateRule'
import { Link } from 'react-router-dom'
import { showMessageSuccess } from 'src/helpers/functions'
import { debounce } from 'lodash'
import { AuthenWrapper, SignupWrapper } from 'src/styles/login/Authen.style'
import BaseButton from 'src/common/components/button'
import StickyChatButton from 'src/common/components/sticky-chat/StickyChatButton'

const SignupCreatorScreen = observer((props: any) => {
   const AuthStore = useStore('AuthStore')
   const { t } = useTranslation()
   const [isLoading, setIsLoading] = useState(false)
   const { history } = props
   const [form] = Form.useForm()

   const onFinishFailed = (errorInfo: any) => {}

   const onFinish = async (values: any) => {
      try {
         setIsLoading(true)
         Object.keys(values)?.forEach((k) => {
            if (typeof values[k] == 'string') return (values[k] = values[k]?.trim())
         })
         const { email, name, password, phoneNumber, linkTelegram } = values
         const result = await AuthStore.action_register({ email, name, password, phoneNumber, linkTelegram }, { role: ROLE.CREATOR })
         if (result?.code === ERROR_CODES.SUCCESS) {
            showMessageSuccess(t(GLOBAL_CLIENT.registerSuccess), () => history.push('/signin'))
         }
      } catch (error) {
         //  console.log(error)
      } finally {
         setIsLoading(false)
      }
   }

   return (
      <AuthenWrapper>
         {/* <Loading isLoading={isLoading} /> */}
         <div className="login__banner">
            <img src={Images.bg_login} alt="" />
         </div>
         <SignupWrapper className="login__content">
         <StickyChatButton />
            <div className="signup-content__body">
               <div className="signup-content__form">
                  <div className="form__title">{t(GLOBAL_CLIENT.signupCreator)}</div>
                  {/* <div className="form__desc"> */}
                  {/* Or sign up as <Link to="/signup-designer">Designer</Link> */}
                  {/* </div> */}
                  <Form
                     form={form}
                     name="basic"
                     onFinish={debounce(onFinish, 400)}
                     onFinishFailed={onFinishFailed}
                     className="gx-signin-form gx-form-row0 signup-form__content"
                  >
                     <div className="form-item__wrapper">
                        <p className="form-item__label">{t(GLOBAL_CLIENT.fullname) + '*'}</p>
                        <Form.Item rules={[rule.requiredName]} name="name">
                           <Input placeholder="Nhập" maxLength={30} />
                        </Form.Item>
                     </div>
                     <div className="form-item__wrapper">
                        <p className="form-item__label">{t(GLOBAL_CLIENT.txtEmail) + '*'}</p>
                        <Form.Item name="email" rules={[rule.emailRule, rule.requiredEmail]}>
                           <Input placeholder="Nhập" maxLength={254} />
                        </Form.Item>
                     </div>
                     <div className="form-item__wrapper">
                        <p className="form-item__label">{t(GLOBAL_CLIENT.txtPhone) + '*'}</p>
                        <Form.Item name="phoneNumber" rules={[rule.phoneRule, rule.requiredPhone]}>
                           <Input placeholder="Nhập" maxLength={20} />
                        </Form.Item>
                     </div>
                     <div className="form-item__wrapper">
                        <p className="form-item__label">{t(GLOBAL_CLIENT.password) + '*'}</p>
                        <Form.Item rules={[rule.passwordRule]} name="password">
                           <Input type="password" placeholder="Nhập" maxLength={30} />
                        </Form.Item>
                     </div>
                     <div className="form-item__wrapper">
                        <p className="form-item__label">{'Telegram/Facebook' + '*'}</p>
                        <Form.Item rules={[rule.requiredTeleFb]} name="linkTelegram">
                           <Input placeholder="Nhập" maxLength={254} />
                        </Form.Item>
                     </div>
                     <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[rule.termRule]}
                        className="signup-form__term"
                     >
                        <Checkbox>
                           {t(GLOBAL_CLIENT.bySigningUpIAccept)}
                           <span>&nbsp;{t(GLOBAL_CLIENT.termCondition)}</span>
                        </Checkbox>
                     </Form.Item>
                     <Form.Item style={{ marginBottom: 'unset', marginTop: 'unset' }}>
                        <div className="signup-form__footer">
                           <div className="signin__link">
                              <span>{t(GLOBAL_CLIENT.or)}</span> <Link to="/signin">{t(GLOBAL_CLIENT.login)}</Link>
                           </div>
                           <BaseButton type="primary" className="gx-mb-0">
                              {t(GLOBAL_CLIENT.register)}
                           </BaseButton>
                        </div>
                     </Form.Item>
                  </Form>
               </div>
            </div>
         </SignupWrapper>
      </AuthenWrapper>
   )
})

export default SignupCreatorScreen
