import { Divider } from 'antd'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import styled from 'styled-components'
import Checkbox from 'src/common/components/checkbox/Checkbox'

type JobFilterOptionsProps = {
   data: any
   onChange: (value: any, type?: number) => void
   ref: any
   title?: string
   children?: any
   isHideTitle?: boolean
}

const JobFilterOptionWrapper = styled.div`
   .filter__group-header {
      font-size: 13px;
      line-height: 20px;
      color: ${(props) => props.theme.color.dark_2};
      p {
         margin: unset;
      }
      margin-bottom: 8px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
   }
   .checkbox-group__wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 6px;
      .checkbox-item__wrapper {
         display: none;
         label {
            cursor: pointer
            margin: unset;
            flex: 1;
            margin-left: 8px;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;

            color: ${(props) => props.theme.color.dark_2};
         }

         input[type='checkbox'] {
            position: relative;
            border: 1.5px solid #272944;
            border-radius: 2px;
            background: none;
            cursor: pointer;
            line-height: 0;
            outline: 0;
            padding: 0 !important;
            margin-top: auto;
            margin-bottom: auto;
            height: 14px;
            width: 14px;
            -webkit-appearance: none;
            opacity: 0.5;
         }

         input[type='checkbox']:hover {
            opacity: 1;
         }

         input[type='checkbox']:checked {
            background-color: #ffffff;
            opacity: 1;
            &:before {
               opacity: 1;
            }
         }

         input[type='checkbox']:before {
            content: '';
            opacity: 0;
            position: absolute;
            right: 50%;
            top: 50%;
            width: 4px;
            height: 8px;
            border: solid black;
            border-width: 0 2px 2px 0;
            margin: -1px -1px 0 -1px;
            transform: rotate(45deg) translate(-50%, -50%);
            z-index: 2;
         }
      }

      .checkbox-item__wrapper:nth-child(1),
      .checkbox-item__wrapper:nth-child(2),
      .checkbox-item__wrapper:nth-child(3),
      .checkbox-item__wrapper:nth-child(4) {
         display: flex;
      }

      .show-all.checkbox-item__wrapper {
         display: flex;
      }
   }
`

const JobFilterOption: React.FC<JobFilterOptionsProps> = forwardRef(
   ({ onChange, data, title, children, isHideTitle }, ref) => {
      const [seeMore, setSeeMore] = useState<boolean>(true)
      const [isCheck, setIsCheck] = useState<any>([])
      const resetToDefaultChecklist = () => {
         setIsCheck([])
      }

      const handleOnChange = (e: any) => {
         const { checked } = e.target
         setIsCheck([...isCheck, Number(e.target.value)])
         if (!checked) {
            setIsCheck(isCheck.filter((item: any) => item !== Number(e.target.value)))
         }
      }

      useEffect(() => {
         onChange(isCheck)
      }, [isCheck])

      const isSeeMoreAvailable = () => {
         if (data?.length > 4) return data?.length - 4
         return false
      }

      const onToggleSeeMore = () => {
         setSeeMore(!seeMore)
      }

      useImperativeHandle(ref, () => ({
         resetFilter() {
            resetToDefaultChecklist()
         }
      }))

      return (
         <JobFilterOptionWrapper>
            {!isHideTitle && (
               <div className="filter__group-header">
                  <p>{title}</p>
                  {isSeeMoreAvailable() && (
                     <div className="filter__see-more-btn" onClick={onToggleSeeMore}>
                        {seeMore ? `Thêm +${isSeeMoreAvailable()}` : 'Thu gọn'}
                     </div>
                  )}
               </div>
            )}

            <div className="checkbox-group__wrapper">
               {data?.map((item: any) => {
                  return (
                     <div
                        className={`checkbox-item__wrapper ${seeMore ? '' : 'show-all'}`}
                        key={`${item?.id}-${item?.name}`}
                     >
                        <Checkbox
                           id={`${item?.id}-${item?.name}`}
                           name={`${item?.id}-${item?.name}`}
                           type="checkbox"
                           value={item?.id}
                           handleOnChange={handleOnChange}
                           isChecked={isCheck.includes(item?.id)}
                        />
                        <label
                           htmlFor={`${item?.id}-${item?.name}`}
                           className="checkbox__name text-oneline"
                           style={{ fontWeight: isHideTitle ? '600' : '400' }}
                        >
                           {item?.name}
                        </label>
                     </div>
                  )
               })}
            </div>
            {children}
            <Divider />
         </JobFilterOptionWrapper>
      )
   }
)

export default JobFilterOption
