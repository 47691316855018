import { Button, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { ROLE, STATUS_JOB } from 'src/helpers/constants'
import { useStore } from 'src/hooks'
import moment from 'moment'
import CustomButton from 'src/common/components/button/Custom'
import useModal from 'src/helpers/modal/useModal'
import { size } from 'lodash'

interface JobDetailButtonsProps {
   handleJobConfirm: (isReject: boolean) => void
   handleJobReceive: () => void
   handleJobReReceive: () => void
   handleJobReview: () => void
   handleJobRate: () => void
   handleJobUpdate: () => void
   handleJobDelete: () => void
   handleJobCancel: () => void
}

const JobDetailButton: React.FC<JobDetailButtonsProps> = ({
   handleJobConfirm,
   handleJobReceive,
   handleJobReReceive,
   handleJobReview,
   handleJobRate,
   handleJobUpdate,
   handleJobDelete,
   handleJobCancel
}) => {
   const { t } = useTranslation()
   const AuthStore = useStore('AuthStore')
   const role = AuthStore.data_role
   const JobDetailStore = useStore('JobDetailStore')
   const { jobDetail } = JobDetailStore
   const status = jobDetail?.status
   const { confirm } = useModal()
   console.log('jobDetail', jobDetail)

   const confirmCanSuaLai = () => {
      confirm({
         title: t(GLOBAL_CLIENT.titlexacnhancansualai),
         icon: null,
         content: t(GLOBAL_CLIENT.xacnhancansualai),
         onOk() {
            handleJobConfirm(true)
         },
         onCancel() {},
         okText: t(GLOBAL_CLIENT.xacnhan),
         cancelText: t(GLOBAL_CLIENT.huy)
      })
   }

   if (role === ROLE.CREATOR) {
      if (status === STATUS_JOB.NEW) {
         return (
            <Row className="gx-flex-row gx-align-items-center gx-justify-content-center gx-mb-2 gx-mx-1" wrap={false}>
               <CustomButton type="secondary" onClick={handleJobDelete}>
                  {t(GLOBAL_CLIENT.delete)}
               </CustomButton>
               <CustomButton type="primary" onClick={handleJobUpdate}>
                  {t(GLOBAL_CLIENT.edit)}
               </CustomButton>
            </Row>
         )
      }
      if (
         status === STATUS_JOB.DOING &&
         Math.floor(moment.duration(moment(jobDetail?.expiresAt).diff(moment())).asSeconds()) <= 0
      ) {
         return (
            <Row className="gx-flex-row gx-align-items-center gx-justify-content-center gx-mb-2 gx-mx-1" wrap={false}>
               <CustomButton type="secondary" onClick={handleJobCancel}>
                  {t(GLOBAL_CLIENT.huyjob)}
               </CustomButton>
               {/* <CustomButton type="primary" onClick={handleJobUpdate}>
                  {t(GLOBAL_CLIENT.edit)}
               </CustomButton> */}
            </Row>
         )
      }
      if (status === STATUS_JOB.REVIEW) {
         return (
            <Row className="gx-flex-row gx-align-items-center gx-justify-content-center gx-mb-2 gx-mx-1" wrap={false}>
               <CustomButton type="secondary" onClick={confirmCanSuaLai}>
                  {t(GLOBAL_CLIENT.cansualai)}
               </CustomButton>
               <CustomButton type="success" onClick={() => handleJobConfirm(false)}>
                  {t(GLOBAL_CLIENT.hoanthanh)}
               </CustomButton>
            </Row>
         )
      }
      // if (status === STATUS_JOB.FINISH) {
      //    return (
      //       <Row className="gx-flex-row gx-align-items-center gx-justify-content-center gx-mb-2 gx-mx-1" wrap={false}>
      //          <CustomButton title={'Đánh giá'} type="primary" onClick={handleJobRate} />
      //          <Button type="primary" onClick={handleJobRate}>
      //             Đánh giá
      //          </Button>
      //       </Row>
      //    )
      // }
   } else if (role === ROLE.DESIGNER) {
      if (status === STATUS_JOB.NEW) {
         return (
            <Row className="gx-flex-row gx-align-items-center gx-justify-content-center gx-mb-2 gx-mx-1" wrap={false}>
               <CustomButton
                  type={
                     jobDetail?.isPriority
                        ? 'prioritize'
                        : jobDetail?.targetId && size(jobDetail?.targetId) > 0
                        ? 'tag_designer'
                        : 'primary'
                  }
                  onClick={handleJobReceive}
               >
                  {'Nhận việc'}
               </CustomButton>
            </Row>
         )
      }
      if (status === STATUS_JOB.REJECT) {
         return (
            <Row className="gx-flex-row gx-align-items-center gx-justify-content-center gx-mb-2 gx-mx-1" wrap={false}>
               <CustomButton type="secondary" onClick={handleJobCancel}>
                  {t(GLOBAL_CLIENT.huyjob)}
               </CustomButton>
               <CustomButton type="primary" onClick={handleJobReReceive}>
                  {t(GLOBAL_CLIENT.nhanlai)}
               </CustomButton>
            </Row>
         )
      }
      if (status === STATUS_JOB.DOING) {
         return (
            <Row className="gx-flex-row gx-align-items-center gx-justify-content-center gx-mb-2 gx-mx-1" wrap={false}>
               <CustomButton type="secondary" onClick={handleJobCancel}>
                  {t(GLOBAL_CLIENT.huyjob)}
               </CustomButton>
               <CustomButton type="primary" onClick={handleJobReview}>
                  {t(GLOBAL_CLIENT.review)}
               </CustomButton>
            </Row>
         )
      }
   }

   return null
}

export default JobDetailButton
