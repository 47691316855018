import { Divider, Popover } from 'antd'
import Select, { StylesConfig } from 'react-select'
import React, { useRef, useState } from 'react'
import { FilterContentWrapper } from 'src/styles/jobs/Jobs.style'
import { observer } from 'mobx-react-lite'
import { useStore } from 'src/hooks'
import styled from 'styled-components'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import BaseButton from 'src/common/components/button'
import { ROLE } from 'src/helpers/constants'
import { showMessageSuccess } from 'src/helpers/functions'

export const PriceRangeWrapper = styled.div`
   margin-top: 12px;
   p {
      margin: unset;
   }
   .price__title {
      font-weight: bold;
      margin-bottom: 12px;
   }
   .price-filter__wrapper {
      display: grid;
      gap: 8px;
      grid-template-columns: repeat(2, 1fr);
      .price__from,
      .price__to {
         display: flex;
         gap: 8px;
         p {
            margin-top: auto;
            margin-bottom: auto;
            width: max-content;
         }
         .ant-input {
            flex: 1;
         }
      }
   }
`

type AssignJobProps = {
   detail: any
   indexItem: number
}

const AssignJob: React.FC<AssignJobProps> = observer(({ detail, indexItem }) => {
   const AuthStore = useStore('AuthStore')
   const UserStore = useStore('UserStore')
   const JobsStore = useStore('JobsStore')
   const JobDetailStore = useStore('JobDetailStore')
   const { t } = useTranslation()
   const selectInputRef = useRef<any>()
   const [designer, setDesigner] = useState(null)
   const [openFilterPopover, setOpenFilterPopover] = useState(false)

   const { data_getUserInfo } = AuthStore
   const { listDesigner } = UserStore

   const colourStyles: StylesConfig<true> = {
      control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
      option: (styles: any, { isDisabled, isFocused, isSelected }) => {
         return {
            ...styles,
            backgroundColor: isDisabled
               ? undefined
               : isSelected
               ? 'rgba(54,179,126,0.3);'
               : isFocused
               ? 'rgba(54,179,126,0.1);'
               : undefined,
            color: 'default',
            cursor: 'default',

            ':active': {
               ...styles[':active'],
               backgroundColor: 'rgba(54,179,126,0.3);'
            }
         }
      },
      multiValue: (styles: any) => {
         return {
            ...styles,
            backgroundColor: 'rgba(54,179,126,0.1)'
         }
      },
      multiValueLabel: (styles: any) => ({
         ...styles,
         color: '#36B37E'
      }),
      multiValueRemove: (styles: any) => ({
         ...styles,
         color: 'green',
         ':hover': {
            ...styles[':active'],
            backgroundColor: '#36B37E'
         }
      })
   }
   const handleOpenFilterPopover = (newOpen: boolean) => {
      setOpenFilterPopover(newOpen)
   }

   const handleChangeDesignerList = (value: any) => {
      console.log('value', value, 'valueID', value?.id)
      setDesigner(value)
      //   setFieldFilter({ ...fieldFilter, designerIds: value?.map((item: any) => item?.id)?.join(',') })
   }

   const handleAssignDesigner = async () => {
      try {
         // setIsLoading(true)
         //@ts-ignore
         if (designer && designer?.id) {
            //@ts-ignore
            const result = await JobDetailStore.assignJobToDesigner(designer?.id, detail?.id)
            setOpenFilterPopover(false)
            JobsStore.action_updateJobAssign(indexItem, designer)
            // if (result) {
            //    setTimeout(() => {
            //@ts-ignore
            // showMessageSuccess(`Bạn đã gán việc #${detail?.id} cho designer ${designer?.name} thành công.`)
            // refreshList()
            // }, 500)
            // }
         }
      } catch (error) {
         // console.log(error)
      } finally {
         // setIsLoading(false)
      }
   }

   const filterContent = () => {
      return (
         <FilterContentWrapper>
            <div className="job-filter__content-wrapper2">
               <div className="job-filter__wrapper">
                  {(data_getUserInfo?.role === ROLE.ADMIN || data_getUserInfo?.role === ROLE.SUPPORTER) && (
                     <PriceRangeWrapper>
                        <p className="price__title">{t(GLOBAL_CLIENT.assignJobFor)}</p>
                        <Select
                           ref={selectInputRef}
                           //    isMulti
                           name="designerIds"
                           options={listDesigner?.rows}
                           onChange={handleChangeDesignerList}
                           getOptionLabel={(option: any) => {
                              return isEmpty(option.name) ? option?.email : option?.name
                           }}
                           getOptionValue={(option: any) => String(option?.id)}
                           //    className="basic-multi-select"
                           styles={colourStyles}
                           classNamePrefix="select"
                           menuPlacement="auto"
                           isSearchable
                        />
                        <Divider />
                     </PriceRangeWrapper>
                  )}
               </div>
            </div>
            <div className="filter-group__btn-group2">
               <BaseButton size="lg" type="primary" className="filter-group__reset-btn" onClick={handleAssignDesigner}>
                  {t(GLOBAL_CLIENT.assignJob)}
               </BaseButton>
            </div>
         </FilterContentWrapper>
      )
   }

   return (
      <Popover
         placement="bottomRight"
         visible={openFilterPopover}
         onVisibleChange={handleOpenFilterPopover}
         className="filter-options__popover"
         content={filterContent}
         overlayClassName="filter-overlay__wrapper"
         trigger="click"
      >
         <span className="gx-link">{t(GLOBAL_CLIENT.assignJob)}</span>
         <Divider type="vertical" />
      </Popover>
   )
})

export default AssignJob
