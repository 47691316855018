import { observer } from "mobx-react-lite";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";
import { GLOBAL_CLIENT } from "../../helpers/GlobalClient";
import { COMMON_STATUS } from "../../helpers/constants";

const VoucherStatus = observer((props: any) => {
    const { t } = useTranslation();
    const { type } = props;

    if (type === COMMON_STATUS.ACTIVE) {
        return <Tag color="default">{t(GLOBAL_CLIENT.txtUsed)}</Tag>;
    } else if (type === COMMON_STATUS.INACTIVE) {
        return <Tag color="success">{t(GLOBAL_CLIENT.txtNotUse)}</Tag>;
    }

    return null;
});

export { VoucherStatus };
