import { FunctionComponent } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import theme from 'src/helpers/theme'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import i18n from 'src/helpers/i18n'

export const Loading: FunctionComponent<{ isLoading: boolean }> = (props) => {
   if (props.isLoading === false) {
      return null
   }
   const antIcon = (
      <LoadingOutlined
         style={{
            fontSize: 24,
            color: theme.color.dark_2
         }}
         spin
      />
   )
   return (
      <div className="loading">
         <Spin
            tip={i18n.t(GLOBAL_CLIENT.loading)}
            size="large"
            className="spinner"
            style={{ color: theme.color.dark_2 }}
            indicator={antIcon}
         />
      </div>
   )
}
