import { Layout, Tabs } from 'antd'

import { GLOBAL_CLIENT } from '../../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import 'moment/locale/vi'

import { observer } from 'mobx-react-lite'
import { useStore } from '../../../hooks'
import { tabIDsNumberEdit } from '../../../helpers/constants'
const { Content } = Layout
const TabPane = Tabs.TabPane

const NumberEditsManagement = observer((props: any) => {
   const { t } = useTranslation()
   const DashboardStore = useStore('DashboardStore')

   const onChangeTab = (key: string) => {
      DashboardStore.setActiveKeyNumberEdit(key)
   }
   return (
      <Content {...props}>
         <Tabs activeKey={DashboardStore.activeKeyNumberEdit} onChange={onChangeTab}>
            <TabPane tab={t(GLOBAL_CLIENT.designer)} key={tabIDsNumberEdit.designer}>
               <div></div>
            </TabPane>
            <TabPane tab={t(GLOBAL_CLIENT.creator)} key={tabIDsNumberEdit.creator}>
               <div></div>
            </TabPane>
         </Tabs>
      </Content>
   )
})

export default NumberEditsManagement
