import { Card } from 'antd'

type WidgetProps = {
   title?: string
   styleName?: string
   cover?: string
   extra?: string
   actions?: any
}

const Widget: React.FC<WidgetProps> = ({ title, children, styleName, cover, extra, actions }) => {
   return (
      <Card title={title} actions={actions} cover={cover} className={`gx-card-widget ${styleName}`} extra={extra}>
         {children}
      </Card>
   )
}

export default Widget
