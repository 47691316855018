import { Button, Col, Modal, Row, Table, InputNumber, DatePicker, Pagination, Space } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
   DATE_FORMAT_CLIENT,
   DATE_FORMAT_CLIENT_PARAMS,
   DATE_TIME_FORMAT_CLIENT_short,
   PAGE_SIZE,
   ROLE,
   getPage,
   getUniqueCodeBank,
   typeFund
} from 'src/helpers/constants'
import { useStore } from 'src/hooks'
import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import 'moment/locale/vi'
import locale from 'antd/es/date-picker/locale/vi_VN'
import { debounce, isEmpty } from 'lodash'
import useWindowDimensions from 'src/common/useWindowDimensions'
import { formatCurrency, showMessageSuccess } from 'src/helpers/functions'
import theme from 'src/helpers/theme'
import styled from 'styled-components'
import useModal from 'src/helpers/modal/useModal'

// interface Transaction {
//    jobId: number
//    time: Date
//    type: string
//    quantity: number
//    unitPrice: number
//    amount: number
//    remainingBudget: number
// }
const TransactionHistory = observer(({ visible, handleCancel }: any) => {
   const AuthStore = useStore('AuthStore')
   const FundStore = useStore('FundStore')
   const CommonConfigStore = useStore('CommonConfigStore')
   const [showWithdrawModal, setShowWithdrawModal] = useState(false)
   const minRutTienConfig = CommonConfigStore?.designerConfigList?.minWithdraw || 0
   const minTienGiuLaiConfig = CommonConfigStore?.designerConfigList?.amountHold || 0
   const mintienDuocRut = parseInt(minRutTienConfig) + parseInt(minTienGiuLaiConfig)
   const mintienCoTheRut =
      Math.floor((parseInt(AuthStore?.data_getUserInfo?.wallet?.balance) - parseInt(minTienGiuLaiConfig)) / 1000) * 1000
   // console.log(
   //    'minTienGiuLaiConfig: ',
   //    minTienGiuLaiConfig,
   //    'minRutTienConfig',
   //    minRutTienConfig,
   //    'mintienDuocRut',
   //    mintienDuocRut
   // )

   const [withdrawAmount, setWithdrawAmount] = useState<any>(mintienCoTheRut)
   const [withdrawError, setWithdrawError] = useState('')
   const [withdrawWarning, setWithdrawWarning] = useState('')
   const [startTime, setStartTime] = useState(moment().subtract(1, 'months'))
   const [endTime, setEndTime] = useState(moment())
   const { rows, count, offset } = AuthStore.transactionHistory
   const role = AuthStore.data_role
   const [remainingBudget, setRemainingBudget] = useState<number>(AuthStore?.data_getUserInfo?.wallet?.balance)
   const { height } = useWindowDimensions()
   const { t } = useTranslation()
   const { confirm } = useModal()
   // const [withdrawSuccess, setWithdrawSuccess] = useState('')
   useEffect(() => {
      if (visible) {
         getListData(0, {
            // text: textSearch,
            from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
            to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
         })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [startTime, endTime, visible])

   useEffect(() => {
      if (parseInt(AuthStore?.data_getUserInfo?.wallet?.balance) < mintienDuocRut) {
         setWithdrawError(t(GLOBAL_CLIENT.dudieukienruttien).replace('{0}', formatCurrency(mintienDuocRut, true)))
      } else {
         setWithdrawAmount(mintienCoTheRut)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [showWithdrawModal, mintienDuocRut, AuthStore?.data_getUserInfo?.wallet?.balance])

   const getListData = async (currentPage: number, param?: any) => {
      try {
         // setIsLoading(true)
         const offset = getPage(currentPage)
         const params = { offset, ...param }
         // console.log('params', params)
         AuthStore.getTransaction(params)
         // await FundStore.getFundList(params)
      } catch (error) {
         // console.log(error)
      } finally {
         // setIsLoading(false)
      }
   }

   const onChange = (currentPage: number) => {
      getListData(currentPage - 1, {
         ...{
            from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
            to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
         }
      })
   }

   const handleWithdraw = async () => {
      // const now = moment()
      // const weekAgo = moment().subtract(7, 'days')
      confirm({
         title: t(GLOBAL_CLIENT.xacnhanck),
         icon: <ExclamationCircleOutlined />,
         content: `<div>
            <div>
               ${t(GLOBAL_CLIENT.bankName)}: ${AuthStore?.data_getUserInfo?.bankName}
            </div>
            <div>
               ${t(GLOBAL_CLIENT.accountBankName)}: ${AuthStore?.data_getUserInfo?.accountBankName}
            </div>
            <div>
               ${t(GLOBAL_CLIENT.bankAccount)}: ${AuthStore?.data_getUserInfo?.bankNumber}
            </div>
         </div>`,
         onCancel() {},
         okText: t(GLOBAL_CLIENT.dongy),
         cancelText: t(GLOBAL_CLIENT.huy),
         async onOk() {
            const params = {
               price: withdrawAmount || '',
               code: getUniqueCodeBank(),
               accountId: AuthStore?.data_getUserInfo?.id || '',
               description: t(GLOBAL_CLIENT.designerruttien),
               type: typeFund.WITHDRAW
               // status:1
            }
            // console.log('params ', params, 'AuthStore?.data_getUserInfo;', AuthStore?.data_getUserInfo)
            const response = await FundStore.createFund(params)
            if (response?.code === 200) {
               showMessageSuccess(t(GLOBAL_CLIENT.hethongdangxacnhan1den10))
            }

            setWithdrawAmount(0)
            setWithdrawError('')
            setShowWithdrawModal(false)
            handleCancel()
         }
      })

      // console.log('withdrawAmount', withdrawAmount)
   }

   let columns = [
      // {
      //    title: t(GLOBAL_CLIENT.receiptType),
      //    dataIndex: 'id',
      //    // sorter: (a: any, b: any) => a.jobId - b.jobId,
      //    key: 'id'
      // },
      {
         title: 'ID',
         dataIndex: 'job',
         // sorter: (a: any, b: any) => a.jobId - b.jobId,
         key: 'job',
         render: (value: any, record: any) => {
            return <span className="txtMax2Line">{value?.name || record?.code || '#' + record?.id}</span>
            // return <span className="txtMax2Line">{getTypeThuChi(value)}</span>
         }
      },
      {
         title: t(GLOBAL_CLIENT.thoigian),
         dataIndex: 'createdAt',
         // sorter: (a: any, b: any) => a.time - b.time,
         key: 'createdAt',
         render: (time: Date) => moment(time).format(DATE_TIME_FORMAT_CLIENT_short)
      },
      {
         title: t(GLOBAL_CLIENT.loaigiaodich),
         dataIndex: 'description',
         // sorter: (a: any, b: any) => a.type - b.type,
         key: 'description',
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
            // return <span className="txtMax2Line">{getTypeThuChi(value)}</span>
         }
      },
      {
         title: role === ROLE.CREATOR ? t(GLOBAL_CLIENT.tongcong) : t(GLOBAL_CLIENT.money),
         dataIndex: 'price',
         key: 'price',
         render: (value: any, record: any) => {
            return (
               <span className="txtMax2Line" style={{ color: record?.type ? 'green' : 'red' }}>
                  {record?.type ? '+ ' : '- '}
                  {formatCurrency(value, true)}
               </span>
            )
         }
         // sorter: (a: any, b: any) => a.amount - b.amount,
         // render: (amount: number) =>
         //    new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(amount)
      },
      {
         title: role === ROLE.CREATOR ? t(GLOBAL_CLIENT.ngansachconlai) : t(GLOBAL_CLIENT.tongcong),
         dataIndex: 'balance',
         // sorter: (a: any, b: any) => a.remainingBudget - b.remainingBudget,
         key: 'balance',
         render: (value: any) => {
            return <span className="txtMax2Line">{formatCurrency(value, true)}</span>
         }
         // render: (remainingBudget: number) =>
         //    new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(remainingBudget)
      }
   ]

   const handleRangePickerChange = (date: any) => {
      // set_dateRangerPicker(date);
      // console.log('datee: ', date)
      setStartTime(date[0])
      setEndTime(date[1])
   }
   const renderPagination = () => {
      if (isEmpty(rows)) {
         return null
      }

      return (
         <div className="containerPagination">
            <Pagination
               onChange={onChange}
               pageSize={PAGE_SIZE}
               current={offset / PAGE_SIZE + 1}
               showSizeChanger={false}
               total={count}
            />
         </div>
      )
   }

   const StyledRangePickerContainer = styled.div`
      @media (max-width: 576px) {
         .ant-picker-panels {
            flex-direction: column !important;
         }
      }
   `

   return (
      <Modal
         visible={visible}
         title={t(GLOBAL_CLIENT.transactionHistory)}
         onCancel={handleCancel}
         closable={true}
         closeIcon={<CloseCircleOutlined />}
         width={'90%'}
         style={{ maxWidth: '1000px' }}
         maskClosable={false}
         footer={null}
      >
         {AuthStore.data_role === ROLE.DESIGNER && (
            <Row
               gutter={[16, 16]}
               className="gx-flex-row gx-align-items-center gx-justify-content-start gx-mb-2"
               wrap={false}
            >
               <Col>
                  <div style={{ paddingTop: 10, paddingBottom: 10, borderRadius: 4 }}>
                     <div
                        style={{
                           fontSize: '16px',
                           lineHeight: '24px',
                           fontWeight: 600,
                           color: '#FFAD63'
                        }}
                        className="gx-fs-md gx-text-center"
                     >
                        {formatCurrency(remainingBudget, true)} {' VND'}
                     </div>
                  </div>
               </Col>
               {role === ROLE.DESIGNER && (
                  <Col>
                     <div
                        style={{
                           fontSize: '14px',
                           lineHeight: '22px',
                           fontWeight: 400,
                           backgroundColor: '#272944',
                           color: '#fff',
                           padding: '9px 16px',
                           borderRadius: 8
                        }}
                        onClick={() => setShowWithdrawModal(true)}
                     >
                        {t(GLOBAL_CLIENT.withdawal)}
                     </div>
                  </Col>
               )}
               <Col></Col>
            </Row>
         )}
         <Row style={{ alignItems: 'center', justifyContent: 'space-between', paddingLeft: 12, paddingRight: 12 }}>
            <Space className="total-items">
               {t(GLOBAL_CLIENT.totalItems)}: {count}
            </Space>
            <Space className="total-items">
               <DatePicker.RangePicker
                  allowClear={false}
                  defaultValue={[startTime, endTime]}
                  format={DATE_FORMAT_CLIENT}
                  locale={locale}
                  onChange={handleRangePickerChange}
                  panelRender={(panelNode) => <StyledRangePickerContainer>{panelNode}</StyledRangePickerContainer>}
               />
            </Space>
         </Row>

         <Table
            scroll={{ x: 800, y: height - 480 }}
            columns={columns}
            dataSource={rows}
            rowKey="id"
            pagination={false}
         />

         {renderPagination()}
         {showWithdrawModal && role !== ROLE.CREATOR && (
            <Modal
               title={t(GLOBAL_CLIENT.withdawal)}
               visible={showWithdrawModal}
               onOk={handleWithdraw}
               onCancel={() => {
                  setShowWithdrawModal(false)
                  setWithdrawAmount(0)
                  setWithdrawError('')
               }}
               closable={true}
               closeIcon={<CloseCircleOutlined />}
               footer={null}
            >
               <InputNumber
                  disabled
                  defaultValue={withdrawAmount}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  placeholder={t(GLOBAL_CLIENT.inputPrice)}
                  onChange={(value) => setWithdrawAmount(value)}
                  style={{ marginBottom: '10px' }}
                  step={1000}
                  // max={MinMaxRutTien.max}
                  // min={MinMaxRutTien.min}
               />
               {withdrawError && <p style={{ color: 'red' }}>{withdrawError}</p>}
               {withdrawWarning && <p style={{ color: theme?.color.grey_1 }}>{withdrawWarning}</p>}
               <div className="gx-flex-row gx-justify-content-end">
                  <Button
                     style={{
                        fontSize: '14px',
                        lineHeight: '22px',
                        fontWeight: 400,
                        backgroundColor: '#FFAD63',
                        color: '#fff'
                     }}
                     disabled={AuthStore?.data_getUserInfo?.wallet?.balance < mintienDuocRut}
                     onClick={debounce(handleWithdraw, 500)}
                  >
                     {t(GLOBAL_CLIENT.apply)}
                  </Button>
               </div>
            </Modal>
         )}
      </Modal>
   )
})

export default TransactionHistory
