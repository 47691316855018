import { useEffect } from 'react'
import { Modal, Button, Pagination, Table, Space, Row } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from '../../../helpers/functions'
import {
   DATE_FORMAT_CLIENT_PARAMS,
   DATE_TIME_FORMAT_CLIENT_short,
   PAGE_SIZE,
   ROLE,
   getPage
} from '../../../helpers/constants'
import moment from 'moment'
import { useStore } from '../../../hooks'
import { isEmpty } from 'lodash'
import useWindowDimensions from 'src/common/useWindowDimensions'

const Transaction = observer((props: any) => {
   const { t } = useTranslation()
   const UserStore = useStore('UserStore')
   const { height } = useWindowDimensions()

   const { item, visible, startTime, endTime, onOk, role, onCancel } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars

   const { rows, count, offset } = UserStore.listTransaction

   const getListData = async (currentPage: number, param?: any) => {
      try {
         // setIsLoading(true)
         const offset = getPage(currentPage)
         const params = { offset, ...param, accountId: item?.userId }
         UserStore.getTransaction(params)
         // await FundStore.getFundList(params)
      } catch (error) {
         // console.log(error)
      } finally {
         // setIsLoading(false)
      }
   }
   useEffect(() => {
      getListData(0, {
         // text: textSearch,
         from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   let columns = [
      // {
      //    title: t(GLOBAL_CLIENT.receiptType),
      //    dataIndex: 'id',
      //    // sorter: (a: any, b: any) => a.jobId - b.jobId,
      //    key: 'id'
      // },
      {
         title: 'ID',
         dataIndex: 'job',
         // sorter: (a: any, b: any) => a.jobId - b.jobId,
         key: 'job',
         render: (value: any, record: any) => {
            return <span className="txtMax2Line">{value?.name || record?.code || '#' + record?.id}</span>
            // return <span className="txtMax2Line">{getTypeThuChi(value)}</span>
         }
      },
      {
         title: t(GLOBAL_CLIENT.thoigian),
         dataIndex: 'createdAt',
         // sorter: (a: any, b: any) => a.time - b.time,
         key: 'createdAt',
         render: (time: Date) => moment(time).format(DATE_TIME_FORMAT_CLIENT_short)
      },
      {
         title: t(GLOBAL_CLIENT.loaigiaodich),
         dataIndex: 'description',
         // sorter: (a: any, b: any) => a.type - b.type,
         key: 'description',
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
            // return <span className="txtMax2Line">{getTypeThuChi(value)}</span>
         }
      },
      {
         title: role === ROLE.CREATOR ? t(GLOBAL_CLIENT.tongcong) : t(GLOBAL_CLIENT.money),
         dataIndex: 'price',
         key: 'price',
         render: (value: any, record: any) => {
            return (
               <span className="txtMax2Line" style={{ color: record?.type ? 'green' : 'red' }}>
                  {record?.type ? '+ ' : '- '}
                  {formatCurrency(value, true)}
               </span>
            )
         }
         // sorter: (a: any, b: any) => a.amount - b.amount,
         // render: (amount: number) =>
         //    new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(amount)
      },
      {
         title: role === ROLE.CREATOR ? t(GLOBAL_CLIENT.ngansachconlai) : t(GLOBAL_CLIENT.tongcong),
         dataIndex: 'balance',
         // sorter: (a: any, b: any) => a.remainingBudget - b.remainingBudget,
         key: 'balance',
         render: (value: any) => {
            return <span className="txtMax2Line">{formatCurrency(value, true)}</span>
         }
         // render: (remainingBudget: number) =>
         //    new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(remainingBudget)
      }
   ]

   const onChange = (currentPage: number) => {
      getListData(currentPage - 1, {
         ...{
            from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
            to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
         }
      })
   }

   const renderPagination = () => {
      if (isEmpty(rows)) {
         return null
      }

      return (
         <div className="containerPagination">
            <Pagination
               onChange={onChange}
               pageSize={PAGE_SIZE}
               current={offset / PAGE_SIZE + 1}
               showSizeChanger={false}
               total={count}
            />
         </div>
      )
   }
   return (
      <Modal
         width={'78%'}
         visible={visible}
         centered
         title={`Số lượng mẫu hoàn thành của ${item?.name} từ ${moment(startTime).format(
            DATE_FORMAT_CLIENT_PARAMS
         )}-${moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS)}`}
         okText={t(GLOBAL_CLIENT.edit)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         footer={[
            <Button key="back" onClick={onCancel}>
               Đóng
            </Button>
         ]}
      >
         <Row style={{ alignItems: 'center', justifyContent: 'space-between', paddingLeft: 12, paddingRight: 12 }}>
            <Space className="total-items">
               {t(GLOBAL_CLIENT.totalItems)}: {count}
            </Space>
         </Row>
         <Table
            scroll={{ x: 800, y: height - 480 }}
            columns={columns}
            dataSource={rows}
            rowKey="id"
            pagination={false}
         />
         {renderPagination()}
      </Modal>
   )
})

export default Transaction
