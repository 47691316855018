import { observable, action } from "mobx";
import { responseDefault } from "./store.constants";
import { isEmpty } from "lodash";
import { Request2 } from "src/common/Request2";
import api from "src/common/api";
import { PAGE_SIZE, typeFund } from "src/helpers/constants";
export const listFundInit = {
  results: [],
  limit: 50,
  page: 1,
  totalPages: 1,
  totalResults: 0,
  collect: 0,
  pay: 0,
  totalAfter: 0,
};
export class FundStore {
  @observable listFund: any = listFundInit;

  @observable fundList: any = { ...responseDefault }
  @observable priceFund: any = {
    "before": 0,
    "collect": 0,
    "spend": 0,
    "after": 0
  };

  @observable listTypesReceipts: any = {
    results: [{}],
    limit: 50,
    page: 1,
    totalPages: 1,
    totalResults: 0,
  };

  @observable listTypesReceiptsFilter: any = {
    results: [],
    limit: 1000,
    page: 1,
    totalPages: 1,
    totalResults: 0,
  };

  @observable listTypesReceiptsFilterCreateDetail: any = {
    results: [],
    limit: 1000,
    page: 1,
    totalPages: 1,
    totalResults: 0,
  };

  @observable listGroupUser: any = {
    staff: "staff",
    customer: "customer",
    partner: "partner",
    ncc: "ncc",
    other: "other",
  };

  @action
  async getFundList(params: any = {},) {
    const result = await Request2.getWithToken(api.funds.getList, { ...params, ...{ limit: PAGE_SIZE } })
    if (!isEmpty(result)) {
      this.fundList = result
    }
  }

  @action
  async createFund(params: any = {}) {
    // console.log('params: ', params, 'api.funds.create: ', api.funds.create);

    const result = await Request2.postWithToken(params, api.funds.create)
    // console.log('result: ', result);
    if (params?.type === typeFund.WITHDRAW || params?.type === typeFund.RECHARGE) {
      return result;
    }
    return result?.data;
  }

  @action
  async approveFund(id: string | number, params: any = {}, history?: any) {
    // console.log('params: ', params, 'api.funds.create: ', api.funds.approve);

    const result = await Request2.postWithToken(params, api.funds.approve + '/' + id)
    // console.log('result: ', result);
    return result?.data;
  }

  @action
  async getTotalPrice(from: string, to: string) {
    const result = await Request2.getWithToken(api.funds.total, { from, to })
    if (!isEmpty(result)) {
      this.priceFund = result
    }
  }
  // @action
  // async actionGetListTypesReceipt(
  //   textSearch: string,
  //   type: string,
  //   page: number,
  //   sortDateDesc: boolean,
  //   pageSize?: number
  // ) {
  //   const name = textSearch ? `&name=${textSearch}` : "";
  //   const typeFilter = type ? `&type=${type}` : "";
  //   const sortDateDescSearch = sortDateDesc ? "&sortBy=createdAt:desc" : "";
  //   const DOMAIN = `${WsCode.typeFund.getList}?limit=${
  //     pageSize || PAGE_SIZE
  //   }&page=${page}${name}${typeFilter}${sortDateDescSearch}`;
  //   const result = await Request.getWithToken(DOMAIN);
  //   console.log(
  //     "result; actionGetListTypesReceipt",
  //     JSON.stringify(result),
  //     "\nDOMAIN; ",
  //     DOMAIN
  //   );

  //   if (result?.page) {
  //     result.page = page;
  //   }
  //   this.listTypesReceipts = result || resultInit;
  //   console.log("result; ", result);
  // }
  // @action
  // async actionGetListTypesReceiptFilter(
  //   type: string,
  //   page: number,
  //   pageSize?: number
  // ) {
  //   const typeFilter = type ? `&type=${type}` : "";
  //   const DOMAIN = `${WsCode.typeFund.getList}?limit=${
  //     pageSize || PAGE_SIZE
  //   }&page=${page}${typeFilter}`;
  //   const result = await Request.getWithToken(DOMAIN);
  //   console.log(
  //     "result; actionGetListTypesReceiptFilter",
  //     JSON.stringify(result),
  //     "\nDOMAIN; ",
  //     DOMAIN
  //   );

  //   if (result?.page) {
  //     result.page = page;
  //   }
  //   this.listTypesReceiptsFilter = result || resultInit;
  //   console.log("result; ", result);
  // }

  // @action
  // async actionGetListTypesReceiptFilterCreateDetail(
  //   type: string,
  //   page: number,
  //   pageSize?: number
  // ) {
  //   const typeFilter = type ? `&type=${type}` : "";
  //   const DOMAIN = `${WsCode.typeFund.getList}?limit=${
  //     pageSize || PAGE_SIZE
  //   }&page=${page}${typeFilter}`;
  //   const result = await Request.getWithToken(DOMAIN);
  //   console.log(
  //     "result; actionGetListTypesReceiptFilter",
  //     JSON.stringify(result),
  //     "\nDOMAIN; ",
  //     DOMAIN
  //   );

  //   if (result?.page) {
  //     result.page = page;
  //   }
  //   this.listTypesReceiptsFilterCreateDetail = result || resultInit;
  //   console.log("result; ", result);
  // }

  // @action
  // async actionCreateFund(
  //   typeUserInteractive: string,
  //   userInteractiveFund: string,
  //   dataUserText: string,
  //   price: string,
  //   type: string | number,
  //   typePayment: string,
  //   dateTime: string,
  //   note: string
  // ) {
  //   let body: any = {
  //     typeUserInteractive,
  //     type,
  //     typePayment,
  //     price,
  //     note,
  //     dateTime,
  //   };

  //   if (dataUserText) {
  //     body.dataUserText = dataUserText;
  //   }
  //   if (userInteractiveFund) {
  //     if(typeUserInteractive==='ncc'){
  //       body.dataNcc = userInteractiveFund;
  //     }else{
  //       body.userInteractiveFund = userInteractiveFund;
  //     }
  //   }
  //   console.log("---------body; ", body, JSON.stringify(body));
  //   const DOMAIN = `${WsCode.fund.create}`;
  //   const result = await Request.postWithToken(body, DOMAIN);
  //   console.log(
  //     "result; fund.create",
  //     JSON.stringify(result),
  //     "\nDOMAIN; ",
  //     DOMAIN,
  //     "body :",
  //     JSON.stringify(body)
  //   );
  //   return result;
  // }

  // @action
  // async actionUpdateFund(
  //   id: string,
  //   typeUserInteractive: string,
  //   userInteractiveFund: string,
  //   dataUserText: string,
  //   price: string,
  //   type: string | number,
  //   typePayment: string,
  //   dateTime: string,
  //   note: string
  // ) {
  //   let body: any = {
  //     typeUserInteractive,
  //     type,
  //     typePayment,
  //     price,
  //     dateTime,
  //   };

  //   if (note) {
  //     body.note = note;
  //   }
  //   if (dataUserText) {
  //     body.dataUserText = dataUserText;
  //   }
  //   if (userInteractiveFund) {
  //     if(typeUserInteractive==='ncc'){
  //       body.dataNcc = userInteractiveFund;
  //     }else{
  //       body.userInteractiveFund = userInteractiveFund;
  //     }
  //   }
  //   console.log("---------body; ", body, JSON.stringify(body));

  //   const DOMAIN = `${WsCode.fund.update}/${id}`;
  //   const result = await Request.putWithToken(body, DOMAIN);
  //   console.log(
  //     "result; fund.edit",
  //     JSON.stringify(result),
  //     "\nDOMAIN; ",
  //     DOMAIN,
  //     "body :",
  //     JSON.stringify(body)
  //   );
  //   return result;
  // }

  // @action
  // async actionCreateTypesReceipt(name: string, type: number) {
  //   const body = { name, type };
  //   const DOMAIN = `${WsCode.typeFund.create}`;
  //   const result = await Request.postWithToken(body, DOMAIN);
  //   console.log(
  //     "result; typeFund.create",
  //     JSON.stringify(result),
  //     "\nDOMAIN; ",
  //     DOMAIN
  //   );
  //   return result;
  // }

  // @action
  // async actionUpdateTypesReceipt(id: string, name: string, type: number) {
  //   const DOMAIN = `${WsCode.typeFund.update}/${id}`;
  //   const body = { name, type };
  //   const result = await Request.putWithToken(body, DOMAIN);
  //   console.log(
  //     "result; typeFund.update",
  //     JSON.stringify(result),
  //     "\nDOMAIN; ",
  //     DOMAIN
  //   );

  //   return result;
  // }

  // @action
  // async actionDeleteTypesReceipt(id: string) {
  //   const DOMAIN = `${WsCode.typeFund.delete}/${id}`;
  //   const body = {};
  //   const result = await Request.deleteWithToken(body, DOMAIN);
  //   console.log(
  //     "result; typeFund.delete",
  //     JSON.stringify(result),
  //     "\nDOMAIN; ",
  //     DOMAIN
  //   );

  //   return result;
  // }

  // @action
  // async actionDeleteFund(id: string) {
  //   const DOMAIN = `${WsCode.fund.delete}/${id}`;
  //   const body = {};
  //   const result = await Request.deleteWithToken(body, DOMAIN);
  //   console.log(
  //     "result; fund.delete",
  //     JSON.stringify(result),
  //     "\nDOMAIN; ",
  //     DOMAIN
  //   );

  //   return result;
  // }

  // @action
  // async actionGetListFund(
  //   from: string,
  //   to: string,
  //   page: number,
  //   sortDateDesc: boolean,
  //   pageSize?: number,
  //   collection?: string,
  //   code?: string,
  //   dataUserText?: string,
  //   typeUserInteractive?: string,
  //   userInteractiveFund?: string,
  //   type?: string,
  //   dataNcc?:string
  // ) {
  //   console.log(
  //     "code",
  //     code,
  //     dataUserText,
  //     typeUserInteractive,
  //     userInteractiveFund
  //   );

  //   const filterTime = `&from=${from}&to=${to}`;
  //   const codeFilter = code ? `&code=${code}` : "";
  //   const collectionFilter =
  //     collection !== "" && collection !== undefined
  //       ? `&collection=${collection}`
  //       : "";
  //   const dataUserTextFilter = dataUserText
  //     ? `&dataUserText=${dataUserText}`
  //     : "";
  //   const typeUserInteractiveFilter = typeUserInteractive
  //     ? `&typeUserInteractive=${typeUserInteractive}`
  //     : "";
  //   const userInteractiveFundFilter = userInteractiveFund
  //     ? `&userInteractiveFund=${userInteractiveFund}`
  //     : "";
  //   const sortDateDescSearch = sortDateDesc ? "&sortBy=createdAt:desc" : "";
  //   const typeFilter = type ? `&type=${type}` : "";
  //   const dataNccFilter = dataNcc ? `&dataNcc=${dataNcc}` : "";
  //   const DOMAIN = `${WsCode.fund.getList}?limit=${
  //     pageSize || PAGE_SIZE
  //   }&page=${page}${filterTime}${codeFilter}${collectionFilter}${dataUserTextFilter}${typeUserInteractiveFilter}${userInteractiveFundFilter}${typeFilter}${sortDateDescSearch}${dataNccFilter}`;

  //   console.log("- -------DOMAIN--------; ", DOMAIN);
  //   const result = await Request.getWithToken(DOMAIN);
  //   console.log(
  //     "-------result; ListFund",
  //     JSON.stringify(result),
  //     "\nDOMAIN; ",
  //     DOMAIN
  //   );

  //   if (result?.page) {
  //     result.page = page;
  //   }
  //   this.listFund = result || listFundInit;
  // }

  // @action
  // async resetStore() {
  //   this.listFund = { ...listFundInit };
  //   this.listTypesReceipts = { ...resultInit };
  //   this.listTypesReceiptsFilterCreateDetail = {
  //     results: [],
  //     limit: 1000,
  //     page: 1,
  //     totalPages: 1,
  //     totalResults: 0,
  //   };
  //   this.listTypesReceiptsFilter = {
  //     results: [],
  //     limit: 1000,
  //     page: 1,
  //     totalPages: 1,
  //     totalResults: 0,
  //   };
  // }

  @action
  async resetStoreDebts() {
    this.listFund = { ...listFundInit };
  }
}
