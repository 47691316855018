import { Col, Empty, Image, Input, Row } from 'antd'
import { isArray } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Request2 } from 'src/common/Request2'
import api from 'src/common/api'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { ROLE, STATUS_JOB } from 'src/helpers/constants'
import { showMessageError } from 'src/helpers/functions'
import { useStore } from 'src/hooks'
import Images from 'src/common/Images'
import NoData from 'src/common/components/NoData'

interface ImageType {
   path: string
   description: string
}

interface JobDetailUploadProps {
   clearImage: () => void
   editedImageId: number | any
   setEditedImageId: any
}

const JobDetailUpload: React.FC<JobDetailUploadProps> = observer(
   ({ clearImage, editedImageId, setEditedImageId }: JobDetailUploadProps) => {
      const { t } = useTranslation()
      const JobDetailStore = useStore('JobDetailStore')
      const { jobDetail } = JobDetailStore
      const AuthStore = useStore('AuthStore')
      const role = AuthStore.data_role
      const status = jobDetail?.status
      const images = jobDetail?.images

      const fileInputRef = useRef<HTMLInputElement>(null)
      const [editedImage, setEditedImage] = useState<ImageType>({
         path: '',
         description: ''
      })
      // const [isLoading, setIsLoading] = useState(false)
      const handleIconClick = () => {
         if (fileInputRef.current) {
            fileInputRef.current.click()
         }
      }

      // const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      //    const file = event.target.files ? event.target.files[0] : null
      //    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif']

      //    setEditedImageId(null)
      //    if (file && allowedTypes.includes(file.type)) {
      //       try {
      //          // setIsLoading(true)
      //          const { data } = await Request2.uploadFile(file, api.upload.uploadFile)
      //          const newImage = {
      //             path: data.filename,
      //             description: images?.length ? `Ảnh ${images?.length + 1}` : 'Ảnh 1'
      //          }
      //          const updatedImages = isArray(images) ? [...images, newImage] : [newImage]
      //          // console.log(updatedImages)
      //          JobDetailStore.setJobDetailImages(updatedImages)
      //       } catch (error) {
      //          showMessageError(error)
      //       } finally {
      //          // setIsLoading(false)
      //       }
      //    } else {
      //       showMessageError(t(GLOBAL_CLIENT.validateanh))
      //    }
      //    if (fileInputRef.current) {
      //       fileInputRef.current.value = ''
      //    }
      // }

      const handleImagesUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
         const fileList = event.target.files ? event.target.files : null
         const allowedTypes = ['image/jpeg', 'image/png', 'image/gif']
         let isValidFile = true

         if (fileList) {
            for (var i = 0; i < fileList.length; i++) {
               var file = fileList[i]
               var fileType = file.type
               if (!allowedTypes.includes(fileType)) {
                  isValidFile = false
                  break
               }
            }
         }

         if (fileList && isValidFile) {
            try {
               // setIsLoading(true)
               const uploadRequests = Array.from(fileList).map(async (file, index) => {
                  const { data } = await Request2.uploadFile(file, api.upload.uploadFile)
                  return {
                     path: data.filename,
                     description: `Ảnh ${images?.length + 1 + index}`
                  }
               })
               const newImages = await Promise.all(uploadRequests)
               const updatedImages = [...images, ...newImages]
               // console.log('images', images)
               // console.log('newImages', newImages)
               JobDetailStore.setJobDetailImages(updatedImages)
               // console.log('updatedImages', updatedImages)
            } catch (error) {
               // console.error(error)
            } finally {
               // setIsLoading(false)
            }
         } else {
            showMessageError(t(GLOBAL_CLIENT.validateanh))
         }
         if (fileInputRef.current) {
            fileInputRef.current.value = ''
         }
      }

      const handleImageDelete = (id: number) => {
         const filterImages = images
         filterImages?.splice(id, 1)
         JobDetailStore.setJobDetailImages(filterImages)
         setEditedImageId(null)
         clearImage()
      }

      const handleEditClick = (image: any, id: number) => {
         setEditedImageId(id)
         if (image) {
            setEditedImage({
               path: image?.path,
               description: image?.description?.trim()
            })
         }
      }

      const handleEditSave = (id: number) => {
         // console.log(editedImage)

         if (id !== null && editedImage?.description?.trim() !== '') {
            const updatedImages = jobDetail.images.map((image: any, index: any) =>
               index === id ? { ...image, description: editedImage?.description?.trim() } : image
            )
            JobDetailStore.setJobDetailImages(updatedImages)
            setEditedImage({
               path: '',
               description: ''
            })
            setEditedImageId(null)
         } else {
            showMessageError(t(GLOBAL_CLIENT.validatemotaanh))
         }
      }

      const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
         setEditedImage({ ...editedImage, description: event.target.value })
      }

      // console.log(editedImageId)

      return (
         <div>
            {/* <Loading isLoading={isLoading} /> */}
            <Row className="gx-flex-row gx-align-items-center gx-justify-content-between gx-mb-2 gx-mx-1" wrap={false}>
               <h4 style={{ fontSize: 14, fontWeight: 600, lineHeight: '22px', color: '#272944' }}>
                  {t(GLOBAL_CLIENT.imageDescription)}
               </h4>
               {role === ROLE.CREATOR &&
                  status !== STATUS_JOB.DOING &&
                  status !== STATUS_JOB.REVIEW &&
                  status !== STATUS_JOB.REJECT &&
                  status !== STATUS_JOB.FINISH && (
                     <div className="gx-module-logo gx-mb-2" onClick={() => handleIconClick()}>
                        <img
                           style={{ color: '#272944', marginLeft: 4, height: 20 }}
                           src={Images.ic_camera}
                           alt="icon"
                        />
                        <div
                           style={{
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: '22px',
                              color: '#272944',
                              marginLeft: 8
                           }}
                        >
                           {t(GLOBAL_CLIENT.uploadanh)}
                        </div>
                     </div>
                  )}
            </Row>
            {jobDetail?.images?.length > 0 ? (
               <div>
                  {jobDetail?.images?.map((image: any, index: any) => (
                     <div className="gx-mb-3" key={index}>
                        <Row className="gx-flex-row gx-justify-content-start gx-mb-2 gx-mx-1">
                           <Col className=" gx-flex-column gx-mr-4" flex="140px">
                              <Image
                                 preview={{
                                    mask: null
                                 }}
                                 width={140}
                                 height={105}
                                 src={`${process.env.REACT_APP_URL + image.path}`}
                                 alt={image?.description}
                                 style={{ objectFit: 'cover', borderRadius: 4 }}
                              />
                           </Col>
                           <Col
                              flex={1}
                              style={{
                                 maxWidth: 'calc(100% - 164px)'
                              }}
                           >
                              {editedImageId === index ? (
                                 <Input
                                    type="text"
                                    value={editedImage?.description}
                                    onChange={handleDescriptionChange}
                                    maxLength={254}
                                    style={{
                                       fontSize: 14,
                                       fontWeight: 400,
                                       lineHeight: '22px',
                                       color: '#5C5C5C',
                                       paddingTop: 9,
                                       paddingBottom: 9,
                                       border: 'solid 1px #dadada'
                                    }}
                                 />
                              ) : (
                                 <div>
                                    <p
                                       style={{
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          display: '-webkit-box',
                                          WebkitLineClamp: 10,
                                          WebkitBoxOrient: 'vertical',
                                          fontSize: 14,
                                          fontWeight: 400,
                                          lineHeight: '22px',
                                          color: '#5C5C5C',
                                          marginBottom: 8
                                       }}
                                    >
                                       {image.description}
                                    </p>
                                 </div>
                              )}
                              {role === ROLE.CREATOR &&
                                 status !== STATUS_JOB.DOING &&
                                 status !== STATUS_JOB.REVIEW &&
                                 status !== STATUS_JOB.REJECT &&
                                 status !== STATUS_JOB.FINISH && (
                                    <Row
                                       className="gx-flex-row gx-align-items-center"
                                       wrap={false}
                                       style={{ marginLeft: '-8px' }}
                                    >
                                       <Col>
                                          <div
                                             onClick={() => handleImageDelete(index)}
                                             style={{
                                                fontSize: 14,
                                                fontWeight: 400,
                                                lineHeight: '22px',
                                                color: '#272944',
                                                padding: 8,
                                                cursor: 'pointer'
                                             }}
                                          >
                                             {t(GLOBAL_CLIENT.delete)}
                                          </div>
                                       </Col>
                                       <Col className="gx-mr-2">
                                          <div
                                             onClick={
                                                editedImageId === index
                                                   ? () => handleEditSave(index)
                                                   : () => handleEditClick(image, index)
                                             }
                                             style={{
                                                fontSize: 14,
                                                fontWeight: 400,
                                                lineHeight: '22px',
                                                color: '#272944',
                                                padding: 8,
                                                cursor: 'pointer'
                                             }}
                                          >
                                             {editedImageId === index ? t(GLOBAL_CLIENT.save) : t(GLOBAL_CLIENT.edit)}
                                          </div>
                                       </Col>
                                    </Row>
                                 )}
                           </Col>
                        </Row>
                     </div>
                  ))}
               </div>
            ) : (
               <NoData title={t(GLOBAL_CLIENT.khongcoanh)} image={Empty.PRESENTED_IMAGE_DEFAULT} />
            )}
            <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleImagesUpload} multiple />
         </div>
      )
   }
)

export default JobDetailUpload
