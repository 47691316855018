import { Button, Col, Input, Row, Select } from 'antd'
import { isEmpty, trim } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ROLE, STATUS_ACTIVITY, getPage, getTitleFromStatus, timeRangeFilterOptions } from 'src/helpers/constants'
import { useStore } from 'src/hooks'
import { ListJobFilterWrapper, ListJobsWrapper } from 'src/styles/jobs/Jobs.style'
import Filter from '../../Filter/Filter'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import JobCardItem from '../JobCardItem'
import NoData from '../../NoData'
import { observer } from 'mobx-react-lite'
import { EditJobDetailModal } from 'src/screens/job-detail/EditJobDetailCreator'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Request2 } from 'src/common/Request2'
import api from 'src/common/api'

const { Search } = Input

type JobsListProps = {
   status: number
   rows: any
   count: any
   offset: any
}
const JobsList: React.FC<JobsListProps> = observer(({ status, rows, offset, count }) => {
   const { t } = useTranslation()
   const JobsStore = useStore('JobsStore')
   const AuthStore = useStore('AuthStore')
   const JobDetailStore = useStore('JobDetailStore')
   const initFilter = { status: status }
   // const [isLoading, setIsLoading] = useState(false)
   const role = AuthStore.data_role
   const [fieldFilter, setFieldfilter] = useState<any>(initFilter)
   const history = useHistory()
   const [textSearch, set_textSearch] = useState<string>('')
   const [isOpenEditJobDetail, setIsOpenEditJobDetail] = useState(false)
   const [selectedItem, setSelectedItem] = useState<any>([])
   const isMounted = useRef(false)
   const currentPage = useRef<number>(0)
   const jobs = JobsStore.jobs
   const jobsRecruiting = JobsStore.jobsRecruiting
   const jobsProcessing = JobsStore.jobsProcessing
   const jobsReview = JobsStore.jobsReview
   const jobsEditing = JobsStore.jobsEditing
   const jobsDone = JobsStore.jobsDone
   const titleSearch = getTitleFromStatus(status)
   const searchRef = useRef<any>(null)

   const handleSelectItem = (item: any) => {
      setSelectedItem(item)
      JobDetailStore.setJobDetail(item)
      setIsOpenEditJobDetail(true)
   }

   const openEditJobModal = () => {
      setIsOpenEditJobDetail(true)
   }

   const closeEditJobModal = () => {
      setIsOpenEditJobDetail(false)
   }

   const openAddJobModal = async () => {
      await fetchLastJob()
      AuthStore.action_OpenAddJobDetail()
   }

   const fetchLastJob = async () => {
      if (
         jobs?.count ||
         jobsRecruiting?.count ||
         jobsProcessing?.count ||
         jobsEditing?.count ||
         jobsReview?.count ||
         jobsDone?.count
      ) {
         try {
            // setIsLoading(true)
            const request = await Request2.getWithToken(api.job.lastJob)
            // setLastJobPrice(price)
            // JobDetailStore.setJobDetailName(getUniqueCode())
            JobDetailStore.setJobDetailDesignTypeId(request?.designTypeId)
            JobDetailStore.setJobDetailProductTypeId(request?.productTypeId)
            JobDetailStore.setJobDetailDescription(request?.note)
            JobDetailStore.setJobDetailTargetId(request?.targetId)

            // A Vinh mong muốn sửa thành 1 mẫu mỗi khi tạo
            JobDetailStore.setJobDetailCount(1)
            // JobDetailStore.setJobDetailCount(request?.count)
            JobDetailStore.setJobDetailPrice(request?.price)
         } catch (error) {
            console.error(error)
         } finally {
            // setIsLoading(false)
         }
      }
   }

   useEffect(() => {
      if (isMounted.current) {
         getListData({ ...fieldFilter, ...{ text: trim(textSearch) } })
      } else {
         isMounted.current = true
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [textSearch])

   useEffect(() => {
      let filter = { ...fieldFilter }
      if (isCreator()) {
         filter = { ...fieldFilter, sort: 'createdAt,DESC' }
      } else {
         if (isDesigner() && status === STATUS_ACTIVITY.FINISH) {
            filter = { ...fieldFilter, sort: 'createdAt,DESC' }
         } else {
            filter = { ...fieldFilter, sort: 'createdAt,ASC' }
         }
      }

      setFieldfilter(filter)
      getListData({ ...filter, ...{ text: trim(textSearch) } })
      JobsStore.action_setFieldFilter({ ...filter, ...{ text: trim(textSearch) } })
   }, [])

   const onSubmitFilter = (value: any) => {
      currentPage.current = 0
      setFieldfilter({ ...fieldFilter, ...value })
      getListData({ ...fieldFilter, ...{ text: trim(textSearch) }, ...value })
      JobsStore.action_setFieldFilter({ ...fieldFilter, ...{ text: trim(textSearch) }, ...value })
   }

   const handleRefreshJobs = () => {
      currentPage.current = 0
      getListData({ ...fieldFilter, ...{ text: trim(textSearch) } })
   }

   const handleFetchJobs = () => {
      getListData({ ...fieldFilter, ...{ text: trim(textSearch) } }, currentPage.current + 1)
      currentPage.current = currentPage.current + 1
   }

   const onResetFilter = () => {
      let filter = {}
      if (isCreator()) {
         filter = { sort: 'createdAt,DESC' }
      } else {
         if (isDesigner() && status === STATUS_ACTIVITY.FINISH) {
            filter = { sort: 'createdAt,DESC' }
         } else {
            filter = { sort: 'createdAt,ASC' }
         }
      }
      setFieldfilter({ ...initFilter, ...filter })
      JobsStore.action_setFieldFilter({ ...initFilter, ...filter })
      // getListData(page, { ...{ text: trim(textSearch) } })
   }

   const getListData = async (param?: any, pageNumber: number = 0) => {
      try {
         // if (pageNumber === 0) setIsLoading(true)
         const offset = getPage(pageNumber)
         const params = { offset, ...param }
         await JobsStore.getJobList(params, history)
      } catch (error) {
         // console.log(error)
      } finally {
         // if (pageNumber === 0) setIsLoading(false)
      }
   }

   const handleChangeTextSearch = (event: any) => {
      set_textSearch(event.target?.value.trim() || '')
   }

   // const onChange = (currentPage: number) => {
   //    getListData(currentPage - 1, { ...fieldFilter, ...{ text: trim(textSearch) } })
   // }

   // const renderPagination = () => {
   //    if (isEmpty(rows)) {
   //       return null
   //    }

   //    return (
   //       <div className="containerPagination">
   //          <Pagination
   //             onChange={onChange}
   //             pageSize={PAGE_SIZE}
   //             current={offset / PAGE_SIZE + 1}
   //             showSizeChanger={false}
   //             total={count}
   //          />
   //       </div>
   //    )
   // }

   const handleChangeTimerangeOptions = (value: string) => {
      currentPage.current = 0
      setFieldfilter({ ...fieldFilter, sort: value })
      getListData({ ...fieldFilter, ...{ text: trim(textSearch) }, sort: value })
      JobsStore.action_setFieldFilter({ ...fieldFilter, ...{ text: trim(textSearch) }, sort: value })
   }

   const isCreator = () => {
      if (isEmpty(role)) {
         AuthStore.action_getRole(history)
      }
      if (role === ROLE.CREATOR) return true
      return false
   }
   const isDesigner = () => {
      if (isEmpty(role)) {
         AuthStore.action_getRole(history)
      }
      if (role === ROLE.DESIGNER) return true
      return false
   }

   const onClickSearch = () => {
      set_textSearch(searchRef.current.input.value?.trim())
   }

   return (
      <>
         <ListJobsWrapper className="gx-container m-container">
            {/* <Loading isLoading={isLoading} /> */}
            <ListJobFilterWrapper>
               <div className="filter-options__wrapper--left">
                  <Input
                     placeholder={`${t(GLOBAL_CLIENT.txtSearch)} ${titleSearch}`}
                     className="jobs__search-input"
                     ref={searchRef}
                     prefix={<SearchOutlined style={{ fontSize: '17px' }} onClick={onClickSearch} />}
                     onPressEnter={handleChangeTextSearch}
                  />
                  <Filter onSubmitFilter={onSubmitFilter} onResetFilter={onResetFilter} />
               </div>
               <div className="filter-options__wrapper--right">
                  <div className="jobs_time-range__wrapper">
                     {/* <p className="time-range__title">Sắp xếp theo:&nbsp;</p> */}
                     <Select
                        className="jobs__time-range"
                        value={fieldFilter?.sort}
                        placeholder="Sắp xếp theo"
                        options={timeRangeFilterOptions}
                        onChange={handleChangeTimerangeOptions}
                     />
                  </div>
                  {isCreator() && (
                     <div className="btn-creator__wrapper">
                        <Button className="ant-btn" onClick={openAddJobModal} icon={<PlusOutlined />}>
                           Tạo mới
                        </Button>
                     </div>
                  )}
               </div>
            </ListJobFilterWrapper>
            {rows?.length > 0 ? (
               // <Row className="list-jobs__body" style={{ marginTop: '16px' }}>
               //    <Col span={24}>
               //       <Row>
               //          {rows?.map((item: any) => {
               //             return (
               //                <Col xl={6} md={8} sm={12} xs={24} key={item?.id}>
               //                   <JobCardItem
               //                      grid={true}
               //                      data={item}
               //                      jobsStatus={isCreator() && status === 0 ? 0 : status + 1}
               //                      handleRefreshJobs={handleRefreshJobs}
               //                      handleShowDetail={() => handleSelectItem(item)}
               //                   />
               //                </Col>
               //             )
               //          })}
               //       </Row>
               //       {renderPagination()}
               //    </Col>
               // </Row>
               <InfiniteScroll
                  dataLength={rows?.length}
                  next={handleFetchJobs}
                  hasMore={true}
                  loader={<></>}
                  style={{ overflow: 'hidden' }}
               >
                  <Row className="list-jobs__body" style={{ marginTop: '16px' }}>
                     <Col span={24}>
                        <Row>
                           {rows?.map((item: any, index: any) => {
                              return (
                                 <Col xl={6} md={8} sm={12} xs={24} key={item?.id}>
                                    <JobCardItem
                                       grid={true}
                                       data={item}
                                       jobsStatus={isCreator() && status === 0 ? 0 : status + 1}
                                       handleRefreshJobs={handleRefreshJobs}
                                       handleShowDetail={() => handleSelectItem(item)}
                                    />
                                 </Col>
                              )
                           })}
                        </Row>
                     </Col>
                  </Row>
               </InfiniteScroll>
            ) : (
               <NoData />
            )}
            <EditJobDetailModal
               data={selectedItem}
               visible={isOpenEditJobDetail}
               handleOk={openEditJobModal}
               handleCancel={closeEditJobModal}
               handleRefreshJobs={handleRefreshJobs}
            />
         </ListJobsWrapper>
      </>
   )
})

export default JobsList
