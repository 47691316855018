import { Input, Row, Tag, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Images from 'src/common/Images'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { STATUS_JOB } from 'src/helpers/constants'
import { useStore } from 'src/hooks'

const JobDetailTagList: React.FC = () => {
   const { t } = useTranslation()

   const [inputVisible, setInputVisible] = useState(false)
   // const [tags, JobDetailStore.setJobDetailTag] = useState<string[]>([])
   const [tagString, setTagString] = useState<string>('')
   const [inputValue, setInputValue] = useState('')
   const [editInputIndex, setEditInputIndex] = useState(-1)
   const [editInputValue, setEditInputValue] = useState('')
   const inputRef = useRef<any>(null)
   const editInputRef = useRef<any>(null)
   const JobDetailStore = useStore('JobDetailStore')
   const { jobDetail } = JobDetailStore
   const status = jobDetail?.status
   const tags = jobDetail?.tag ? jobDetail?.tag?.split(', ') : []

   useEffect(() => {
      if (inputVisible) {
         inputRef.current?.focus()
      }
   }, [inputVisible])

   useEffect(() => {
      editInputRef.current?.focus()
   }, [inputValue])

   const handleClose = (removedTag: string) => {
      const newTags = tags.filter((tag) => tag !== removedTag)
      JobDetailStore.setJobDetailTag(newTags)
   }

   const showInput = () => {
      setInputVisible(true)
   }

   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value)
   }

   const handleInputConfirm = () => {
      if (inputValue.trim() && tags.indexOf(inputValue) === -1) {
         const newTags = [...tags, inputValue]
         JobDetailStore.setJobDetailTag(newTags)
      }
      setInputVisible(false)
      setInputValue('')
   }

   const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEditInputValue(e.target.value)
   }

   const handleEditInputConfirm = () => {
      const newTags = [...tags]
      newTags[editInputIndex] = editInputValue
      JobDetailStore.setJobDetailTag(newTags)
      setEditInputIndex(-1)
      setInputValue('')
   }

   return (
      <>
         <Row className="gx-flex-row gx-align-items-center gx-justify-content-between gx-mb-2 gx-mx-1" wrap={false}>
            <h4 style={{ fontSize: 14, fontWeight: 600, lineHeight: '22px', color: '#272944' }}>
               {t(GLOBAL_CLIENT.tagTitle)}
            </h4>
            {!inputVisible &&
               status !== STATUS_JOB.DOING &&
               status !== STATUS_JOB.REVIEW &&
               status !== STATUS_JOB.REJECT &&
               status !== STATUS_JOB.FINISH && (
                  <div className="gx-module-logo gx-mb-2" onClick={showInput}>
                     <img style={{ color: '#272944', marginLeft: 4, height: 20 }} src={Images.ic_plus} alt="icon" />
                     <div
                        style={{ fontSize: 14, fontWeight: 400, lineHeight: '22px', color: '#272944', marginLeft: 8 }}
                     >
                        {t(GLOBAL_CLIENT.themtag)}
                     </div>
                  </div>
               )}
         </Row>
         <Row className="gx-flex-row gx-align-items-center gx-justify-content-start gx-mb-2 gx-mx-1" wrap={false}>
            {tags?.map((tag, index) => {
               if (editInputIndex === index) {
                  return (
                     <Input
                        ref={editInputRef}
                        key={index}
                        size="small"
                        maxLength={100}
                        style={{ width: 100, fontSize: 12, marginBottom: 8 }}
                        className="tag-input"
                        value={editInputValue}
                        onChange={handleEditInputChange}
                        onBlur={handleEditInputConfirm}
                        onPressEnter={handleEditInputConfirm}
                     />
                  )
               }

               const isLongTag = tag?.length > 20

               const tagElem = (
                  <Tag
                     className="edit-tag"
                     key={tag}
                     closable={false}
                     style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#F1F1F1',
                        borderRadius: 4,
                        border: 'none',
                        paddingTop: 1,
                        paddingBottom: 1
                     }}
                  >
                     <span
                        onDoubleClick={(e) => {
                           if (index !== 0) {
                              setEditInputIndex(index)
                              setEditInputValue(tag)
                              e.preventDefault()
                           }
                        }}
                        style={{ fontSize: 14, fontWeight: 400, lineHeight: '22px', color: '#5c5c5c' }}
                     >
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                     </span>
                     <img
                        onClick={() => handleClose(tag)}
                        style={{ color: '#272944', marginLeft: 4, height: 16 }}
                        src={Images.ic_close_circle}
                        alt="icon"
                     />
                  </Tag>
               )
               return isLongTag ? (
                  <Tooltip title={tag} key={tag}>
                     {tagElem}
                  </Tooltip>
               ) : (
                  tagElem
               )
            })}
            {inputVisible && (
               <Input
                  ref={inputRef}
                  type="text"
                  size="small"
                  maxLength={100}
                  className="tag-input"
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                  style={{ width: 100, fontSize: 12, marginBottom: 8 }}
               />
            )}
            {!inputVisible &&
               status !== STATUS_JOB.DOING &&
               status !== STATUS_JOB.REVIEW &&
               status !== STATUS_JOB.REJECT &&
               status !== STATUS_JOB.FINISH && (
                  <Tag
                     className="site-tag-plus"
                     onClick={showInput}
                     style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#F1F1F1',
                        borderRadius: 4,
                        border: 'none',
                        paddingTop: 1,
                        paddingBottom: 1
                     }}
                  >
                     <img style={{ color: '#272944', marginRight: 4, height: 20 }} src={Images.ic_plus} alt="icon" />{' '}
                     <span style={{ fontSize: 14, fontWeight: 400, lineHeight: '22px', color: '#5c5c5c' }}>
                        {t(GLOBAL_CLIENT.newTag)}
                     </span>
                  </Tag>
               )}
         </Row>
      </>
   )
}

export default JobDetailTagList
