import { isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Loading } from 'src/common'
import { ERROR_CODES } from 'src/helpers/constants'
import { showMessageError, showMessageSuccess } from 'src/helpers/functions'
import { useStore } from 'src/hooks'

const VerifyEmailScreen = () => {
   const { search } = useLocation()
   const searchParams = useMemo(() => new URLSearchParams(search), [search])
   const AuthStore = useStore('AuthStore')
   const history = useHistory()

   const [isLoading, setIsLoading] = useState(false)
   const sendVerifyEmail = async () => {
      try {
         setIsLoading(true)
         if (isEmpty(searchParams.get('token'))) {
            history.push('/')
            return
         }
         const result = await AuthStore.action_verifyEmail(searchParams.get('token'))
         if (result.code === ERROR_CODES.SUCCESS) {
            showMessageSuccess(result?.message, () => history.push('/signin'))
         } else {
            showMessageError(result?.message, () => history.push('/signin'))
         }
      } catch (e) {
         // console.log(e)
      } finally {
         setIsLoading(false)
      }
   }
   useEffect(() => {
      sendVerifyEmail()
   }, [])

   return <Loading isLoading={isLoading} />
}

export default VerifyEmailScreen
