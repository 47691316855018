import icon_login from '../assets/images/icon_login.png'
import logo_white from '../assets/images/logo_white.png'
import bg_login from '../assets/images/bg-login.png'
import icon_avatar from '../assets/images/icon_avatar.png'
import no_data from '../assets/images/no_data.png'
import image_not_found from '../assets/images/image_not_found.png'
import ic_close_circle from '../assets/images/ic_close_circle.png'
import ic_clock from '../assets/images/ic_clock.png'
import ic_plus from '../assets/images/ic_plus.png'
import ic_edit from '../assets/images/ic_edit.png'
import ic_caret_down from '../assets/images/ic_caret_down.png'
import ic_caret_up from '../assets/images/ic_caret_up.png'
import ic_upload from '../assets/images/ic_upload.png'
import ic_camera from '../assets/images/ic_camera.png'
import ic_filter from '../assets/images/filter-icon.svg'
import ic_user from '../assets/images/user.svg'
import ic_history from '../assets/images/history.svg'
import ic_chart from '../assets/images/chart-bar.svg'
import ic_ticket from '../assets/images/ticket.svg'
import logout from '../assets/images/logout.png'
import chat_support from '../assets/images/chat-support.png'
import ic_copy from '../assets/images/ic_copy.png'
import ic_bell from '../assets/images/ic_bell.png'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
   chat_support,
   logout,
   ic_ticket,
   ic_chart,
   ic_history,
   ic_user,
   icon_login,
   logo_white,
   bg_login,
   icon_avatar,
   no_data,
   image_not_found,
   ic_close_circle,
   ic_clock,
   ic_plus,
   ic_edit,
   ic_caret_down,
   ic_caret_up,
   ic_upload,
   ic_camera,
   ic_filter,
   ic_copy,
   ic_bell
}
