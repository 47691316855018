import { Button, Divider, Input, Popover } from 'antd'
import Select, { StylesConfig } from 'react-select'
import React, { useEffect, useRef, useState } from 'react'
import { FilterContentWrapper } from 'src/styles/jobs/Jobs.style'
import JobFilterOption from '../jobs/JobFilterOption'
import { observer } from 'mobx-react-lite'
import { useStore } from 'src/hooks'
import styled from 'styled-components'
import { GLOBAL_CLIENT } from 'src/helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import Images from 'src/common/Images'
import BaseButton from 'src/common/components/button'
import { ROLE } from 'src/helpers/constants'
import Checkbox from 'src/common/components/checkbox/Checkbox'

export const PriceRangeWrapper = styled.div`
   margin-top: 12px;
   p {
      margin: unset;
   }
   .price__title {
      font-weight: bold;
      margin-bottom: 12px;
   }
   .price-filter__wrapper {
      display: grid;
      gap: 8px;
      grid-template-columns: repeat(2, 1fr);
      .price__from,
      .price__to {
         display: flex;
         gap: 8px;
         p {
            margin-top: auto;
            margin-bottom: auto;
            width: max-content;
         }
         .ant-input {
            flex: 1;
         }
      }
   }
`

type FilterProps = {
   onSubmitFilter: (value: any) => void
   onResetFilter: () => void
}

const Filter: React.FC<FilterProps> = observer(({ onSubmitFilter, onResetFilter }) => {
   const CommonConfigStore = useStore('CommonConfigStore')
   const AuthStore = useStore('AuthStore')
   const UserStore = useStore('UserStore')
   const { t } = useTranslation()
   const selectInputRef = useRef<any>()
   const jobFilterRef = useRef<any>([])
   const minRef = useRef<any>(null)
   const maxRef = useRef<any>(null)
   const [openFilterPopover, setOpenFilterPopover] = useState(false)
   const [isDefaultFilter, setIsDefaultFilter] = useState<boolean>(false)
   const { productTypesList, designTypesList } = CommonConfigStore
   const { data_getUserInfo } = AuthStore
   const { listCreator, listDesigner } = UserStore
   const isDefaultCount = useRef(true)

   const colourStyles: StylesConfig<true> = {
      control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
      option: (styles: any, { isDisabled, isFocused, isSelected }) => {
         return {
            ...styles,
            backgroundColor: isDisabled
               ? undefined
               : isSelected
               ? 'rgba(54,179,126,0.3);'
               : isFocused
               ? 'rgba(54,179,126,0.1);'
               : undefined,
            color: 'default',
            cursor: 'default',

            ':active': {
               ...styles[':active'],
               backgroundColor: 'rgba(54,179,126,0.3);'
            }
         }
      },
      multiValue: (styles: any) => {
         return {
            ...styles,
            backgroundColor: 'rgba(54,179,126,0.1)'
         }
      },
      multiValueLabel: (styles: any) => ({
         ...styles,
         color: '#36B37E'
      }),
      multiValueRemove: (styles: any) => ({
         ...styles,
         color: 'green',
         ':hover': {
            ...styles[':active'],
            backgroundColor: '#36B37E'
         }
      })
   }
   const [fieldFilter, setFieldFilter] = useState<any>({})
   const productQuantityList = [
      { id: 1, name: '1 mẫu' },
      { id: 2, name: '2 mẫu' }
   ]
   const isPriority = [{ id: 1, name: t(GLOBAL_CLIENT.priorityJob) }]

   useEffect(() => {
      // console.log('is default', fieldFilter.designTypeIds?.split(',')?.length, designTypesList?.length)
      if (
         !isEmpty(fieldFilter.productTypeIds) &&
         fieldFilter?.productTypeIds?.split(',')?.length <= productTypesList?.length
      ) {
         setIsDefaultFilter(false)
         return
      }
      if (
         !isEmpty(fieldFilter.designTypeIds) &&
         fieldFilter?.designTypeIds?.split(',')?.length <= designTypesList?.length
      ) {
         setIsDefaultFilter(false)
         return
      }
      if (
         (!isEmpty(fieldFilter.count) && fieldFilter?.count?.split(',')?.length <= productQuantityList?.length) ||
         !isDefaultCount.current
      ) {
         setIsDefaultFilter(false)
         return
      }
      if (!isEmpty(fieldFilter.isPriority) && fieldFilter?.isPriority?.split(',')?.length <= isPriority?.length) {
         setIsDefaultFilter(false)
         return
      }
      if (
         !isEmpty(fieldFilter.creatorIds) &&
         fieldFilter?.creatorIds?.split(',')?.length <= listCreator?.rows?.length
      ) {
         setIsDefaultFilter(false)
         return
      }
      if (
         !isEmpty(fieldFilter.designerIds) &&
         fieldFilter?.designerIds?.split(',')?.length <= listDesigner?.rows?.length
      ) {
         setIsDefaultFilter(false)
         return
      }
      setIsDefaultFilter(true)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [fieldFilter])

   const handleOpenFilterPopover = (newOpen: boolean) => {
      setOpenFilterPopover(newOpen)
   }

   const handleRestoreDefaultFilter = () => {
      jobFilterRef.current.forEach((filter: any) => {
         filter.resetFilter()
      })
      selectInputRef.current?.clearValue()
      setFieldFilter({})
      onResetFilter()
   }
   const handleApplyFilterPopover = () => {
      onSubmitFilter(fieldFilter)
      setOpenFilterPopover(false)
   }

   const handleChangeDesignType = (value: any) => {
      setFieldFilter({ ...fieldFilter, designTypeIds: value?.join(',') })
   }
   const handleChangeProductType = (value: any) => {
      setFieldFilter({ ...fieldFilter, productTypeIds: value?.join(',') })
   }
   const handleChangeProductQuantity = (value: any) => {
      if (value?.length === 2) isDefaultCount.current = false
      else isDefaultCount.current = true
      setFieldFilter({ ...fieldFilter, count: value?.length === 2 ? '' : value?.join(',') })
   }
   const handleChangeCreatorList = (value: any) => {
      setFieldFilter({ ...fieldFilter, creatorIds: value?.map((item: any) => item?.id)?.join(',') })
   }
   const handleChangeDesignerList = (value: any) => {
      setFieldFilter({ ...fieldFilter, designerIds: value?.map((item: any) => item?.id)?.join(',') })
   }
   const handleChangePriority = (value: any) => {
      console.log('handleChangePriority', value)
      setFieldFilter({ ...fieldFilter, isPriority: value?.join(',') })
   }
   const handleChangeMin = (e: any) => {
      setFieldFilter({ ...fieldFilter, min: e.target?.value })
   }
   const handleChangeMax = (e: any) => {
      setFieldFilter({ ...fieldFilter, max: e.target?.value })
   }

   const filterContent = () => {
      return (
         <FilterContentWrapper>
            <div className="job-filter__content-wrapper">
               <div className="job-filter__wrapper">
                  <JobFilterOption
                     data={designTypesList}
                     title={t(GLOBAL_CLIENT.designType)}
                     onChange={handleChangeDesignType}
                     ref={(el: any) => (jobFilterRef.current[0] = el)}
                  />
                  <JobFilterOption
                     data={productTypesList}
                     title={t(GLOBAL_CLIENT.productType)}
                     onChange={handleChangeProductType}
                     ref={(el: any) => (jobFilterRef.current[1] = el)}
                  />
                  <JobFilterOption
                     data={productQuantityList}
                     title={t(GLOBAL_CLIENT.numberItems)}
                     onChange={handleChangeProductQuantity}
                     ref={(el: any) => (jobFilterRef.current[2] = el)}
                  ></JobFilterOption>
                  {/* {priceRange()} */}
                  {/* <JobFilterOption
                     data={levelDesignerList}
                     title={t(GLOBAL_CLIENT.lvDesign)}
                     onChange={handleChangeLevelDesign}
                     ref={(el: any) => (jobFilterRef.current[3] = el)}
                  /> */}
                  {/* <JobFilterOption
                     data={creatorList}
                     title={t(GLOBAL_CLIENT.creator)}
                     onChange={handleChangeCreatorList}
                     ref={(el: any) => (jobFilterRef.current[4] = el)}
                  /> */}
                  {(data_getUserInfo?.role === ROLE.ADMIN || data_getUserInfo?.role === ROLE.SUPPORTER) && (
                     <PriceRangeWrapper>
                        <p className="price__title">{t(GLOBAL_CLIENT.creator)}</p>
                        <Select
                           ref={selectInputRef}
                           isMulti
                           name="creatorIds"
                           options={listCreator?.rows}
                           onChange={handleChangeCreatorList}
                           getOptionLabel={(option: any) => {
                              return isEmpty(option.name) ? option?.email : option?.name
                           }}
                           getOptionValue={(option: any) => String(option?.id)}
                           className="basic-multi-select"
                           styles={colourStyles}
                           classNamePrefix="select"
                           menuPlacement="top"
                        />
                        <Divider />
                     </PriceRangeWrapper>
                  )}
                  {(data_getUserInfo?.role === ROLE.ADMIN || data_getUserInfo?.role === ROLE.SUPPORTER) && (
                     <PriceRangeWrapper>
                        <p className="price__title">{t(GLOBAL_CLIENT.designer)}</p>
                        <Select
                           ref={selectInputRef}
                           isMulti
                           name="designerIds"
                           options={listDesigner?.rows}
                           onChange={handleChangeDesignerList}
                           getOptionLabel={(option: any) => {
                              return isEmpty(option.name) ? option?.email : option?.name
                           }}
                           getOptionValue={(option: any) => String(option?.id)}
                           className="basic-multi-select"
                           styles={colourStyles}
                           classNamePrefix="select"
                           menuPlacement="top"
                        />
                        <Divider />
                     </PriceRangeWrapper>
                  )}
                  {(data_getUserInfo?.role === ROLE.ADMIN ||
                     data_getUserInfo?.role === ROLE.SUPPORTER ||
                     data_getUserInfo?.role === ROLE.DESIGNER) && (
                     <JobFilterOption
                        data={isPriority}
                        title={t(GLOBAL_CLIENT.priorityJob)}
                        onChange={handleChangePriority}
                        ref={(el: any) => (jobFilterRef.current[3] = el)}
                        isHideTitle
                     ></JobFilterOption>
                  )}
               </div>
            </div>
            <div className="filter-group__btn-group">
               <BaseButton
                  size="lg"
                  type="secondary"
                  className="filter-group__reset-btn"
                  onClick={handleRestoreDefaultFilter}
               >
                  {t(GLOBAL_CLIENT.default)}
               </BaseButton>
               <BaseButton
                  size="lg"
                  type="primary"
                  className="filter-group__apply-btn"
                  onClick={handleApplyFilterPopover}
               >
                  {t(GLOBAL_CLIENT.apply)}
               </BaseButton>
            </div>
         </FilterContentWrapper>
      )
   }

   return (
      <Popover
         placement="bottomRight"
         visible={openFilterPopover}
         onVisibleChange={handleOpenFilterPopover}
         className="filter-options__popover"
         content={filterContent}
         overlayClassName="filter-overlay__wrapper"
         trigger="click"
      >
         <Button
            icon={<img src={Images.ic_filter} alt="" style={{ width: '16px', height: '16px' }} />}
            style={{ borderRadius: '8px', width: '40px', height: '40px', border: 'none' }}
            className={isDefaultFilter ? '' : 'active'}
         />
      </Popover>
   )
})

export default Filter
