// import { DatePicker } from "antd";
import { observer } from "mobx-react-lite";

// const { RangePicker } = DatePicker;

const DatePickerCustom = observer((props: any) => {
  // const { t } = useTranslation();
  // const { type, format } = props;

  // const locale = {
  //   lang: {
  //     locale: "vi_VN",
  //     placeholder: t(GLOBAL_CLIENT.chonngay),
  //     rangePlaceholder: [
  //       t(GLOBAL_CLIENT.thoigianbatdau),
  //       t(GLOBAL_CLIENT.thoigianketthuc),
  //     ],
  //     today: "Today",
  //     now: "Now",
  //     backToToday: "Back to today",
  //     ok: "Ok",
  //     clear: "Clear",
  //     month: "Month",
  //     year: "Year",
  //     timeSelect: "Select time",
  //     dateSelect: "Select date",
  //     monthSelect: "Choose a month",
  //     yearSelect: "Choose a year",
  //     decadeSelect: "Choose a decade",
  //     yearFormat: "YYYY",
  //     dateFormat: "M/D/YYYY",
  //     dayFormat: "D",
  //     dateTimeFormat: "M/D/YYYY HH:mm:ss",
  //     monthFormat: "MMMM",
  //     monthBeforeYear: true,
  //     previousMonth: "Previous month (PageUp)",
  //     nextMonth: "Next month (PageDown)",
  //     previousYear: "Last year (Control + left)",
  //     nextYear: "Next year (Control + right)",
  //     previousDecade: "Last decade",
  //     nextDecade: "Next decade",
  //     previousCentury: "Last century",
  //     nextCentury: "Next century",
  //   },
  //   timePickerLocale: {
  //     placeholder: "Select time",
  //   },
  //   dateFormat: "DD-MM-YYYY",
  //   dateTimeFormat: "DD-MM-YYYY HH:mm:ss",
  //   weekFormat: "YYYY-wo",
  //   monthFormat: "YYYY-MM",
  // };

  // if (type === TYPE_DATE_PICKER.RANGE) {
  //     return (
  //         // <RangePicker
  //         //     {...props}
  //         //     format={format || DATE_FORMAT_CLIENT}
  //         //     locale={locale}
  //         // />
  //     );
  // }

  return (
    // <DatePicker
    //     {...props}
    //     format={format || DATE_FORMAT_CLIENT}
    //     locale={locale}
    // />
    <div />
  );
});

export { DatePickerCustom };
