// import "antd/dist/antd.css";
import './assets/vendors/style' // css
import './App.css'
import './index.css'
import './helpers/i18n'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { StoresProvider, stores } from './stores'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from 'styled-components'
import theme from './helpers/theme'

ReactDOM.render(
   <React.StrictMode>
      <StoresProvider value={stores}>
         <ThemeProvider theme={theme}>
            <BrowserRouter>
               <App />
            </BrowserRouter>
         </ThemeProvider>
      </StoresProvider>
   </React.StrictMode>,
   document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
