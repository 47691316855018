import { Modal, Divider } from 'antd'
import { ModalFuncProps } from 'antd/lib/modal'
import { ModalFunc } from 'antd/lib/modal/confirm'
import React from 'react'
import Images from 'src/common/Images'
import styled, { ThemeProvider } from 'styled-components'
import BaseButton from 'src/common/components/button'
import theme from '../theme'
type ModalProps = {
   title?: string
} & ModalFuncProps &
   ModalFunc

const ContentWrapper = styled.div`
   .content__wrapper {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;

      color: ${(props) => props.theme.color.grey_1};
   }
   .confirm-modal__btn-group {
      margin-top: 16px;
      display: flex;
      justify-content: end;
      gap: 16px;
   }
`

const HeaderWrapper = styled.div`
   .header__close-icon {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
   }
   .header__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      padding-right: 32px;
      color: #272944;
   }
   .ant-divider {
      border-top: 1px solid #dadada;
      margin: 16px 0;
   }
`

const useModal = () => {
   const header = (title: any) => {
      const handleCloseModal = () => {
         Modal.destroyAll()
      }

      return (
         <HeaderWrapper>
            <div className="header__title">{title}</div>
            <div className="header__close-icon" onClick={handleCloseModal}>
               <img src={Images.ic_close_circle} alt="close-icon" />
            </div>
            <Divider />
         </HeaderWrapper>
      )
   }

   const body = (content: any, okText: any, cancelText: any, onCancel: any, onOk: any) => {
      const handleClickCancel = () => {
         onCancel()
         Modal.destroyAll()
      }

      const handleClickOk = () => {
         onOk()
         Modal.destroyAll()
      }
      return (
         <ThemeProvider theme={theme}>
            <ContentWrapper>
               <div className="content__wrapper" dangerouslySetInnerHTML={{ __html: content }}></div>
               <div className="confirm-modal__btn-group">
                  <BaseButton type="secondary" width="98px" onClick={handleClickCancel}>
                     {cancelText}
                  </BaseButton>
                  <BaseButton width="98px" onClick={handleClickOk}>
                     {okText}
                  </BaseButton>
               </div>
            </ContentWrapper>
         </ThemeProvider>
      )
   }

   const confirm: ModalProps = ({ title, content, okText, cancelText, onCancel, onOk, ...rest }) => {
      return Modal.confirm({
         ...rest,
         title: header(title),
         content: body(content, okText, cancelText, onCancel, onOk),
         className: 'modal__padding-16 custom-confirm__modal'
      })
   }

   return { confirm }
}

export default useModal
