import { Drawer } from "antd";
import SidebarContent from "./SidebarContent";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../hooks";

const Sidebar = observer((props: any) => {
  const AuthStore = useStore("AuthStore");

  const handleCloseSidebar = () => {
    AuthStore.action_closeSidebar();
  };

  return (
    <Drawer
      className={`gx-drawer-sidebar drawer-container`}
      placement="left"
      closable={false}
      onClose={handleCloseSidebar}
      visible={AuthStore.navCollapsed}
    >
      <SidebarContent {...props} sidebarCollapsed={true} />
    </Drawer>
  );
});
export default Sidebar;
