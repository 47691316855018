import { observer } from 'mobx-react-lite'
import JobsList from 'src/common/components/jobs/jobs-list'
import { STATUS_ACTIVITY } from 'src/helpers/constants'
import { useStore } from 'src/hooks'

const JobsEditing = observer((props: any) => {
   const JobsStore = useStore('JobsStore')
   const { rows, count, offset } = JobsStore.jobsEditing

   return <JobsList status={STATUS_ACTIVITY.REJECT} rows={rows} count={count} offset={offset}/>
})

export default JobsEditing
