import { useEffect, useState } from 'react'
import { Table, Space, Col, Row, DatePicker, Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../hooks'
import { DATE_FORMAT_CLIENT, DATE_FORMAT_CLIENT_PARAMS, FILTER_TIME } from '../../../helpers/constants'
import { GLOBAL_CLIENT } from '../../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
// import Create from './Create'
import useWindowDimensions from '../../../common/useWindowDimensions'
// import Detail from './Detail'
import useModal from 'src/helpers/modal/useModal'
import moment, { Moment } from 'moment'
import 'moment/locale/vi'
import locale from 'antd/es/date-picker/locale/vi_VN'
import { map } from 'lodash'
import { formatCurrency } from 'src/helpers/functions'
import Chart from './Chart'

const BusinessResultsManagement = observer(() => {
   const { t } = useTranslation()
   const DashboardStore = useStore('DashboardStore')
   const [textSearch, set_textSearch] = useState('')
   const { height } = useWindowDimensions()

   const [sortDateDesc, setSortDateDesc] = useState(true)
   const [visibleDetail, setVisibleDetail] = useState(false)
   const [startTime, setStartTime] = useState(FILTER_TIME[1].startTime)
   const [endTime, setEndTime] = useState(FILTER_TIME[1].endTime)
   const [item, setItem] = useState(null)
   const { confirm } = useModal()
   const [typeFilter, setTypeFilter] = useState(FILTER_TIME[1].type)

   const data = DashboardStore.listRevenue
   useEffect(() => {
      handle_getListData(startTime, endTime)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [textSearch, startTime, endTime])

   const handle_getListData = async (from: Moment, to: Moment) => {
      await DashboardStore.getRevenue({
         from: moment(from).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         to: moment(to).format(DATE_FORMAT_CLIENT_PARAMS).toString()
      })
   }

   const showDetail = (value: any) => {
      setItem(JSON.parse(JSON.stringify(value)))
      // console.log('showDetail; ', JSON.parse(JSON.stringify(value)))

      setTimeout(() => {
         setVisibleDetail(true)
      }, 500)
   }

   const handleEdit = () => {
      setVisibleDetail(false)
      setItem(null)
   }

   const hideDetail = () => {
      setVisibleDetail(false)
      setItem(null)
   }

   let columns = [
      {
         title: '',
         dataIndex: 'key',
         key: 'key',
         align: 'center' as 'center',
         width: 95,
         fixed: 'left',
         render: (value: any, record: any) => {
            return (
               <span onClick={() => showDetail(record)} className="txtMax2Line gx-link">
                  {value === 'revert' ? 'Doanh thu' : value === 'expense' ? 'Chi phí' : 'Lợi nhuận gộp'}
               </span>
            )
         }
      },
      {
         title: t(GLOBAL_CLIENT.total),
         dataIndex: 'total',
         key: 'total',
         align: 'center' as 'center',
         width: 140,
         fixed: 'left',
         render: (value: any) => {
            return <span className="txtMax2Line">{`${formatCurrency(value, true)} đ`}</span>
         }
         // fixed: 'left',
         //  render: (value: string, record: any, index: number) =>
         //      (page-1) * PAGE_SIZE + index + 1,
      }
   ]

   if (data[0]) {
      //@ts-ignore
      map(data[0]?.day, (customer: any, index: number) => {
         //@ts-ignore
         columns.push({
            title: moment(customer.day, 'DD/MM/YYYY').format('DD/MM'),
            dataIndex: customer?.day,
            width: 110,

            key: customer?.day,

            render: (value: any, object: any) => {
               // console.log('object', JSON.parse(JSON.stringify(object)), 'value', JSON.parse(JSON.stringify(value)))
               return (
                  <span className="txtMax2Line">{`${
                     object[`${customer?.day}`].key === 'revert'
                        ? formatCurrency(object[`${customer?.day}`].revenue || 0, true)
                        : object.key === 'expense'
                        ? formatCurrency(object[`${customer?.day}`].expense || 0, true)
                        : formatCurrency(object[`${customer?.day}`].profit || 0, true)
                  }`}</span>
               )
            }
         })
      })
   }
   const handleRangePickerChange = (date: any) => {
      setStartTime(date[0])
      setEndTime(date[1])
   }

   function onChangeTable(pagination: any, filters: any, sorter: any, extra: any) {
      // console.log('params', pagination, filters, sorter, extra)
      if (sorter.order === 'ascend') {
         setSortDateDesc(false)
      } else {
         setSortDateDesc(true)
      }
   }

   const handleChangeSelect = (value: string) => {
      console.log('value', value)
      setTypeFilter(value)
      switch (value) {
         case FILTER_TIME[0].type:
            setStartTime(FILTER_TIME[0].startTime)
            setEndTime(FILTER_TIME[0].endTime)
            break
         case FILTER_TIME[1].type:
            setStartTime(FILTER_TIME[1].startTime)
            setEndTime(FILTER_TIME[1].endTime)
            break
         case FILTER_TIME[2].type:
            setStartTime(FILTER_TIME[2].startTime)
            setEndTime(FILTER_TIME[2].endTime)
            break
         case FILTER_TIME[3].type:
            setStartTime(FILTER_TIME[3].startTime)
            setEndTime(FILTER_TIME[3].endTime)
            break

         default:
            setStartTime(FILTER_TIME[4].startTime)
            setEndTime(FILTER_TIME[4].endTime)
            break
      }
   }

   const disabledDate = (current: Moment) => {
      return current > moment() || current < moment().subtract(90, 'day')
   }

   const renderType = () => {
      return (
         <>
            <Select
               defaultValue={FILTER_TIME[1].type}
               value={typeFilter}
               onChange={handleChangeSelect}
               style={{ width: 185, alignItems: 'center', textAlign: 'left' }}
            >
               {FILTER_TIME.map((item: any) => {
                  return (
                     <Select.Option key={item.type} value={item.type}>
                        {item.title}
                     </Select.Option>
                  )
               })}
            </Select>
            {
               <DatePicker.RangePicker
                  key={moment(startTime).format('YYYYMMDDHHmmss')}
                  allowClear={false}
                  defaultValue={[startTime, endTime]}
                  format={DATE_FORMAT_CLIENT}
                  locale={locale}
                  disabled={typeFilter !== FILTER_TIME[4].type}
                  disabledDate={disabledDate}
                  onChange={handleRangePickerChange}
               />
            }
         </>
      )
   }

   return (
      <>
         <Row>
            <Col span={24}>
               <Row className="row-container-head">
                  <Col xs={24} md={12}>
                     {/* <Input.Search
                                onSearch={handleChangeTextSearch}
                                placeholder={t(GLOBAL_CLIENT.txtSearch)}
                                allowClear
                            /> */}
                  </Col>
                  <Col xs={24} md={12}>
                     <div className="text-right">
                        <Space>{renderType()}</Space>
                     </div>
                  </Col>
               </Row>

               {/* <Space className="total-items">
                        {t(GLOBAL_CLIENT.totalItems)}: {totalResults}
                    </Space> */}

               <Table
                  // scroll={{ x: 1200 }}
                  scroll={{ y: height - 400, x: typeFilter === FILTER_TIME[0].type ? 1000 : 2000 }}
                  //@ts-ignore
                  columns={columns}
                  dataSource={data}
                  rowKey="id"
                  pagination={false}
                  onChange={onChangeTable}
               />

               {/* {renderPagination()} */}
            </Col>
         </Row>
         {/* {visibleCreate && <Create visible={visibleCreate} onOk={handleCreate} onCancel={handleCancel} />} */}
         {visibleDetail && item && (
            <Chart
               visible={visibleDetail}
               item={item}
               onOk={handleEdit}
               onCancel={hideDetail}
               startTime={startTime}
               endTime={endTime}
            />
         )}
      </>
   )
})

export default BusinessResultsManagement
